import React, { useEffect, useRef, useState } from "react";
//  importing components
import { Buttons } from "../../../AbstractsComponents/Button/button";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import {
  FormTextField,
  FormSelectField,
  FormSelectFieldOption,
  FormTextArea,
  AddTagInputField,
} from "../../../AbstractsComponents/TextFields/textFields";
import { VINForm } from "../../vinFormField";
import {
  AddVehicleFormValidationSchema,
  UpdateVehicleFormValidationSchema,
} from "../../../../Validations/validations";
//  importing css files
import "./addNewVehicle.css";
import { ToggleButton } from "../../../AbstractsComponents/ToggleButton/toggleButton";
import ToastController from "../../../../Controllers/toastController";
import VehiclesController from "../../../../Controllers/vehiclesController";
import Modal from "../../Modal/Modal";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
const VehicleForm = ({
  onSubmit,
  editingMode,
  initialData,
  onActiveStatus,
  onUpdated,
  updateEnabled,
}) => {
  const formTitle = editingMode ? "Edit Vehicle" : "Add New Vehicle";
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const formLoaded = useRef(false);
  // const [errorMessage, setErrorMessage] = useState("");
  const [vehicleEnabledStatus, setVehicleEnabledStatus] = useState(1);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);
  const [msg, setMsg] = useState({
    success: null,
    error: null,
  });

  const handleSubmitVehicleForm = (values) => {
    // if(values.priceMaxRange > values.priceMinRange){
    console.log(`Updateing withing sttus:${vehicleEnabledStatus}`);
    console.log(values, "@create vehicle data");
    if (onSubmit)
      onSubmit(
        {
          ...values,
          //  enabledStatus: vehicleEnabledStatus
        },
        editingMode
      );
    // }else{
    //   ToastController.error(`Invalid price range, max-price:$${values.priceMaxRange} must be greater than min-price:$${values.priceMinRange}.`)
    // }
  };
  const checkTernary = (x, y) => {
    return x ? x : y ? y : "";
  };
  const { handleSubmit, getFieldProps, touched, errors, setValues, values } =
    useFormik({
      validationSchema: editingMode
        ? UpdateVehicleFormValidationSchema
        : AddVehicleFormValidationSchema,
      initialValues: {
        title: "",
        priceMinRange: "",
        year: "",
        modal: "",
        make: "",
        fuleType: "",
        KMDriven: "",
        trimLevel: "",
        interior: "",
        exterior: "",
        transmission: "",
        engine: "",
        mileageHighway: "",
        mileageCity: "",
        madeIn: "",
        description: "",
        knownIssueValue: "",
        knownIssues: [],
      },
      onSubmit: handleSubmitVehicleForm,
    });

  // useEffect(() => {
  //   if (Object.keys(errors).length > 0) {
  //     ToastController.error(`Please fill all required fields...`);
  //     //     return;
  //     //     // if (errors.description) {
  //     //     // } else {
  //     //     //   ToastController.error(`Invalid vehicle form.`);
  //     //     // }
  //     //     // console.log(`Error in form`, errors);
  //   }
  // }, [errors]);

  const handleSubmitForm = (e) => {
    e.preventDefault();
    if (Object.keys(errors).length > 0) {
      ToastController.error(`Please fill all required fields...`);
      return;
    }
    handleSubmit();
  };

  useEffect(() => {
    if (formLoaded.current) {
      if (!isDataUpdated) {
        setIsDataUpdated(true);
        if (onUpdated) onUpdated();
      }
    }
  }, [values]);

  useEffect(() => {
    if (editingMode && initialData) {
      setValues({
        ...values,
        title: initialData.title ? initialData.title : "",
        priceMinRange: initialData.priceMinRange,
        priceMaxRange: initialData.priceMaxRange
          ? initialData.priceMaxRange
          : 0,
        year: initialData.year ? initialData.year : "",
        modal: initialData.modal ? initialData.modal : "",
        make: initialData.make ? initialData.make : "",
        fuleType: initialData.fuleType ? initialData.fuleType : "",
        KMDriven: initialData.KMDriven ? initialData.KMDriven : "",
        trimLevel: initialData.trimLevel ? initialData.trimLevel : "",
        interior: initialData.interior ? initialData.interior : "",
        exterior: initialData.exterior ? initialData.exterior : "",
        transmission: initialData.transmission ? initialData.transmission : "",
        engine: initialData.engine ? initialData.engine : "",
        mileageHighway: initialData.mileageHighway
          ? initialData.mileageHighway
          : "",
        mileageCity: initialData.mileageCity ? initialData.mileageCity : "",
        madeIn: initialData.madeIn ? initialData.madeIn : "",
        description: initialData.description ? initialData.description : "",
        knownIssues: checkTernary(initialData?.knownIssues, []),
      });
      console.log(`Initial data status:${initialData.enabledStatus}`);
      setVehicleEnabledStatus(
        initialData.enabledStatus
        // typeof initialData.enabledStatus === "number"
        //   ? initialData.enabledStatus
        //   : 1
      );
    }
    setTimeout(() => (formLoaded.current = true), 500);
  }, []);

  const onVinDecoded = (vehicleData) => {
    setValues(vehicleData);
  };
  const handleOnActiveStatus = () => {
    if (onActiveStatus) {
    }
  };

  let toast = new ToastController();

  const handleStatusChange = () => {
    // console.log("id, status....", id, status);

    toast.showProcessing("Changing Vehicle Status");
    let statusToggle = vehicleEnabledStatus ? 0 : 1;

    VehiclesController.changeVehicleStatus(initialData._id, statusToggle)
      .then((status) => {
        console.log(status, "@status res");
        setVehicleEnabledStatus(status);

        toast.endProcessingWithSuccess(
          `Vehicle ${status ? "enabled" : "disabled"}...!`
        );
      })
      .catch((err) => {
        toast.endProcessingWithError(err);
      });
  };
  // const handleToggle = (value) => {
  //   setVehicleEnabledStatus(value ? 1 : 0);
  //   // if (formLoaded.current) {
  //   //   if (!isDataUpdated) {
  //   //     setIsDataUpdated(true);
  //   //     if (onUpdated) onUpdated();
  //   //   }
  //   // }
  // };

  const handleSafeDeleteVehicle = () => {
    setProcessing(true);
    console.log("Vehicle data", initialData);
    VehiclesController.safeDeleteVehicle(initialData?._id)
      .then((res) => {
        setProcessing(false);
        setMsg({
          success: "Vehicle Deleted Successfully!",
          errors: null,
        });
      })
      .catch((err) => {
        setProcessing(false);
        setMsg({
          success: null,
          error: err,
        });
      });
  };

  return (
    <div>
      <form
      // onSubmit={handleSubmit}
      >
        <div className="add-new-vehicle-card">
          <div className="add-new-vehicle-head-div">
            <h4 className="add-new-vehicle-heading">{formTitle}</h4>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "20px 0px",
              }}
            >
              {editingMode ? (
                <ToggleButton
                  value={vehicleEnabledStatus}
                  onChange={handleStatusChange}
                  style={{ marginRight: "30px", marginTop: "5px" }}
                  title={`Turn this vehicle ${
                    vehicleEnabledStatus ? "off" : "on"
                  }`}
                />
              ) : (
                false
              )}

              <Link to="/allVehicle" className="react-router-link">
                <Buttons type="button" editButton>
                  CANCEL
                </Buttons>
              </Link>

              <div style={{ width: "20px" }} />

              {editingMode ? (
                <div className="flex flex-col gap-2 mb-3">
                  <Buttons
                    onClick={handleSubmitForm}
                    disabled={!updateEnabled}
                    // backgroundColor="green"
                    type="submit"
                    style={{
                      width: "157px",
                      height: "40px",
                      //  marginLeft: "20px",
                      lineHeight: "38px",
                      boxShadow: "none",
                      textTransform: "uppercase",
                      backgroundColor: updateEnabled ? "#2674e6" : "",
                    }}
                  >
                    Update
                  </Buttons>
                  <button
                    type="button"
                    onClick={() => setShow(true)}
                    className="w-[157px] p-[6px] text-lg bg-red-600 rounded-lg uppercase text-white"
                  >
                    Delete
                  </button>
                </div>
              ) : (
                <Buttons
                  onClick={handleSubmitForm}
                  // LoaderButton={LoaderButton}
                  type="submit"
                  style={{
                    width: "157px",
                    height: "40px",
                    marginLeft: "20px",
                    lineHeight: "38px",
                    boxShadow: "none",
                    textTransform: "uppercase",
                    backgroundColor: "#2674e6",
                  }}
                >
                  UPLOAD
                </Buttons>
              )}
            </div>
          </div>

          <div className="form-scoll-able-body">
            <div className="add-vehicle-most-imp-div">
              <hr style={{ backgroundColor: "#E7E7E7", height: "2px" }} />
              <p>Vehicle Details</p>
            </div>
            <div></div>
            <VINForm
              initialData={initialData}
              editingMode={editingMode}
              onVINDecoded={onVinDecoded}
            />
            {/* <div className="add-vehicle-most-imp-div">
            <hr style={{ backgroundColor: "#E7E7E7", height: "2px" }} />
            <p>PRICE RANGE</p>
          </div> */}
            <div></div>
            <Row>
              <Col xl={6} lg={6} md={8} sm={6} xs={12}>
                <FormTextField
                  name="title"
                  id="title"
                  type="text"
                  label="Title"
                  placeholder="Type Vehicle Title"
                  {...getFieldProps("title")}
                />
                <span className="errorMessage">
                  {touched["title"] && errors["title"]}
                </span>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                <FormTextField
                  name="priceMinRange"
                  id="priceMinRange"
                  type="number"
                  label="Price"
                  placeholder="$ 0"
                  {...getFieldProps("priceMinRange")}
                />
                <span className="errorMessage">
                  {values.priceMinRange <= 0
                    ? `Must be greater than $0`
                    : touched["priceMinRange"] && errors["priceMinRange"]}
                </span>
              </Col>
              {/* <Col xl={3} lg={3} md={3} sm={6} xs={12}>
              <FormTextField
                type="number"
                name="priceMaxRange"
                id="priceMaxRange"
                label="MAX PRICE"
                placeholder="$ 0"
                {...getFieldProps("priceMaxRange")}
              />
              <span className="errorMessage">
                {(parseInt(values.priceMinRange)  >= parseInt(values.priceMaxRange))? `Less than ${values.priceMinRange}`:touched["priceMaxRange"] && errors["priceMaxRange"]}
              </span>
            </Col> */}
            </Row>
            <div className="add-vehicle-most-imp-div">
              <hr style={{ backgroundColor: "#E7E7E7", height: "2px" }} />
              <p>General</p>
            </div>
            <div></div>

            <Row>
              <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                <FormTextField
                  name="KMDriven"
                  id="KMDriven"
                  type="text"
                  placeholder="Type your Odo meter"
                  {...getFieldProps("KMDriven")}
                  label="ODOMETER"
                />
                <span className="errorMessage">
                  {touched["KMDriven"] && errors["KMDriven"]}
                </span>
              </Col>

              <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                <FormTextField
                  name="year"
                  id="year"
                  type="text"
                  placeholder="Type year"
                  {...getFieldProps("year")}
                  label="year"
                />
                <span className="errorMessage">
                  {touched["year"] && errors["year"]}
                </span>
              </Col>
              <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                <FormTextField
                  name="make"
                  id="make"
                  type="text"
                  placeholder="Type Vehicle Make"
                  {...getFieldProps("make")}
                  label="Make"
                />
                <span className="errorMessage">
                  {touched["make"] && errors["make"]}
                </span>
              </Col>
            </Row>
            <Row>
              <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                <FormTextField
                  name="fuleType"
                  id="fuleType"
                  type="text"
                  placeholder="Type Vehicle Fuel Type"
                  {...getFieldProps("fuleType")}
                  label="Fuel Type"
                />
                <span className="errorMessage">
                  {touched["fuleType"] && errors["fuleType"]}
                </span>
              </Col>
              <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                <FormTextField
                  type="text"
                  name="modal"
                  id="modal"
                  label="model"
                  placeholder="Type Vehicle Modal"
                  {...getFieldProps("modal")}
                />
                <span className="errorMessage">
                  {touched["modal"] && errors["modal"]}
                </span>
              </Col>
              <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                <FormTextField
                  name="interior"
                  id="interior"
                  type="text"
                  placeholder="Type Interior"
                  {...getFieldProps("interior")}
                  label="Interior"
                />
                <span className="errorMessage">
                  {touched["interior"] && errors["interior"]}
                </span>
              </Col>
            </Row>
            <Row>
              <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                <FormTextField
                  name="exterior"
                  id="exterior"
                  type="text"
                  placeholder="Type Exterior"
                  {...getFieldProps("exterior")}
                  label="Exterior"
                />
                <span className="errorMessage">
                  {touched["exterior"] && errors["exterior"]}
                </span>
              </Col>
              <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                <FormTextField
                  type="text"
                  name="transmission"
                  id="transmission"
                  label="Transmission"
                  placeholder="Select transmission"
                  {...getFieldProps("transmission")}
                />
                <span className="errorMessage">
                  {touched["transmission"] && errors["transmission"]}
                </span>
              </Col>

              <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                <FormTextField
                  type="text"
                  name="trimLevel"
                  id="trimLevel"
                  label="trimLevel"
                  placeholder="Type trimLevel"
                  {...getFieldProps("trimLevel")}
                />
                <span className="errorMessage">
                  {touched["trimLevel"] && errors["trimLevel"]}
                </span>
              </Col>
            </Row>

            <Row>
              <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                <FormTextField
                  name="mileageHighway"
                  id="mileageHighway"
                  type="text"
                  placeholder="Select mileageHighways"
                  {...getFieldProps("mileageHighway")}
                  label="MILEAGE HIGHWAY"
                />
                <span className="errorMessage">
                  {touched["mileageHighway"] && errors["mileageHighway"]}
                </span>
              </Col>
              <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                <FormTextField
                  type="text"
                  name="mileageCity"
                  id="mileageCity"
                  label="MILEAGE CITY"
                  placeholder="Type Milages"
                  {...getFieldProps("mileageCity")}
                />
                <span className="errorMessage">
                  {touched["mileageCity"] && errors["mileageCity"]}
                </span>
              </Col>
              <Col xl={4} lg={4} md={4} sm={6} xs={12}>
                <FormTextField
                  name="madeIn"
                  id="madeIn"
                  type="text"
                  placeholder="ex: Made in japan"
                  {...getFieldProps("madeIn")}
                  label="Made In"
                />
                <span className="errorMessage">
                  {touched["madeIn"] && errors["madeIn"]}
                </span>
              </Col>
            </Row>

            <Row>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <FormTextField
                  name="engine"
                  id="engine"
                  type="text"
                  placeholder="Type your Engine"
                  {...getFieldProps("engine")}
                  label="engine"
                />
                <span className="errorMessage">
                  {touched["engine"] && errors["engine"]}
                </span>
              </Col>
            </Row>

            <Row>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <FormTextArea
                  name="description"
                  id="description"
                  type="text"
                  placeholder="Type Vehicle Description"
                  {...getFieldProps("description")}
                  label="description"
                />
                <span className="errorMessage">
                  {touched["description"] && errors["description"]}
                </span>
              </Col>
            </Row>

            <Row>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <AddTagInputField
                  label={`Known Issues`}
                  className={`py-1 px-3`}
                  options={values?.knownIssues}
                  name={`knownIssueValue`}
                  {...getFieldProps(`knownIssueValue`)}
                  placeholder={"Type known issues"}
                  onDeleteItem={(e) => {
                    let filterValue = values?.knownIssues?.filter(
                      (_, i) => e !== i
                    );
                    setValues({ ...values, knownIssues: filterValue });
                  }}
                  onAddItem={(e) => {
                    if (e !== "") {
                      setValues({
                        ...values,
                        knownIssues: [...values.knownIssues, e],
                        knownIssueValue: "",
                      });
                    }
                  }}
                  onUpdateItem={(value, index) => {
                    let knownIssuesCopy = [...values.knownIssues];
                    knownIssuesCopy[index] = value;

                    if (index >= 0) {
                      setValues({
                        ...values,
                        knownIssues: knownIssuesCopy,
                        knownIssueValue: "",
                      });
                    }
                  }}
                  onEdit={(e) => setValues({ ...values, knownIssueValue: e })}
                />
              </Col>
            </Row>
            {/* <div>
            <SelectField
              type="select"
              label="Power Feature"
              name="PowerFeature"
              id="PowerFeature"
              {...getFieldProps("PowerFeature")}
              placeholder="1KMHH82109MJ"
            />
            <span className="errorMessage">
              {touched["PowerFeature"] && errors["PowerFeature"]}
            </span>
          </div> */}
            {/* <div>
            <p className="power-text">Description</p>
            <div className="add-vehicle-descrption-div">
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint
                harum minus debitis voluptates reprehenderit est, perferendis
                nemo doloribus assumenda optio velit numquam, obcaecati
                molestias? Iusto modi a cupiditate ullam ab!
              </p>
            </div>
          </div> */}
          </div>
        </div>
      </form>
      <Modal visible={show} closeModal={() => setShow(false)}>
        <div className="max-w-[400px] w-full mx-auto rounded-lg min-h-max bg-white flex px-10 py-5 flex-col gap-4">
          <h3
            className={`text-lg capitalize text-center mx-auto font-[700] ${
              msg.error
                ? "text-red-500"
                : msg.success
                ? "text-green-500"
                : "text-black"
            }`}
          >
            {msg.error
              ? msg.error
              : msg.success
              ? msg.success
              : "Do want to delete vehicle?"}
          </h3>
          <div className="flex gap-3 mx-auto">
            {!msg.error && !msg.success && (
              <button
                onClick={handleSafeDeleteVehicle}
                className="px-3 py-2 text-white uppercase bg-blue-600 rounded-md"
              >
                {processing ? (
                  <TailSpin width={20} height={20} color={`white`} />
                ) : (
                  "Confirm"
                )}
              </button>
            )}
            <button
              onClick={() => {
                if (msg.success) {
                  navigate("/allVehicle");
                }
                setShow(false);
                setMsg({
                  success: null,
                  error: null,
                });
              }}
              className="px-3 py-2 text-black uppercase bg-gray-300 rounded-md"
            >
              {msg.error || msg.success ? "Ok" : "Cancel"}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default VehicleForm;
