import React from "react";
//    importing components
//    importing images
import Logo from "../../../Assests/Icons/InfoRide-01 1.svg";
//    importing icons
// import HomeIcon from "../../../Assests/Icons/home.svg";
import greyCarIcon from "../../../Assests/Icons/car (3) 1.png";
import walletCardGreyIcon from "../../../Assests/Icons/walletWhite.png";
import WalletIcon from "../../../Assests/Icons/wallet.svg";
import CarIcon from "../../../Assests/Icons/car.svg";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
//    importing css files
import "./sideBar.css";

export const SideBar = (props) => {
  //     const [toggle, setToggle] = useState(false);

  //   const  openNav=()=>{
  //         document.getElementById("mySidebar").style.marginLeft = "0px";
  //         document.getElementById("main").style.width = "84%";
  //         setToggle(false)
  //       }
  //     const  closeNav=()=> {
  //         document.getElementById("mySidebar").style.marginLeft = "-250px";
  //         document.getElementById("main").style.width= "100%";
  //         setToggle(true)

  //       }
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");
  return (
    <div className="side-bar-main-div">
      <div>
        <img src={Logo} alt="" />
      </div>
      <div className="side-bar-button-div">
        {/* <Buttons id="1" onClick={()=>this.SelectButtonActive(1)} style={{marginTop:"10px"}} SideBarBtn={"SideBarBtn"} leftIcon={HomeIcon}>Dashboard</Buttons> */}
        <Link to="/allVehicle" className="react-router-link">
          <button
            className={
              splitLocation[1] === "allVehicle"
                ? "side-bar-button-active"
                : "side-bar-button"
            }
          >
            <div className="side-bar-button-text-div">
              <div className="side-bar-button-icon-div">
                <img
                  src={
                    splitLocation[1] === "allVehicle" ? CarIcon : greyCarIcon
                  }
                  alt=""
                />
              </div>
              <div>Vehicles</div>
            </div>
          </button>
        </Link>

        <Link to="/offered-vehicles" className="react-router-link">
          <button
            className={
              splitLocation[1] === "offered-vehicles"
                ? "side-bar-button-active"
                : "side-bar-button"
            }
          >
            <div className="side-bar-button-text-div">
              <div className="side-bar-button-icon-div">
                <img
                  src={
                    splitLocation[1] === "offered-vehicles"
                      ? CarIcon
                      : greyCarIcon
                  }
                  alt=""
                />
              </div>
              <div>Offered Vehicles</div>
            </div>
          </button>
        </Link>

        <Link to="/wallet" className="react-router-link">
          <button
            className={
              splitLocation[1] === "wallet"
                ? "side-bar-button-active"
                : "side-bar-button"
            }
          >
            <div className="side-bar-button-text-div">
              <div className="side-bar-button-icon-div">
                <img
                  src={
                    splitLocation[1] === "wallet"
                      ? walletCardGreyIcon
                      : WalletIcon
                  }
                  alt=""
                />
              </div>
              <div>Invoices</div>
            </div>
          </button>
        </Link>

        {/* <Link to="/import" className="react-router-link">
          <button
            className={
              splitLocation[1] === "import"
                ? "side-bar-button-active"
                : "side-bar-button"
            }
          >
            <div className="side-bar-button-text-div">
              <div className="side-bar-button-icon-div">
                <img
                  src={
                    splitLocation[1] === "import"
                      ? walletCardGreyIcon
                      : WalletIcon
                  }
                  alt=""
                />
              </div>
              <div>Import</div>
            </div>
          </button>
        </Link> */}
      </div>
      {/* <button className='sidebar-toggle-button' onClick={()=>{toggle===false?closeNav():openNav()}}>toggle</button> */}
    </div>
  );
};
