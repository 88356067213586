import React, { Component, useState } from "react";
//    importing components
import { Buttons } from "../../../../Components/AbstractsComponents/Button/button";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Link, useNavigate } from "react-router-dom";
//    importing images
import UserImage from "../../../../Assests/Images/Ellipse 276.png";
//    importing icons
import MessageIcon from "../../../../Assests/Icons/messageIcon.png";
//    imoporting css files
import "./offerTable.css";
import { OfferButtons } from "../../../ModuleComponents/OfferButtons/offerButtons";
import VehiclesController from "../../../../Controllers/vehiclesController";
var products = [
  {
    clientPicture: UserImage,
    vehicleName: "2016 Ford F-150 XLT",
    offer: "$45,000",
    age: "1 day",
  },
  {
    clientPicture: UserImage,
    vehicleName: "2016 Ford F-150 XLT",
    offer: "$45,000",
    age: "1 day",
  },
  {
    clientPicture: UserImage,
    vehicleName: "2016 Ford F-150 XLT",
    offer: "$45,000",
    age: "1 day",
  },
  {
    clientPicture: UserImage,
    vehicleName: "2016 Ford F-150 XLT",
    offer: "$45,000",
    age: "1 day",
  },
  {
    clientPicture: UserImage,
    vehicleName: "2016 Ford F-150 XLT",
    offer: "$45,000",
    age: "1 day",
  },
  {
    clientPicture: UserImage,
    vehicleName: "2016 Ford F-150 XLT",
    offer: "$45,000",
    age: "1 day",
  },
  {
    clientPicture: UserImage,
    vehicleName: "2016 Ford F-150 XLT",
    offer: "$45,000",
    age: "1 day",
  },
  {
    clientPicture: UserImage,
    offer: "$45,000",
    age: "1 day",
  },
  {
    clientPicture: UserImage,
    offer: "$45,000",
    age: "1 day",
  },
  {
    clientPicture: UserImage,
    offer: "$45,000",
    age: "1 day",
  },
  {
    clientPicture: UserImage,
    offer: "$45,000",
    age: "1 day",
  },
  {
    clientPicture: UserImage,
    offer: "$45,000",
    age: "1 day",
  },
  {
    clientPicture: UserImage,
    offer: "$45,000",
    age: "1 day",
  },
  {
    clientPicture: UserImage,
    offer: "$45,000",
    age: "1 day",
  },
];
function imageFormatter(cell, row) {
  return (
    <img
      src={cell}
      style={{ width: 70, height: 70, borderRadius: "50%", objectFit: "cover" }}
      alt=""
    />
  );
}

function PayloadFormaater({ cell }) {
  // console.log("Rowwwww...", cell.cell.offerType);
  return (
    <div className="capitalize tracking-wider">
      {cell.offerType ? cell.offerType : "---"}
    </div>
  );
}

function ButtonFormat({ cell, row }) {
  // console.log("Cell ---> ", cell)
  // console.log("Row ---> ", row)

  const navigate = useNavigate();

  const [processing, setProcessing] = useState(false);
  const handleAcceptOffer = (status) => {
    console.log(status, "@offer status");
    setProcessing(true);
    // console.log(`ID:${row.payload._id}, title ${row.vehicleName}`)
    VehiclesController.acceptOffer(row.payload._id, status)
      .then((offerAccepted) => {
        console.log("Accepted", offerAccepted);
        if (status === "accepted") {
          navigate(`/allVehicle`);
        }
        setProcessing(false);
      })
      .catch((err) => {
        setProcessing(false);
      });
  };

  return (
    <OfferButtons
      handleAcceptOffer={handleAcceptOffer}
      processing={processing}
      offer={row.payload}
      type={cell}
    />
  );
}

class VehicleTable extends Component {
  state = {};
  renderShowsTotal(start, to, total) {
    return (
      <p className="show-table-data-text">
        Showing {to} from {total}&nbsp;&nbsp;
      </p>
    );
  }

  render() {
    // console.log(this.props,"Props Data")
    const options = {
      page: 1, // which page you want to show as default
      sizePerPageList: [
        {
          text: "5",
          value: 5,
        },
        {
          text: "10",
          value: 10,
        },
        {
          text: "All",
          value: products.length,
        },
      ], // you can change the dropdown list for size per page
      sizePerPage: 30, // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 4, // the pagination bar size.
      prePage: "<<", // Previous page button text
      nextPage: ">>", // Next page button text
      firstPage: "", // First page button text
      lastPage: ">>", // Last page button text
      paginationShowsTotal: this.renderShowsTotal, // Accept bool or function
      // hideSizePerPage: true > You can hide the dropdown for sizePerPage
      // alwaysShowAllBtns: true // Always show next and previous button
      // withFirstAndLast: false > Hide the going to First and Last page button
      hidePageListOnlyOnePage: true,
    };
    console.log("=>OFFERS", this.props.offers);
    return (
      <div style={{ display: "block", height: "70vh" }}>
        <BootstrapTable
          options={options}
          bodyContainerClass="my-custom-body"
          headerStyle={{ padding: "0px 0px" }}
          tableStyle={{ borderRadius: "30px", border: "none" }}
          tableContainerClass="my-custom-table-class"
          bodyStyle={{
            height: "calc(100vh - 350px)",
            background: "#ffffff",
            borderRadius: "30px",
          }}
          pagination
          maxHeight="500px"
          data={this.props.offers ? this.props.offers : []}
        >
          <TableHeaderColumn
            thStyle={{ padding: "20px 40px" }}
            tdStyle={{ padding: "10px 0px 10px 40px" }}
            width="180px"
            className="td-header-string-example"
            isKey
            dataField="clientPicture"
            dataFormat={imageFormatter}
          >
            <div style={{ display: "flex" }}>
              Buyer Picture
              {/* <div className='header-arrow-div'>
                        <img src={UpArrow} className='header-arrows'/>
                        <img src={DownArrow} className='header-arrows'/>
                    </div> */}
            </div>
          </TableHeaderColumn>

          <TableHeaderColumn
            dataField="buyerName"
            tdStyle={{
              color: "#16478E",
              fontWeight: "700",
              verticalAlign: "middle",
            }}
            className="td-header-string-example"
            width="140px"
          >
            <div style={{ display: "flex" }}>
              Buyer Name
              {/* <div className='header-arrow-div'>
                        <img src={UpArrow} className='header-arrows'/>
                        <img src={DownArrow} className='header-arrows'/>
                    </div> */}
            </div>
          </TableHeaderColumn>

          <TableHeaderColumn
            dataField="offer"
            tdStyle={{
              color: "#16478E",
              fontWeight: "700",
              verticalAlign: "middle",
            }}
            className="td-header-string-example"
            width="90px"
          >
            <div style={{ display: "flex" }}>
              Offer
              {/* <div className='header-arrow-div'>
                        <img src={UpArrow} className='header-arrows'/>
                        <img src={DownArrow} className='header-arrows'/>
                    </div> */}
            </div>
          </TableHeaderColumn>

          <TableHeaderColumn
            className="td-header-string-example"
            tdStyle={{
              padding: "10px",
              fontWeight: "700",
              verticalAlign: "middle",
            }}
            dataField="age"
          >
            <div style={{ display: "flex" }}>
              Age
              {/* <div className='header-arrow-div'>
                        <img src={UpArrow} className='header-arrows'/>
                        <img src={DownArrow} className='header-arrows'/>
                    </div> */}
            </div>
          </TableHeaderColumn>
          <TableHeaderColumn
            className="td-header-string-example"
            tdStyle={{
              padding: "10px",
              fontWeight: "700",
              verticalAlign: "middle",
            }}
            dataField="payload"
            dataFormat={(cell, row) => <PayloadFormaater cell={cell} />}
          >
            <div style={{ display: "flex" }}>Offer Type</div>
          </TableHeaderColumn>
          <TableHeaderColumn
            className="td-header-string-example"
            tdStyle={{
              padding: "10px 0px 10px 0px",
              backgroundColor: "white",
              textAlign: "right",
              verticalAlign: "middle",
              position: "relative",
              overflow: "visible",
            }}
            width="300px"
            dataField="action"
            thStyle={{
              backgroundColor: "white",
            }}
            dataFormat={(cell, row) => <ButtonFormat cell={cell} row={row} />}
          >
            <div style={{ display: "flex" }}>
              Actions
              {/* <div className='header-arrow-div'>
                        <img src={UpArrow} className='header-arrows'/>
                        <img src={DownArrow} className='header-arrows'/>
                    </div> */}
            </div>
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    );
  }
}

export default VehicleTable;
