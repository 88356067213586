import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const ChatSkeleton = props => {
    
    return (
        <SkeletonTheme  baseColor="#f3f3f3" highlightColor="#ecebeb">
                
            <p style={{display:"flex", flexDirection:"row", alignItems:'center'}}>
                <div style={{width: 50}}/>
                <Skeleton style={{borderRadius:10}} width={100} height={70} count={1} />
                <div style={{width: 10}}/>
                <Skeleton style={{borderRadius: 5}} width={130} height={30} count={1} />
                <div style={{width: 50}}/>
                <Skeleton style={{borderRadius: 5}} width={100} height={30} count={1} />
            </p>

            <p style={{display:"flex", flexDirection:"column", alignItems:"flex-start", paddingLeft: 50}}>

           
            <div style={{height: 50}}/>
            <Skeleton style={{borderRadius: 5}} width={700} height={30} count={1} />
            <div style={{height: 30}}/> 
            <Skeleton style={{borderRadius: 5}} width={500} height={30} count={1} />
            <div style={{height: 30}}/>
            <Skeleton style={{borderRadius: 5}} width={300} height={30} count={1} />
            <div style={{height: 30}}/>
            <Skeleton style={{borderRadius: 5}} width={500} height={30} count={1} />

            </p>
        </SkeletonTheme>
    )
}

export default ChatSkeleton;