export const toDateString = (date_) => {
    let date = new Date(date_);
    return `${date.getFullYear()}-${(date.getMonth()+1).toString().padStart(2, "0")}-${(date.getDate()).toString().padStart(2, "0")}`
}


export const dateToShortTime = (date_) => {
    /// TODO: Past time format code here
    let date = new Date(date_);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

export const datesEquals = (date1, date2) => {
    return ( date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear() )
}

export const isDateYesterday = ( date2) => {
    let todayDate = new Date();
   todayDate.setDate( todayDate.getDate() - 1 ); // removing a day
   return datesEquals(todayDate, date2)
}

export const dateToInteligentDate = (date) => {
        let formatedDate = ""; 
        switch(true){
            case datesEquals(new Date(), new Date(date)):
                formatedDate = "Today";
             break;
            case isDateYesterday( new Date(date)):
                formatedDate = "Yesterday";
                break;
            default: 
            formatedDate =  new Date(date).toLocaleDateString();
                break;
        }
        return formatedDate;
}