import React from "react";
//     importing components
import SearchIcon from "../../../Assests/Icons/Search.svg";
import BellIcon from "../../../Assests/Icons/bellIcon.svg";
import UserImage from "../../../Assests/Images/image 2.png";
import ArrowIcon from "../../../Assests/Icons/chevronIcon.svg";
import NotificationImg from "../../../Assests/Images/image 2.png";
import ReduxDispatchController, {
  useLoaderAction,
} from "../../../Controllers/reduxDispatchController";
import {
  DropDownsPop,
  DropDownItems,
  DropDownMenu,
} from "../../../Components/AbstractsComponents/DropDown/dropDown";
import { Link } from "react-router-dom";
import AuthController from "../../../Controllers/authController";
import { Line, Circle } from "rc-progress";
//     importing css files
import "./userNavBar.css";
export const UserNavBar = ({ currentUser }) => {
  const loader = useLoaderAction();
  return (
    <React.Fragment>
      <div className="user-navbar-main-div">
        {loader.visible ? (
          <Line
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              height: 2,
              width: "100%",
              borderRadius: 0,
            }}
            percent={loader.progress}
            strokeLinecap="0"
            trailColor="rgba(7, 166, 255, 0.2)"
            transition="width 2s"
            // transition="stroke-dashoffset 2s ease 2s, stroke-dasharray 2s ease 2s, stroke 2s linear"
            strokeColor="#2674e6"
          />
        ) : (
          false
        )}

        <div className="user-navbar-inner-div">
          <div className="user-nav-serach-div">
            {/* <img src={SearchIcon} className="search-icon" alt="" />
            <input
              type="text"
              className="searc-anything-input"
              placeholder="Search anything"
            /> */}
          </div>
          <div>
            <div className="user-nav-icons-div">
              {/* <img src={} className='user-nav-bell-icon' /> */}
              {/* <div>
                <DropDownsPop heading={BellIcon} style={{ float: "left" }}>
                  <DropDownMenu>
                    <DropDownItems>
                      <div className="notification-dropdown-div">
                        <div className="notification-image-div">
                          <img src={NotificationImg} alt="" />
                        </div>
                        <div className="notification-dropdown-description">
                          <p>
                            you received a new message about{" "}
                            <span>2016 Ford F-150 XLT</span>
                          </p>
                          <span>3 min</span>
                        </div>
                      </div>
                    </DropDownItems>
                    <DropDownItems>
                      <div className="notification-dropdown-div">
                        <div>
                          <img src={NotificationImg} alt="" />
                        </div>
                        <div className="notification-dropdown-description">
                          <p>
                            you received a new message about{" "}
                            <span>2016 Ford F-150 XLT</span>
                          </p>
                          <span>3 min</span>
                        </div>
                      </div>
                    </DropDownItems>
                    <DropDownItems>
                      <div className="notification-dropdown-div">
                        <div>
                          <img src={NotificationImg} alt="" />
                        </div>
                        <div className="notification-dropdown-description">
                          <p>
                            you received a new message about{" "}
                            <span>2016 Ford F-150 XLT</span>
                          </p>
                          <span>3 min</span>
                        </div>
                      </div>
                    </DropDownItems>
                  </DropDownMenu>
                </DropDownsPop>
                <p className="notification-value-div">4</p>
              </div> */}
              <span>{currentUser?.fullName?.split(" ")[0]}</span>
              <img
                src={currentUser?.profilePicture?.avatar?.url}
                className="user-nav-user-img"
                alt=""
              />
              <div>
                <Link to="/">
                  <button
                    className="logout-btn"
                    onClick={() => AuthController.logout()}
                  >
                    Logout
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
//  <div><DropDownsPop heading={ArrowIcon}>
//                   <DropDownMenu className="log-out-dropdown">
//                     <DropDownItems style={{ padding: "10px" }}>
//                       View profile
//                     </DropDownItems></div>

//                     <Link to="/" className="react-router-link">
//                       {" "}
//                       <DropDownItems
//                         style={{ padding: "10px", color: "black" }}
//                         onClick={()=> AuthController.logout()}
//                       >
//                         Log out
//                       </DropDownItems>
//                     </Link>
//                   </DropDownMenu>
//                   </DropDownsPop>
