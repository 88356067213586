import React, { useEffect, useState } from "react";
import Logo from "../../Assests/Icons/InfoRide-01 1.svg";
import Lock from "../../Assests/Icons/blueLock.svg";
import { TailSpin } from "react-loader-spinner";
import ReduxDispatchController from "../../Controllers/reduxDispatchController";
import AuthController from "../../Controllers/authController";
import { HistoryNavigator } from "../../history";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const SplashScreen = (props) => {
  const [message, setMessage] = useState("authenticating user...!");
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  const navigate = useNavigate();

  //   const [params] = useSearchParams();
  const accessKey = params.accessKey;
  const vehicleId = params.vehicleId;
  const offerId = params.offerId;

  //   let accessKey = window.location.search;

  useEffect(() => {
    console.log(
      `Requested Location:`,
      window.location.pathname.match("signUp")
    );
    let credencials = AuthController.restorePresistedCredencials();
    if (!accessKey && credencials) {
      AuthController.checkAuthStatus()
        .then((res) => {
          HistoryNavigator.push("/");
          ReduxDispatchController.AUTH.setSplashVisible(false);
        })
        .catch((err) => {
          AuthController.logout();
          HistoryNavigator.push("/auth/login");
          ReduxDispatchController.AUTH.setSplashVisible(false);
        });
    } else {
      if (AuthController.isAuthRoute(window.location.pathname)) {
        console.log(`@step 1`);
        ReduxDispatchController.AUTH.setSplashVisible(false);
        HistoryNavigator.push(window.location.pathname);
      } else {
        console.log(`@step 2`);

        AuthController.varifyDelegateAccess(accessKey)
          .then(() => {
            console.log(`@step 3`);

            ReduxDispatchController.AUTH.setSplashVisible(false);
            if (vehicleId) {
              console.log("running vehicleId", vehicleId);
              setTimeout(() => {
                navigate(`/allVehicle/offerVehicle/${vehicleId}`);
                // navigate(
                //   `/allVehicle/offerVehicle/chat/${vehicleId}/${offerId}`
                // );
              }, 500);
            } else {
              navigate(`/allVehicle`);
            }
          })
          .catch((err) => {
            console.log(`@step 4`, err);

            // ReduxDispatchController.AUTH.setSplashVisible(false);
            HistoryNavigator.push("/auth/login");
            // setProcessing(false);
          })
          .finally(() => {
            ReduxDispatchController.AUTH.setSplashVisible(false);
          });
        // ReduxDispatchController.AUTH.setSplashVisible(false);
      }
    }
  }, []);
  const loaderSize = 40;

  return (
    <div
      style={{
        width: "100%",
        height: window.innerHeight,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "center",
          }}
        >
          <img src={Logo} className="auth-nav-bar-img" alt="" />
          <div style={{ marginRight: -20, marginBottom: -10 }}>
            <TailSpin
              height={loaderSize}
              width={loaderSize}
              color={"#2674e6"}
              ariaLabel="loading"
            />
            <div
              style={{
                width: loaderSize,
                height: loaderSize,
                marginTop: -loaderSize,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{ marginTop: -3 }}
                width={loaderSize * 0.4}
                height={loaderSize * 0.4}
                src={Lock}
                alt=""
              />
            </div>
          </div>
        </div>
        <div style={{ height: 40, marginTop: -10 }}>
          <h6>{message}</h6>
        </div>
      </div>
    </div>
  );
};
export default SplashScreen;
