import axios from "axios";
import { useSelector } from "react-redux";
import { dateToInteligentDate, dateToShortTime } from "../Utils/common";
import AuthController from './authController';
import { ERRORS } from "./errorMessages";
import ReduxDispatchController from "./reduxDispatchController";
import { getFirstImage } from "./vehiclesController";
class InvoicesController {
    static ADAPTER = {
        transform: (invoice) => {
            return {
                picture: getFirstImage(invoice.vehicle.pictures).avatar.url,
                vehicleTitle: invoice.vehicle?.title,
                time: `${dateToInteligentDate(invoice.dateTime)} ${dateToShortTime(invoice.dateTime)}`,
                total: `$${invoice.amount}`, 
                payload: invoice
            }
        }
    }

    static getInvoices = () => {
        return new Promise((resolve, reject) => {
            const businessId = AuthController.getCurrentUser()._id
            axios.get(`/business/business/get-all-invoices/${businessId}`)
                .then(res => {
                    if(res.data.success){
                        console.log(res.data)
                        let totalPayable = res.data.data.totalPayable;
                        const invoices = res.data.data.invoices.map(invoice =>{
                            return InvoicesController.ADAPTER.transform(invoice)
                        })
                        ReduxDispatchController.INVOICES.set(invoices, totalPayable)
                        resolve(invoices);
                    }else{
                        resolve([])
                    }
                }).catch(err => {
                    console.log(`Catch error in getting invoices`)
                    console.log(err)
                    reject(ERRORS.NETWORK_ERROR)
                })
        })
    }
    
    static loadInvocies = () => {
        console.log(`Loading invoices...`)
        ReduxDispatchController.INVOICES.setProcessing(true);
        InvoicesController.getInvoices()
            .then(invoices => {
                console.log(`invoices resolved, ending process.`)
                ReduxDispatchController.INVOICES.setProcessing(false);
            }).catch(Err => {
                console.log(`Error in loading invoices`)
                ReduxDispatchController.INVOICES.setProcessing(false);
            })
    }

}
export default InvoicesController;


export const useInvoices = () => {
    return useSelector(state => ({
        loading: state.InvoicesReducer.loadingInvoices, 
        invoices: state.InvoicesReducer.invoices,
        totalPayable: state.InvoicesReducer.totalPayable
    }))
}