import React from "react";
//     importing componants
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import { AuthRouting } from "./authRouting";
import ProtectedDashboardWrapper from "./dashBoard";
import { useSelector } from "react-redux";
import { SignInScreen } from "../Screens/SignInScreen/signInScreen";
import { AuthNav } from "../Components/AbstractsComponents/AuthNavBar/authNavBar";
import { SignUpScreen } from "../Screens/SignUpScreen/signUpScreen";
import { PendingUprovalScreen } from "../Screens/PendingUprovalScreen/pendingUprovalScreen";
import { ForgetPasswordScreen } from "../Screens/ForgetPasswordScreen/forgetPasswordScreen";
import { PrivacyPolicyScreen } from "../Screens/PriviacyPolicyScreen/priviacyPolicyScreen";
import { TermsAndConditionsScreen } from "../Screens/TermsAndConditionsScreen/termsAndConditionsScreen";
import NotFoundPage from "./notFoundPage";
import { AllVehicleScreen } from "../Screens/AllVehicleScreen/allVehicleScreen";
import { OfferVehicleScreen } from "../Screens/OfferVehicleScreen/offerVehicleScreen";
import { WalletScreen } from "../Screens/WalletScreen/walletScreen";
import { AddVehicleScreen } from "../Screens/AddVehicleScreen/addVehicleScreen";
import { AllVehicleChatScreen } from "../Screens/AllVehicleChatScreen/allVehicleChatScreen";
import { EditVehicleScreen } from "../Screens/EditVehicleScreen/editVehicleScreen";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import ImportVehicleScreen from "../Screens/ImportVehicleScreen";
import DelegateAccesScreen from "../Screens/DelegateAccesScreen";
import { OfferedVehicleScreen } from "../Screens/OfferedVehicleScreen/OfferedVehicleScreen";

export const Routing = () => {
  const isAuthenticateUser = useSelector(
    (state) => state.InfoRiderReducrs.UserAuthenticate
  );
  console.log(isAuthenticateUser);
  return (
    <>
      <Routes>
        {/* <Route
          path="/delegateAccess/:accessId"
          element={<DelegateAccesScreen />}
        /> */}
        <Route path="auth" element={<AuthWrapper />}>
          <Route path="" element={<Navigate to="/auth/login" />} />
          <Route path="login" element={<SignInScreen />} />
          <Route path="signUp" element={<SignUpScreen />} />
          <Route path="forgetPassword" element={<ForgetPasswordScreen />} />
          <Route path="privacyPolicy" element={<PrivacyPolicyScreen />} />
          <Route
            path="termsAndConditions"
            element={<TermsAndConditionsScreen />}
          />
          <Route path="*" element={<NotFoundPage />} />
        </Route>

        <Route element={<ProtectedDashboardWrapper />}>
          <Route path="" element={<Navigate to="/allVehicle" />} />
          <Route path="/allVehicle" element={<AllVehicleScreen />} />
          <Route path="/offered-vehicles" element={<OfferedVehicleScreen />} />

          <Route path="/import" element={<ImportVehicleScreen />} />
          <Route
            path="/allVehicle/offerVehicle/:_id"
            element={<OfferVehicleScreen />}
          />
          <Route
            path="/allVehicle/offerVehicle/chat/:vehicleId/:_id"
            element={<AllVehicleChatScreen />}
          />
          <Route path="/allVehicle/addVehicle" element={<AddVehicleScreen />} />
          <Route
            path="/allVehicle/editVehicle/:_id"
            element={<EditVehicleScreen />}
          />
          <Route path="/wallet" element={<WalletScreen />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>

        <Route path="approval-pending" element={<ApprovalPendingWrapper />}>
          <Route path="" element={<PendingUprovalScreen />} />
        </Route>
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        // theme="colored"
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

const AuthWrapper = (props) => {
  return (
    <div>
      <AuthNav {...props} />
      <Outlet />
    </div>
  );
};

const ApprovalPendingWrapper = (props) => {
  return (
    <div>
      <AuthNav {...props} />
      <Outlet />
    </div>
  );
};
