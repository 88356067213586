import XLSX from 'xlsx';
import { make_cols, get_header_row } from './MakeColumns';
 
class ExcelReader {
  constructor() {
    this.state = {
      file: {},
      data: [],
      cols: []
    }
    this.handleFile = () => {
      return new Promise((resolve, reject) => {
        try {
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
     
        reader.onload = (e) => {
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA : true });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws);
          if(data.length >0){
            let headers = get_header_row(ws);
            let i = headers.findIndex(h => h === false);
            if(i >= 0){
              reject("Invalid Headers")
            }else{
              resolve({data: data, cols: make_cols(ws['!ref']), headers })
            }
          }else{
            reject("Empty File")
          }
        };
     
        if (rABS) {
          reader.readAsBinaryString(this.state.file);
        // console.log("TEST 2")

        } else {
          reader.readAsArrayBuffer(this.state.file);
        // console.log("TEST 2' ")

        };
      } catch (error) {
        reject("Invalid File")
      }
      })
    }
    this.handleChange = (e) => {
      const files = e.target.files;
      if (files && files[0])  this.state.file = files[0] ;
    }
  }


}
const MyExcelReader = new ExcelReader();
export default MyExcelReader;