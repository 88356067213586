import React, { useState } from "react";
import GoogleMapComponent from "./GoogleMapComponent";
import { AiFillCloseCircle } from "react-icons/ai";

const GoogleMapPicker = ({ setOpen, show, onChange }) => {
  return (
    <div>
      {show && (
        <div className="fixed top-0 left-0 w-screen h-screen p-10 bg-gray-900/90">
          <GoogleMapComponent
            setOpen={() => setOpen(false)}
            onChange={onChange}
          />
        </div>
      )}
    </div>
  );
};

export default GoogleMapPicker;
