import React, { useState } from "react";
//    importing components
import { TextFields } from "../../../AbstractsComponents/TextFields/textFields";
import { Buttons } from "../../../AbstractsComponents/Button/button";
import { Link, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { LogInSignIN } from "../../../../Validations/validations";
import { useNavigate } from "react-router-dom";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
//    importing imags icon
import EyeIcon from "../../../../Assests/Icons/eye-outline.png";
//    importing css files
import "./signIn.css";
import AuthController from "../../../../Controllers/authController";
import { HistoryNavigator } from "../../../../history";

export const SignIn = () => {
  const [processing, setProcessing] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const inputArr = [EyeIcon];
  let navigate = useNavigate();
  let location = useLocation();
  const SignInFormSubmit = (values) => {
    // navigate("/pendingApproval");
    console.log("Signin form submit values");
    console.log(values);
    console.log("Signin form submit values");
    setProcessing(true);
    AuthController.login(
      values.email,
      values.password,
      values.rememberMe?.length > 0
    )
      .then((loggedIn) => {
        console.log(`Logged in successfully`);
        setProcessing(false);
        navigate("/");
      })
      .catch((err) => {
        setErrorMessage(err);
        setProcessing(false);
        console.log(err);
      });
  };

  const { handleSubmit, getFieldProps, touched, errors } = useFormik({
    initialValues: {
      email: email,
      password: password,
      rememberMe: "",
    },
    validationSchema: LogInSignIN,
    onSubmit: SignInFormSubmit,
  });
  // const CallApi = () => {
  //   setLoaderButton(true, () => {
  //     axios.get("localhost:300").then((result) => setLoaderButton(false));
  //   });
  // };

  const [show, setShow] = useState(false);

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <div className="sign-in-main-div">
          <div className="sign-in-info-div">
            <div style={{ height: 50 }} />
            <form>
              <h2>Welcome back!</h2>
              <p className="login-text">Log in to your account</p>
              <TextFields
                onChange={(e) => setEmail(e.target.value)}
                label={"Email"}
                placeholder={"Enter your email"}
                name="email"
                id="email"
                type="email"
                {...getFieldProps("email")}
              />
              <span className="errorMessage" style={{ marginTop: "-10px" }}>
                {touched["email"] && errors["email"]}
              </span>

              <div className="relative flex flex-col w-full h-auto">
                <h5 className="text-[12px] font-[600] mt-1 text-[#5a616c]">
                  Password
                </h5>
                <input
                  type={show ? "text" : "password"}
                  className="text-fileds-input"
                  onChange={(e) => setPassword(e.target.value)}
                  label={"Password"}
                  placeholder={"Enter your password"}
                  name="password"
                  id="password"
                  {...getFieldProps("password")}
                />
                <span
                  className="mt-1 errorMessage"
                  style={{ marginTop: "-10px" }}
                >
                  {touched["password"] && errors["password"]}
                </span>
                <button
                  type="button"
                  onClick={() => setShow(!show)}
                  className="absolute right-6 top-[50%] text-[#5a616c]"
                >
                  {show ? <AiFillEyeInvisible /> : <AiFillEye />}
                </button>
              </div>
            </form>
            <div className="remember-me-div">
              {/*<div style={{ display: "flex" }}>
                <input {...getFieldProps("rememberMe")}  value={"rem"} type="checkbox" name="rememberMe" id="rememberMe" />
                <label
                  htmlFor="rememberMe"
                  id="rememberMe"
                  className="check-box-text"
                >
                  Remember me
                </label>
                </div>*/}
              <div
                className="forget-password-text"
                onClick={() => navigate("/auth/forgetPassword")}
              >
                Forget Your Password?
              </div>
            </div>
            {/* <Link
            to={isValid ? "/auth/pendingApproval" : ""}
            className="react-router-link"
          > */}
            <Buttons
              onClick={handleSubmit}
              processing={processing}
              type="submit"
              default
              style={{ height: "60px" }}
            >
              Log in
            </Buttons>
            {/* </Link> */}
            <div style={{ height: 20 }} className="already-have-account-div">
              <span style={{ color: "red" }}>{errorMessage}</span>
            </div>
            <div className="already-have-account-div">
              <p>
                Not have an account?
                <Link to="/auth/signUp" className="react-router-link">
                  <span> SIGN UP</span>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};
