import React, { Component } from "react";
import SkeletonLoader, { SkeletonTheme } from 'react-loading-skeleton'

//    importing components
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import CustomPagination from "../CustomPagination";
//    imoporting css files
import '../../CompositeComponents/Table/AllVehicle/allVehicleTable.css'
import 'react-loading-skeleton/dist/skeleton.css'



class TableSkeleton extends Component {
  state = {};
  render() {
    let renderShowsTotal = () => {
      return (
        <>
          <p className="d-flex justify-content-between pagination-container">
            <div className="show-table-data-text">
              Showing {this.props.activePageNo} from {this.props.noOfPages}
            </div>
            <CustomPagination
              noOfPages={this.props.noOfPages}
              onPageChange={this.props.setPageNo}
              activePageNo={this.props.activePageNo}
              pageNoArray={this.props.pageNoArray}
              setPageNoArray={this.props.setPageNoArray}
            />
          </p>
        </>
      );
    };
    const options = {
      page: 1,
      sizePerPageList: [
        {
          text: "5",
          value: 5,
        },
        {
          text: "10",
          value: 10,
        },
        {
          text: "All",
          value: 10,
        },
      ], // you can change the dropdown list for size per page
      sizePerPage: 20, // which size per page you want to locate as default
      pageStartIndex: 0, // where to start counting the pages
      paginationSize: this.props.noOfPages, // the pagination bar size.
      prePage: "<<", // Previous page button text
      nextPage: ">>", // Next page button text
      firstPage: ">", // First page button text
      lastPage: ">>", // Last page button text
      paginationShowsTotal: renderShowsTotal, // Accept bool or function
      hidePageListOnlyOnePage: true,
    };
    let dummy_data = [...Array(10)].map(() => {
      return {
        Id: {
          chatId: "",
          hostId: "",
        },
        bookingStatus: "",
        duration: "",
        fare: "",
        phoneNumber: "",
        reciverName: "",
        type: "",
      };
    });
    return (
      <div style={{ display: "block", height: "70vh" }}>
        <BootstrapTable
          data={dummy_data}
          options={options}
          bodyContainerClass="my-custom-body"
          headerStyle={{ padding: "0px 0px" }}
          tableStyle={{ borderRadius: "30px", border: "none" }}
          tableContainerClass="my-custom-table-class"
          bodyStyle={{
            overflow: "scroll",
            height: "calc(100vh - 350px)",
            // minHeight: "calc(100vh - 400px)",
            background: "#FFFFFF",
            borderRadius: "30px",
            position: "relative",
            minHeight: "240px",
          }}
          maxHeight="500px"
          pagination={true}
        >
          <TableHeaderColumn
            thStyle={{ padding: "20px 40px" }}
            tdStyle={{
              padding: "10px 0px 10px 40px",
              verticalAlign: "middle",
              fontWeight: "700",
            }}
            width="150px"
            className="td-header-string-example"
            isKey
            dataField="reciverName"
            dataFormat={() => (
                <SkeletonLoader width={150} height={70} borderRadius={10} />
            )}
          >
            <div style={{ display: "flex" }}>
              Vehicle Picture
            </div>
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="phoneNumber"
            tdStyle={{
              color: "black",
              fontWeight: "700",
              verticalAlign: "middle",
            }}
            width="150px"
            className="td-header-string-example"
            dataFormat={() => (
                <SkeletonLoader width={150} height={40} borderRadius={30}  />
            )}
          >
            <div style={{ display: "flex" }}>
            Vehicle Name
            </div>
          </TableHeaderColumn>
          <TableHeaderColumn
            className="td-header-string-example"
            width="150px"
            tdStyle={{
              padding: "10px",
              fontWeight: "700",
              verticalAlign: "middle",
            }}
            dataField="type"
            dataFormat={() => (
              <SkeletonLoader width={150} height={40} borderRadius={30}  />
            )}
          >
            <div style={{ display: "flex" }}>
              Price
            </div>
          </TableHeaderColumn>
          <TableHeaderColumn
            className="td-header-string-example"
            tdStyle={{ fontWeight: "700", verticalAlign: "middle" }}
            width="250px"
            dataField="fare"
            dataFormat={() => (
                <SkeletonLoader width={150} height={40} borderRadius={30}  />
            )}
          >
            <div style={{ display: "flex" }}>
              Age
            </div>
          </TableHeaderColumn>
          
          <TableHeaderColumn
            tdStyle={{
              borderRadius: "30px",
              verticalAlign: "middle",
              textAlign: "left",
              backgroundColor: "white",
            }}
            thStyle={{

              textAlign: "left",
              backgroundColor: "white",
              borderRadius: "30px",
            }}
            dataField="bookingStatus"
            width="220px"
            dataFormat={() => (
             <>
             <SkeletonLoader width={150} height={40} borderRadius={30}  />
             </>
            )}
          >
            <div>
              Actions
            </div>
          </TableHeaderColumn>
          
         
        </BootstrapTable>
      </div>
    );
  }
}
export default TableSkeleton;