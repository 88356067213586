import React, { useEffect, useRef, useState } from "react";
//     importing components
import { BackButton } from "../../Components/AbstractsComponents/BackButton/backButton";
// import { EditVehicleForm } from "../../Components/ModuleComponents/EditVehicle/EditVehicleForm/editVehicleForm";
import VehicleForm from "../../Components/ModuleComponents/VehiclesForm/VehicleForm";

import { Link, useNavigate, useParams } from "react-router-dom";
// import { LodaerPage } from "../../Components/AbstractsComponents/LodaerPage/loaderPage";
import axios from "axios";
//     impoering css files
import "./editVehicleScreen.css";
import VehiclesController, {
  useSingleVehicle,
} from "../../Controllers/vehiclesController";
import { UploadImageCard } from "../../Components/ModuleComponents/AddVehicle/UploadImageCard/uploadImageCard";
import { useCallbackPrompt } from "../../Hooks/useCallbackPrompt";
import DialogueBox from "../../Components/ModuleComponents/DialogueBox";
import ToastController from "../../Controllers/toastController";
export const EditVehicleScreen = (props) => {
  let toast = new ToastController();
  const [isFormDataUpdated, setIsFormDataUpdated] = useState(false);
  const [picturesUpdated, setPicturesUpdated] = useState(false);
  const [vehicleImages, setVehicleImage] = useState([]);
  const formLoadedFlag = useRef(false);
  const [processing, setProcessing] = useState(false);
  const [showDialogu, setShowDialogue] = useState(false);

  const params = useParams();
  const navigate = useNavigate();
  const vehicle = useSingleVehicle(params._id);

  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialogu);

  useEffect(() => {
    if (isFormDataUpdated || picturesUpdated) {
      setShowDialogue(true);
    }
  }, [isFormDataUpdated, picturesUpdated]);

  console.log(`Single Vehicle`, vehicle);

  useEffect(() => {
    formLoadedFlag.current = true;
  }, [500]);

  const getImagesDiff = (images_) => {
    let currentImages = vehicle.pictures;
    // finding images to delete.
    let imagesToDelete = [];
    let imagesToPush = images_.filter(
      (image) => !image.source?.image && image.source
    );
    for (let index = 0; index < currentImages.length; index++) {
      const ci = currentImages[index];
      let vIndex = images_.findIndex(
        (image) => image.source?.image?.key === ci.image.key
      );
      if (vIndex === -1) {
        imagesToDelete.push(currentImages[index]);
      }
    }
    return { imagesToDelete, imagesToPush };
  };

  const handleStatusChange = (id, status) => {
    console.log("id, status....", id, status);
    let data = {
      _id: id,
      enabledStatus: status,
    };
    toast.showProcessing("Changing Vehicle Status");

    VehiclesController.changeVehicleStatus(data)
      .then((res) => {
        toast.endProcessingWithSuccess("Status Changed!");
      })
      .catch((err) => {
        toast.endProcessingWithError(err);
      });
  };

  const handleOnEditVehicle = (values) => {
    // console.log(`UpdateForm: ${isFormDataUpdated}, UpdateImages: ${picturesUpdated}`)
    // // const {imagesToDelete, imagesToPush} = getImagesDiff();
    // console.log(`=>UPDATED VALUES`, values)
    console.log(`@values....`, values);

    const { imagesToDelete, imagesToPush } = getImagesDiff(vehicleImages);
    setShowDialogue(false);
    setProcessing(true);
    VehiclesController.updateVehicleData(
      params._id,
      values,
      imagesToPush,
      imagesToDelete
    )
      .then((updated) => {
        setProcessing(false);
        navigate(-1);
      })
      .catch((err) => {
        setProcessing(false);
      });
  };

  const getImagesToUpload = (images) => {
    return images.filter((i) => i.networkImage);
  };

  const handleOnChangeImages = (images) => {
    console.log(`Images`, images);

    setVehicleImage(images);
    if (formLoadedFlag.current) {
      setPicturesUpdated(true);
    }
  };

  return (
    <div>
      <div className="all-vehicle-header">
        <Link
          to="/allVehicle"
          className="react-router-link"
          style={{ margin: "auto 0px" }}
        >
          <BackButton>Edit Vehicle</BackButton>
        </Link>
      </div>
      <div className="add-vehicle-form-div">
        <div className="upload-image-div">
          <UploadImageCard
            initialImagesUrl={vehicle.pictures}
            onChange={handleOnChangeImages}
          />
        </div>
        <div className="add-vehicle-form-inner-div">
          <VehicleForm
            editingMode
            initialData={vehicle}
            updateEnabled={isFormDataUpdated || picturesUpdated}
            onUpdated={() => setIsFormDataUpdated(true)}
            onSubmit={handleOnEditVehicle}
          />
        </div>
      </div>
      <DialogueBox
        cancelNavigation={cancelNavigation}
        confirmNavigation={confirmNavigation}
        show={showPrompt}
      />
      {processing ? (
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.03)",
            zIndex: 99999,
          }}
        />
      ) : (
        false
      )}
    </div>
  );
};
