import { useState } from "react";
import GoogleMapPicker from "../../ModuleComponents/GoogleMapPicker";
import "./textFields.css";
import { BiCurrentLocation } from "react-icons/bi";

export const LocationTextField = ({
  id,
  label,
  value,
  type,
  onChange,
  onBlur,
  name,
  placeholder,
  onLoactionChange,
  onClick,
}) => {
  const [show, setShow] = useState(false);

  const handleOnFocus = () => {
    if (value === "") {
      setShow(true);
    }
  };

  return (
    <>
      <div>
        <label
          className="text-[12px] font-[600] mb-2 text-[#5a616c]"
          htmlFor={id}
        >
          {label}
        </label>
        <div className="relative w-full">
          <input
            value={value}
            type={type}
            id={id}
            className="text-fileds-input"
            onChange={onChange}
            onFocus={handleOnFocus}
            onBlur={onBlur}
            name={name}
            placeholder={placeholder}
          />
          {/* {icon ? ( */}
          <div
            className={"absolute right-5  top-[33%] cursor-pointer"}
            onClick={() => setShow(true)}
          >
            {/* <img src={icon} alt="" /> */}
            <BiCurrentLocation className="text-3xl text-[#5a616c]" />
          </div>
          {/* ) : null} */}
        </div>
      </div>

      <GoogleMapPicker
        onChange={onLoactionChange}
        show={show}
        setOpen={setShow}
      />
    </>
  );
};
