import React, { useState } from "react";
//     importing components
import { Link, useNavigate } from "react-router-dom";
import { BackButton } from "../../Components/AbstractsComponents/BackButton/backButton";
import { UploadImageCard } from "../../Components/ModuleComponents/AddVehicle/UploadImageCard/uploadImageCard";
// import { AddNewVehicle } from "../../Components/ModuleComponents/AddVehicle/AddNewVehicle/addNewVehicle";
import VehicleForm from "../../Components/ModuleComponents/VehiclesForm/VehicleForm";
import axios from "axios";
//     importing css files
import "./addVehicleScreen.css";
import ToastController from "../../Controllers/toastController";
import VehiclesController from "../../Controllers/vehiclesController";
export const AddVehicleScreen = (props) => {
  const [setLoaderButton] = useState(false);
  const [vehicleImages, setVehicleImage] = useState([]);
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();
  const AddVehiclePageLoader = () => {
    setLoaderButton(true, () => {
      axios.get("localhost:300").then((result) => setLoaderButton(false));
    });
    console.log("page lodaer");
  };

  const handleCreateVehicle = (vehicle) => {
    console.log(`Vehicle`, vehicle);
    if (vehicleImages.length > 0) {
      setProcessing(true);
      VehiclesController.createVehicle(vehicleImages, vehicle)
        .then((created) => {
          setProcessing(false);
          navigate(-1);
        })
        .catch((err) => {
          setProcessing(false);
        });
    } else {
      ToastController.error(`Please select vehicle images.`);
    }
    // VehiclesControllber.createVehicle(vehicleImages, vehicle)
    // ToastController.error(`Work under progress..!!`)
  };

  const handleOnChangeImages = (images) => {
    console.log(`Images`, images);
    setVehicleImage(images);
  };

  return (
    <div>
      <div className="all-vehicle-header">
        <Link
          to="/allVehicle"
          className="react-router-link"
          style={{ margin: "auto 0px" }}
        >
          <BackButton>Add Vehicle</BackButton>
        </Link>
      </div>
      <div className="add-vehicle-form-div">
        <div className="upload-image-div">
          <UploadImageCard onChange={handleOnChangeImages} />
        </div>
        <div className="add-vehicle-form-inner-div">
          <VehicleForm onSubmit={handleCreateVehicle} />
        </div>
      </div>
      {processing ? (
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.03)",
            zIndex: 99999,
          }}
        />
      ) : (
        false
      )}
    </div>
  );
};
