import ReduxDispatchController from "./reduxDispatchController";
import axios from "axios";
import { ERRORS } from "./errorMessages";
import store from "../Store";
import ToastController from "./toastController";
import SocketController from "../SocketController";
class AuthController {
  static SAVED_SESSION_KEY = "key@inforide.webv0.0.2";

  static BUSINESS_APPROVAL_STATUS = {
    PENDING: "pending",
    APPROVED: "approved",
    BLOCKED: "blocked",
    REJECTED: "rejected",
  };

  static login(email, password, rememberDevice) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/business/auth/login`, { email, password })
        .then((res) => {
          if (res.data.success) {
            // if(rememberDevice){
            AuthController.presistCredencials(
              res.data.data.user,
              res.data.data.token
            );
            // }
            ReduxDispatchController.AUTH.setCurrentUser(res.data.data.user);
            axios.defaults.headers.common["Authorization"] =
              res.data.data.token;
            // AuthController.handleUpdateCurrentLocation();
            resolve(true);
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(ERRORS.NETWORK_ERROR);
        });
    });
  }

  static checkAuthStatus() {
    return new Promise((resolve, reject) => {
      const currentUser = AuthController.getCurrentUser();
      axios
        .post(`/business/auth/check-auth-status`, { email: currentUser.email })
        .then((res) => {
          console.log(res.data, "@check auth status...");
          if (res.data.success) {
            let approvalStatus = res.data.data?.user?.verificationStatus;

            if (approvalStatus !== this.BUSINESS_APPROVAL_STATUS.BLOCKED) {
              AuthController.presistCredencials(
                res.data.data.user,
                res.data.data.token
              );
              ReduxDispatchController.AUTH.setCurrentUser(res.data.data.user);
              axios.defaults.headers.common["Authorization"] =
                res.data.data.token;
              SocketController.init();
              resolve(res.data.data);
            } else {
              this.logout();
              reject(false);
            }
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          reject(ERRORS.NETWORK_ERROR);
        });
    });
  }

  static varifyDelegateAccess = (invitationToken) => {
    return new Promise((resolve, reject) => {
      console.log(`@delegate access token: ${invitationToken}`);
      AuthController.logout();
      axios
        .get(`/business/auth/verify-delegate-access/${invitationToken}`)
        .then((res) => {
          console.log(
            `@delegate RES INVITE.............................`,
            res.data
          );
          if (res.data.success) {
            ReduxDispatchController.AUTH.setDelegateBar(true);
            ReduxDispatchController.AUTH.setCurrentUser(res.data.data.user);
            axios.defaults.headers.common["Authorization"] =
              res.data.data.token;
            // ReduxDispatchController.AUTH.setDelegateBar(true);

            resolve(true);
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          reject(ERRORS.NETWORK_ERROR);
        });
    });
  };

  static logout() {
    localStorage.removeItem(AuthController.SAVED_SESSION_KEY);
    axios.defaults.headers.common["Authorization"] = undefined;
    ReduxDispatchController.AUTH.setCurrentUser(null);
    ReduxDispatchController.AUTH.logout();
    ReduxDispatchController.AUTH.setSplashVisible(true);
  }

  static restorePresistedCredencials() {
    let presistedData = AuthController.getPresistedCredencials();
    console.log(`Restored data`, presistedData);
    if (presistedData) {
      ReduxDispatchController.AUTH.setCurrentUser(presistedData.user);
      axios.defaults.headers.common["Authorization"] = presistedData.token;
      // AuthController.handleUpdateCurrentLocation();
    }
    return presistedData;
  }

  static getPresistedCredencials = () => {
    let strSessionData = localStorage.getItem(AuthController.SAVED_SESSION_KEY);
    if (strSessionData) {
      return JSON.parse(strSessionData);
    } else {
      return null;
    }
  };
  static presistCredencials = (user, token) => {
    if (user && token) {
      localStorage.setItem(
        AuthController.SAVED_SESSION_KEY,
        JSON.stringify({ user, token })
      );
    } else {
      console.log(`undefined credencials: not presisted`);
    }
  };

  static getCurrentUser = () => {
    return store.getState().AuthReducer.currentUser;
  };

  static signup = (
    email,
    fullName,
    password,
    profileImage,
    location,
    phoneNumber
  ) => {
    location = JSON.stringify(location);
    console.log("Loaction......", location);
    return new Promise((resolve, reject) => {
      let toastController = new ToastController();
      toastController.showProcessing("Creating business account...");
      const formData = new FormData();
      formData.append("email", email);
      formData.append("fullName", fullName);
      formData.append("password", password);
      formData.append("location", location);
      formData.append("image", profileImage);
      formData.append("phoneNumber", phoneNumber);
      axios
        .post(`/business/auth/signup`, formData)
        .then((res) => {
          console.log(res.data);
          if (res.data.success) {
            toastController.endProcessingWithSuccess(
              `Account created successfully.`
            );
            resolve(true);
          } else {
            toastController.endProcessingWithError(res.data.error.message);
            console.log("@dealer signup err....", res.data.error.message);
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          console.log(err, "exception err at daler signup..");
          toastController.endProcessingWithError(err);
          reject(err);
        });
    });
  };

  static updateCurrentLocation = () => {
    return new Promise((resolve, reject) => {
      const currentUserId = AuthController.getCurrentUser()._id;
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log(`Position =>`, position);
          axios
            .patch(`/business/auth/update-location/${currentUserId}`, {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            })
            .then((res) => {
              console.log(`Location updated.`, res.data);
              resolve(true);
            })
            .catch((err) => {
              console.log(`Network error in updating location`);
              reject(ERRORS.NETWORK_ERROR);
            });
        },
        (error) => {
          console.log(`Error in getting current user location:`, error);
          reject(`Location permission denied`);
        }
      );
    });
  };

  static handleUpdateCurrentLocation = () => {
    AuthController.updateCurrentLocation()
      .then((updated) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  static isAuthRoute = (pathname) => {
    let authRouteMatched = false;

    const match = (path, route) => {
      let pathFound = path.match(route);
      return pathFound ? true : false;
    };

    switch (true) {
      case match(pathname, "login"):
        authRouteMatched = true;
        break;
      case match(pathname, "signUp"):
        authRouteMatched = true;
        break;
      // case match(pathname, "/"):
      //   authRouteMatched = true;
      //   break;
      default:
        authRouteMatched = false;
        break;
    }
    return authRouteMatched;
  };
}
export default AuthController;
