import React from "react";
//    importing icons
import LockIcon from "../../../Assests/Icons/lock.svg";
import { TailSpin } from "react-loader-spinner";
//    importing css files
import "./button.css";
export const Buttons = (props) => {
  const Styles = {
    default: {
      backgroundColor: props.backgroundColor,
      // width: props.width,
      // height: props.height,
      ...props.style,
    },
    offerButton: {
      backgroundColor: "#64B822",
      width: "144px",
      height: "40px",
      boxShadow: "none",
      fontSize: "16px",
      // lineHeight:"38px",
      margin: "0px",
    },
    acceptedOfferButton: {
      backgroundColor: "#2674e6",
      width: "144px",
      height: "40px",
      boxShadow: "none",
      fontSize: "16px",
      // lineHeight:"38px",
      margin: "0px",
    },
    messageButton: {
      backgroundColor: "#64B82233",
      color: "#64B822",
      width: "144px",
      height: "40px",
      boxShadow: "none",
      fontSize: "16px",
      // lineHeight:"38px",
      marginLeft: "auto",
      margin: "0px",
    },
    editButton: {
      backgroundColor: "rgba(7, 166, 255, 0.2)",
      width: "87px",
      height: "40px",
      boxShadow: "none",
      color: "#07A6FF",
      // lineHeight:"38px",
      fontSize: "16px",
      margin: "0px",
    },
    Disable: {
      color: "white",
      backgroundColor: "#C7C7C7",
      boxShadow: "none",
      width: "144px",
      height: "40px",
      // lineHeight:"40px",
      // marginLeft: "auto",
      margin: "0px",
    },
  };
  return (
    <div>
      {/* {props.disabled ? (
                <span style={{backgroundColor:"#f0f0f0", borderRadius:"50%"}}>
                  <img src={LockIcon} alt="" />
                </span>
              ) : (
                <span style={{ textAlign: "right" }}>
                  {props.badgeValue ? (
                    <span className="button-badge">{props.badgeValue}</span>
                  ) : (
                    ""
                  )}
                </span>
          )} */}

      <button
        type={props.type}
        id={props.id}
        disabled={props.disabled}
        // disabled={!props.processing ? true : props.disabled ? true : ""}
        onClick={props.processing ? () => false : props.onClick}
        className={"default-button"}
        style={
          props.messageButton
            ? Styles.messageButton
            : props.offerButton
            ? Styles.offerButton
            : props.editButton
            ? Styles.editButton
            : props.acceptedOfferButton
            ? Styles.acceptedOfferButton
            : props.default
            ? Styles.default
            : props.style
        }
      >
        <div
          style={{ position: "absolute", marginRight: -120, marginTop: -10 }}
        >
          {props.disabled ? (
            <img
              style={{
                padding: 5,
                backgroundColor: "#f0f0f0",
                borderRadius: "50%",
              }}
              src={LockIcon}
              alt=""
            />
          ) : props.badgeValue ? (
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 20,
                height: 20,
                backgroundColor: "red",
                borderRadius: "50%",
              }}
            >
              {props.badgeValue}
            </span>
          ) : (
            false
          )}
        </div>
        {props.processing ? (
          <span style={{ margin: "auto 0px" }}>
            <TailSpin
              height="30px"
              width="30px"
              color="white"
              ariaLabel="loading"
            />
          </span>
        ) : (
          <div
            style={{
              display: "flex",
              verticalAlign: "middle",
              justifyContent: "space-between",
              margin: "auto",
            }}
          >
            {props.leftIcon ? (
              <img
                src={props.leftIcon}
                className="button-icon left-icon"
                alt=""
              />
            ) : (
              ""
            )}
            <p style={{ textAlign: "left", margin: "auto" }}>
              {props.children}
            </p>
            {props.RightIcon ? (
              <img
                src={props.RightIcon}
                className="button-icon right-icon"
                alt=""
              />
            ) : (
              ""
            )}
          </div>
        )}
      </button>
      {/* Badge Span */}
    </div>
  );
};

Buttons.defaultProps = {
  default: {
    backgroundColor: "#2674e6",
  },
  backgroundColor: "#2674e6",
};
