import React, { useEffect, useState } from "react";
//     importing components
import { ImageUploadV2 } from "../../../AbstractsComponents/ImageUpload/imageUploadV2";
//     importing css files
import "./uploadImageCard.css";

export const UploadImageCard = ({ onChange, initialImagesUrl }) => {
  const [images, setImages] = useState([
    { _id: 0, source: null, objectUrl: null },
    { _id: 1, source: null, objectUrl: null },
    { _id: 2, source: null, objectUrl: null },
    { _id: 3, source: null, objectUrl: null },
    { _id: 4, source: null, objectUrl: null },
    { _id: 5, source: null, objectUrl: null },
  ]);

  useEffect(() => {
    if (initialImagesUrl) {
      let initialImgs = initialImagesUrl.map((source) => ({
        source: { ...source, networkImage: true },
        objectUrl: source.image.url,
      }));
      arrange(initialImgs);
    }
  }, []);

  const arrange = (_images = []) => {
    let emptyCount = 6 - _images.length;
    let newImages = _images.map((image, index) => ({
      _id: index,
      source: image.source,
      objectUrl: image.objectUrl,
    }));
    if (onChange) onChange(newImages);
    for (let index = 6 - emptyCount; index < 6; index++) {
      newImages.push({ _id: index, source: null, objectUrl: null });
    }
    setImages(newImages);
  };

  const onAddImage = ({ _id, source, objectUrl }) => {
    let _images = images.filter((i) => i.source);
    let imageIndex = _images.findIndex((i) => i._id === _id);
    if (imageIndex >= 0) {
      _images[_id].source = source;
      _images[_id].objectUrl = objectUrl;
    } else {
      _images.push({ source, objectUrl });
    }
    arrange(_images);
  };
  const onRemoveImage = ({ _id }) => {
    let _images = images.filter((i) => i._id !== _id && i.source);
    arrange(_images);
  };

  return (
    <React.Fragment>
      <div className="upload-image-card">
        <h1 className="upload-img-head">Upload Images</h1>
        <hr style={{ backgroundColor: "#E7E7E7", height: "1px" }} />
        <div className="uplaod-image-scroll-body">
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              padding: "6px  10px",
            }}
          >
            <ImageUploadV2
              onRemove={onRemoveImage}
              onChange={onAddImage}
              image={images[0]}
            />
            <ImageUploadV2
              onRemove={onRemoveImage}
              onChange={onAddImage}
              image={images[1]}
              id={"second"}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              padding: "0px 10px",
            }}
          >
            <ImageUploadV2
              onRemove={onRemoveImage}
              onChange={onAddImage}
              image={images[2]}
              id={"third"}
            />
            <ImageUploadV2
              onRemove={onRemoveImage}
              onChange={onAddImage}
              image={images[3]}
              id={"forth"}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              padding: "0px 10px",
            }}
          >
            <ImageUploadV2
              onRemove={onRemoveImage}
              onChange={onAddImage}
              image={images[4]}
              id={"fifth"}
            />
            <ImageUploadV2
              onRemove={onRemoveImage}
              onChange={onAddImage}
              image={images[5]}
              id={"sixth"}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
