import React, { useState } from "react";
//    importing components
import { MessageButton } from "./messageButton";
import { AcceptButton } from "./acceptButton";
import { Link } from "react-router-dom";
//    importing icons
import MessageIcon from "./messageIcon.svg";
import CrossIcon from "./cross.svg";
import AcceptIcon from "./accpetIcon.svg";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Button from "react-bootstrap/Button";
import AbstractButton, {
  ABSTRACT_BUTTON_TYPE,
} from "../../AbstractsComponents/Button/V2/button";

export const OFFER_STATUSES = {
  ACTIVE: "active",
  PENDING: "pending",
  ACCEPTED: "accepted",
  REJECTED: "rejected",
  CONFLICT: "conflict",
  CLOSED: "closed",
  COUNTER: "counter",
  CONVERSATION: "conversation",
  EXPIRED: "expired",
};

export const OfferButtons = (props) => {
  console.log(props.type, "@offer status.....");
  switch (props.type) {
    case OFFER_STATUSES.ACCEPTED:
      return (
        <div className="offer-button-div">
          <Link
            to={`/allVehicle/offerVehicle/chat/${props.offer.vehicle._id}/${props.offer._id}`}
            className="react-router-link"
          >
            <MessageButton
              isUnread={!props.offer?.conversation?.businessLastSeen} /// TODO:BUG businessLastSeen undefined
              onClick={props.onClick}
              RightIcon={MessageIcon}
              processing={props.processing}
              status={props.type}
            >
              Message
            </MessageButton>
          </Link>
        </div>
      );
    case OFFER_STATUSES.ACTIVE:
      return (
        <div
          className="offer-button-div"
          style={{ display: "flex", flexDirection: "row", zIndex: "1000" }}
        >
          <Link
            to={`/allVehicle/offerVehicle/chat/${props.offer.vehicle._id}/${props.offer._id}`}
            className="react-router-link"
          >
            <MessageButton
              isUnread={!props?.offer?.conversation?.businessLastSeen} /// TODO:BUG businessLastSeen undefined
              onClick={props.onClick}
              RightIcon={MessageIcon}
              processing={props?.processing}
            >
              Message
            </MessageButton>
          </Link>
          <div style={{ width: 10 }} />
          <div>
            <DropdownButton
              id="dropdown-item-button"
              title="Actions"
              variant="light"
            >
              <Dropdown.Item
                as="button"
                onClick={() => {
                  props.handleAcceptOffer("accepted");
                }}
                disabled={props.disabled ? true : false}
                style={
                  props.disabled
                    ? { backgroundColor: "#C7C7C7", color: "white" }
                    : {}
                }
              >
                Accepted
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                onClick={() => {
                  props.handleAcceptOffer("rejected");
                }}
              >
                Rejected
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                onClick={() => {
                  props.handleAcceptOffer("counter");
                }}
              >
                Counter
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
      );
    case OFFER_STATUSES.REJECTED:
      return (
        <div className="offer-button-div">
          <Button variant="danger" disabled>
            Rejected
          </Button>
        </div>
      );

    case OFFER_STATUSES.EXPIRED:
      return (
        <div className="offer-button-div">
          <Button variant="danger" disabled>
            Expired
          </Button>
        </div>
      );
    case OFFER_STATUSES.COUNTER:
      return (
        <div className="offer-button-div">
          <Button variant="danger" disabled>
            Counter
          </Button>
        </div>
      );
    case OFFER_STATUSES.CONFLICT:
      return (
        <div className="offer-button-div">
          {/* <span>conflict</span> */}
          <Link
            to={`/allVehicle/offerVehicle/chat/${props.offer.vehicle._id}/${props.offer._id}`}
            className="react-router-link"
          >
            <MessageButton
              isUnread={!props?.offer?.conversation?.businessLastSeen} /// TODO:BUG businessLastSeen undefined
              onClick={props.onClick}
              RightIcon={CrossIcon}
              processing={props.processing}
            >
              Message
            </MessageButton>
          </Link>
        </div>
      );

    case OFFER_STATUSES.CONVERSATION:
      return (
        <div className="offer-button-div">
          {/* <span>conflict</span> */}
          <Link
            to={`/allVehicle/offerVehicle/chat/${props.offer.vehicle._id}/${props.offer._id}`}
            className="react-router-link"
          >
            <MessageButton
              // isUnread={!props?.offer?.conversation?.businessLastSeen} /// TODO:BUG businessLastSeen undefined
              isUnread={props.isUnread}
              onClick={props.onClick}
              RightIcon={MessageIcon}
              processing={props.processing}
            >
              Message
            </MessageButton>
          </Link>
        </div>
      );
    case OFFER_STATUSES.CLOSED:
      return (
        <div className="offer-button-div">
          <Button variant="danger" disabled>
            Closed
          </Button>
          {/* <AcceptButton
            onClick={props.onClick}
            RightIcon={AcceptIcon}
            disabled
            processing={props.processing}
            status={props.type}
          >
            Disputed
          </AcceptButton> */}
        </div>
      );
    default:
      return (
        <div className="offer-button-div">
          {/* <MessageButton
            isUnread={props.isUnread}
            onClick={props.onClick}
            RightIcon={MessageIcon}
          >
            Message
          </MessageButton> */}
        </div>
      );
  }
  //   return (
  //     <div className="offer-button-div">
  //       {props.type === "Message" ? (
  //         <Link to="/allVehicle/offerVehicle/chat" className="react-router-link">
  //           <MessageButton RightIcon={MessageIcon} isUnread>
  //             {props.type}
  //           </MessageButton>
  //         </Link>
  //       ) : (
  //         <AcceptButton
  //           RightIcon={AcceptIcon}
  //           // disabled
  //         >
  //           {props.type}
  //         </AcceptButton>
  //       )}
  //     </div>
  //   );
};

// <AcceptButton
//             onClick={props.onClick}
//             RightIcon={AcceptIcon}
//             disabled
//             processing={props.processing}
//             status={props.type}
//           >
//             Rejected
//           </AcceptButton>
