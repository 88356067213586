import React, { useEffect, useState } from "react";
//      importing components
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Buttons } from "../../Components/AbstractsComponents/Button/button";
import VehicleSkeleton from "../../Components/ModuleComponents/Skeleton/vehiclesList";
import AllVehicleTable from "../../Components/CompositeComponents/Table/AllVehicle/allVehicleTable";
//      importing css files
import "./allVehicleScreen.css";
import {
  useGetAuctions,
  useGetVehiclesByPage,
} from "../../Controllers/vehiclesController";
import TableSkeleton from "../../Components/ModuleComponents/Skeleton/vehicleTableSekelton";
import Search from "../../Components/AbstractsComponents/Search";
import axios from "axios";
import { SelectFieldV2 } from "../../Components/AbstractsComponents/TextFields/textFields";
export const AllVehicleScreen = () => {
  // const [auctionId, setAuctionId] = useState(undefined);
  // const [searchList, setSearchList] = useState([]);
  // const [processing, setProcessing] = useState(false);
  // const [search, setSearch] = useState("");
  // const [searchLoading, setSearchLoading] = useState(false);
  // const [disabled, setDisabled] = useState(false);

  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  let pageNoQuery = params.get("PageNo");
  const auctionQuery = params.get("auction") || "";

  const [pageNo, setPageNo] = useState(pageNoQuery === null ? 0 : pageNoQuery);

  const qryObj = {
    pageNumber: pageNoQuery || 0,
    pageSize: 20,
    auctionId: auctionQuery,
  };
  const { auctions, loading: auctionLoading } = useGetAuctions();
  const { loading, vehicles, noOfPages } = useGetVehiclesByPage(qryObj);

  // console.log("Loading", loading)

  const [pageNoArray, setPageNoArray] = useState([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    navigate(`?PageNo=${parseInt(pageNo)}`);
    // console.log(pageNo,'page no .......................');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo]);

  const handleUpdateQueryParam = (obj) => {
    if (typeof obj === "object") {
      for (const key in obj) {
        params.set(key, obj[key]);
      }
      setParams(params);
    } else {
      // alert("you can pass only object in update params");
    }
  };

  const handleDeleteParams = (arr) => {
    if (Array.isArray(arr)) {
      arr.map((x) => params.delete(x));
      setParams(params);
    } else {
      // alert("array of params required to delete.");
    }
  };

  const handleSelectByAuction = (e) => {
    const value = e.target.value;
    if (value !== "") {
      handleUpdateQueryParam({ auction: value });
    } else {
      setTimeout(() => {
        handleDeleteParams([`auction`]);
      }, 500);
    }
  };

  return (
    <div>
      <div className="all-vehicle-header">
        <h1>Vehicles</h1>
        <div className="flex items-center gap-4 m-[0px_auto]">
          <SelectFieldV2
            containerClass={
              "!py-[3px]  sm:!py-[5px] !min-h-[50px] !min-w-[200px]"
            }
            // value={auctionQuery}
            className={"sm:!text-xs !text-[10px]"}
            placeholder={`Select Auction`}
            defaultValue={`Select Auction`}
            options={auctions}
            loading={auctionLoading}
            onChange={handleSelectByAuction}
            // defaultValue={auctionQuery}
            // defaultValue={`Select Field`}
            // loading={loading}
          />
          <Link to="/allVehicle/AddVehicle" className="react-router-link">
            <Buttons style={{ width: "192px", height: "57px" }}>
              Add Vehicle
            </Buttons>
          </Link>
          <Link to="/import" className="react-router-link">
            <Buttons style={{ width: "192px", height: "57px" }}>Import</Buttons>
          </Link>
        </div>
      </div>
      <div>
        {loading ? (
          <TableSkeleton
            pageNoArray={pageNoArray}
            setPageNoArray={setPageNoArray}
            activePageNo={parseInt(pageNo)}
            setPageNo={setPageNo}
            noOfPages={noOfPages}
          />
        ) : (
          <AllVehicleTable
            data={vehicles}
            noOfPages={noOfPages}
            pageNoArray={pageNoArray}
            setPageNoArray={setPageNoArray}
            activePageNo={parseInt(pageNo)}
            setPageNo={setPageNo}
            show={show}
            setShow={setShow}
          />
        )}
      </div>
    </div>
  );
};
