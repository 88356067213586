import React from "react";
//    importing css files
import "./welcomeToInfo.css";
export const  WelcomeToInfo =()=> {
    return (
      <React.Fragment>
        <div className="welcome-info-main-div">
          <h1>Welcome to InfoRide</h1>
          {/* <h2>inforide where car sells in minutes</h2> */}
        </div>
      </React.Fragment>
    );
  }

