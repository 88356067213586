import React, { useState, useEffect } from "react";
import "react-chat-elements/dist/main.css";
import { Picker } from "emoji-mart";
//    importing images
import UserMessageImg from "../../../../Assests/Images/chatImag.png";
import SendIcon from "../../../../Assests/Icons/chatSendIcon.svg";
// import AtIcon from "../../../Assests/Icons/chatAtIcon.svg";
import ClockSvg from "../../../../Assests/Icons/clockSvg.svg";
import RefreshSvg from "../../../../Assests/Icons/refreshSvg.svg";
// import ChatSkeleton from '../../../ModuleComponents/Skeleton/chatSkeleton'
//    importing css files
import "emoji-mart/css/emoji-mart.css";
import "./chatPanelCard.css";
import { dateToShortTime } from "../../../../Utils/common";
import MessageController from "../../../../Controllers/messageController";
import VehiclesController, {
  getFirstImage,
} from "../../../../Controllers/vehiclesController";
import CloseDealButton from "../../Offer/closeDealButton";
export const ChatPanelCard = (props) => {
  const [messageBody, setMessageBody] = useState("");
  // console.log(props.conversation, "@conversation........");
  const vehicle = props.conversation.vehicle;
  const messages = props.conversation.messages;

  const handleOnChangeText = (e) => {
    setMessageBody(e.target.value);
  };

  console.log("Offer....", props?.offer);

  const handleSendMesssage = () => {
    if (messageBody) {
      new MessageController(
        messageBody,
        vehicle._id,
        props.offerId,
        props.conversation.buyer._id
      ).send();
      setMessageBody("");
    }
  };

  const handleKeyDown = (e) => {
    if (e.code === "Enter" && messageBody !== "") {
      handleSendMesssage();
    }
  };

  const handleResendMessage = (message) => {
    new MessageController(
      message.body,
      vehicle._id,
      props.offerId,
      props.conversation.buyer._id,
      message._id
    ).reSend();
  };

  useEffect(() => {
    VehiclesController.markSeen(props.offerId);
  }, []);

  // const [showEmojis, setShowEmojis] = useState(false);
  // const [menuItems, setMenuItems] = useState([]);
  // const [InputChatValue, setInputChatValue] = useState();

  // const d = new Date();
  // var hours = d.getHours();
  // var minutes = d.getMinutes();
  // var ampm = hours >= 12 ? "PM" : "AM";
  // hours = hours % 12;
  // hours = hours ? hours : 12; // the hour '0' should be '12'
  // minutes = minutes < 10 ? "0" + minutes : minutes;
  // var strTime = hours + ":" + minutes + " " + ampm;

  // const InputValueHandle = (Values) => {
  //   console.log(Values);
  //   setInputChatValue(Values);
  // };
  // const clickHandler = (Value) => {
  //   console.log("chat inpit button get value");
  //   // const items = menuItems;
  //   setMenuItems((menuItems) => [...menuItems, Value]);
  //   setInputChatValue("");
  //   setShowEmojis(false);
  // };

  // const addEmoji = (e) => {
  //   let sym = e.unified.split("-");
  //   let codesArray = [];
  //   sym.forEach((el) => codesArray.push("0x" + el));
  //   let emoji = String.fromCodePoint(...codesArray);
  //   setInputChatValue(InputChatValue + emoji);
  // };

  // const DeleteMessage = (ID) =>{
  //     setMenuItems(menuItems => menuItems.filter(item => item.id !== ID));
  // }
  // const replyMessage= (ID) =>{
  //     const index = menuItems.find(ls => ls.id === ID); //use id instead of index
  //    setReply(true);
  //    setReplyMessage(replyMessageValue => [index.title])
  //    console.log(index.title)
  // }
  useEffect(() => {
    // Update the document title using the browser API
    document.getElementsByClassName("chat-body-panel")[0].scrollTop =
      document.getElementsByClassName("chat-body-panel")[0].scrollHeight;
  });

  const MyMessage = ({ message }) => {
    const isSender = message.business ? true : false;
    if (isSender) {
      return (
        <div className="message-main-div-sender ">
          <div
            style={{
              flex: 1,
              display: "flex",
              maxWidth: 20,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {message.status === MessageController.MESSAGE_STATUS.SENDING ? (
              <img width={15} height={15} src={ClockSvg} />
            ) : (
              false
            )}
          </div>
          <div class="talk-bubble-sender triangle right-top">
            <div class="talktext-sender">
              <p className="talktext-sender-message">{message.body}</p>
              <p className="sender-time">{dateToShortTime(message.sentTime)}</p>
            </div>
          </div>
          {message.status === MessageController.MESSAGE_STATUS.FAILD ? (
            <div
              onClick={() => handleResendMessage(message)}
              style={{
                flex: 1,
                display: "flex",
                maxWidth: 40,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <img width={15} height={15} src={RefreshSvg} />
              <span style={{ fontSize: 12 }}>Resend</span>
            </div>
          ) : (
            false
          )}
        </div>
      );
    } else {
      return (
        <div className="message-main-div-reciever">
          <div
            style={{
              flex: 1,
              display: "flex",
              maxWidth: 20,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <img width={15} height={15} src={ClockSvg}/> */}
          </div>
          <div class="talk-bubble-reciever triangle right-top-reciever">
            <div class="talktext-reciever">
              <p className="talktext-reciever-message">{message.body}</p>
              <p className="reciever-time">
                {dateToShortTime(message.sentTime)}
              </p>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="chat-panel-card">
      <div
        style={{
          borderBottom: "1px solid #ECF7FF",
          height: "91px",
          verticalAlign: "middle",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex" }}>
          <img
            src={getFirstImage(vehicle.pictures).avatar.url}
            style={{
              width: "73px",
              height: "55px",
              borderRadius: 10,
              margin: "auto 15px auto 30px",
            }}
            alt=""
          />
          <div style={{ margin: "auto 0px", textAlign: "left" }}>
            <span
              style={{
                marginTop: "20px",
                color: "#16478E",
                fontWeight: "600",
                fontSize: "18px",
              }}
            >
              {vehicle.title}
            </span>
            <br />
            <span
              style={{
                marginLeft: "0px",
                color: "#030303",
                fontWeight: "500",
                fontSize: "15px",
              }}
            >
              ${props?.offer?.amount}
            </span>
          </div>
        </div>
        <div
          style={{
            margin: "auto 0px",
            color: "#1D3161",
            fontSize: "22px",
            fontWeight: "500",
          }}
        ></div>
        <div
          style={{
            backgroundColor: "",
            content: "",
            width: "173px",
            height: "58px",
            margin: "auto 40px auto 0px",
          }}
        >
          <div style={{ height: 5 }} />
          {props.offer?.status !== "accepted" ? null : (
            <CloseDealButton
              offer={props.offer}
              conversation={props.conversation}
            />
          )}
        </div>
      </div>
      <div className="chat-body-panel min-h-[calc(100%-200px)] overflow-auto">
        <div className="chat-body-innder-div">
          {/* <div className='chat-body-participent-client'> */}
          {messages.map((item) => (
            <MyMessage message={item} />
          ))}
        </div>
      </div>
      <div className="sticky bottom-1">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px 20px",
            borderTop: "1px solid #D9D9D9",
          }}
        >
          {/* {showEmojis === true ? (
          <div>
            <Picker
              onSelect={addEmoji}
              style={{ position: "absolute", bottom: "70px" }}
            />
          </div>
        ) : (
          ""
        )} */}

          {/* <img
          src={EmojiIcon}
          className="chat-input-emoji"
          onClick={() => setShowEmojis(!showEmojis)}
          alt=""
        /> */}
          <input
            placeholder="Start typing..."
            onChange={handleOnChangeText}
            className="chat-panel-input outline-none "
            onKeyDown={handleKeyDown}
            value={messageBody}
          />
          {/* <img src={AtIcon} className="footer-at-icon" alt=""/> */}
          <button
            style={{
              margin: "auto",
              backgroundColor: "transparent",
              border: "none",
            }}
            onClick={handleSendMesssage}
          >
            <img src={SendIcon} alt="" />
          </button>
        </div>
      </div>
    </div>
  );
};
