import React from "react";
//    importing icon
import BackArrow from "../../../Assests/Icons/backArow.svg";
//    importing css files
import "./backButton.css";
export const BackButton = (props) => {
  return (
    <button className="back-btn" style={props.style}>
      <img
        src={BackArrow}
        className="back-btn-img"
        style={props.imgStyle}
        alt=""
      />
      {props.children}
    </button>
  );
};
