import React, { useState } from "react";
import Modal from "./Modal/Modal";

const DialogueBox = ({
  show,
  setShow,
  processing,
  confirmNavigation,
  cancelNavigation,
}) => {
  return (
    <Modal
      visible={show}
      closeModal={() => setShow(false)}
      wrapperClass={`max-w-[450px] w-full`}
    >
      <div className="w-full p-10 bg-white rounded-md min-h-[150px] ">
        <h3 className="mb-4 font-bold capitalize">
          Do you want to Leave this page without saving ?
        </h3>
        <div className="flex gap-3">
          {/* {success ? (
            <button
              onClick={handleOk}
              className="w-full px-3 py-2 text-center text-white rounded-lg bg-primary "
            >
              ok
            </button>
          ) : ( */}
          <>
            <button
              className="w-full inline-flex justify-center items-center px-3 py-2 text-center text-white rounded-lg bg-primary "
              onClick={confirmNavigation}
            >
              Leave Page
            </button>
            <button
              className="w-full px-3 py-2 text-center bg-gray-300 rounded-lg text-dark1 "
              onClick={cancelNavigation}
            >
              Stay on Page
            </button>
          </>
          {/* )} */}
        </div>
      </div>
    </Modal>
  );
};

export default DialogueBox;
