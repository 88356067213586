import React, { useState } from "react";
//     importing icons
//     importing css files
import "./textFields.css";
import { AiFillCloseCircle } from "react-icons/ai";
import { IoAddCircleOutline } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";

export const TextFields = (props) => {
  const myArray = props.textIconArr;

  return (
    <div
      className={
        props.modal ? "text-modal-input-main-div" : "text-input-main-div"
      }
    >
      <label htmlFor={props.id}>{props.label}</label>
      <br />
      <input
        value={props.value}
        id={props.id}
        type={props.type}
        onChange={props.onChange}
        onBlur={props.onBlur}
        name={props.name}
        placeholder={props.placeholder}
        className={
          props.modal ? "text-fileds-modal-input" : "text-fileds-input"
        }
        style={props.style}
      />
      {props.textIconArr ? (
        <div
          className={
            props.modal ? "text-modal-input-icons" : "text-input-icons"
          }
        >
          {myArray.map((ls) => (
            <img src={ls} alt="" />
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export const FormTextField = (props) => {
  return (
    <div>
      <label className="add-vehicle-label" htmlFor={props.id}>
        {props.label}
      </label>
      <input
        value={props.value}
        type={props.type}
        id={props.id}
        disabled={props.disabled}
        className="add-vehicle-input"
        onChange={props.onChange}
        onBlur={props.onBlur}
        name={props.name}
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
        style={{ color: "black" }}
      />
    </div>
  );
};

export const AddTagInputField = ({
  label,
  name,
  type,
  placeholder,
  onChange,
  labelClass,
  className,
  options,
  value,
  onAddItem,
  onDeleteItem,
  onUpdateItem,
  onEdit,
  ...rest
}) => {
  const [editIndex, setEditIndex] = useState(undefined);
  // console.log(value, "@value.....");

  const handleEdit = (value, index) => {
    onEdit(value);
    setEditIndex(index);
  };

  const handleAddUpdate = () => {
    console.log("@knownissues test 1");
    if (editIndex >= 0 && onUpdateItem && value !== "") {
      console.log("@knownissues test 2");

      onUpdateItem(value, editIndex);
      setEditIndex(undefined);
    } else {
      console.log("@knownissues test 3");

      onAddItem && onAddItem(value);
    }
  };

  return (
    <div className="flex flex-col text-sm sm:text-base w-full">
      <label
        className={`text-[#566589] font-medium text-base uppercase mb-[2px] ${labelClass}`}
        htmlFor={name}
      >
        {label}
        {/* <span> *</span> */}
      </label>

      <div className="border-[#c2c2c2] border border-solid rounded-[20px] overflow-hidden flex flex-col items-center">
        <div className="relative w-full">
          <input
            className={`block w-full  border-b border focus:outline-none shadow-none   !pr-6 ${className}`}
            type={type}
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            value={value}
            {...rest}
          />
          <button
            type="button"
            className="absolute inset-y-0 my-auto   h-full px-2 right-0 flex justify-center items-center bg-green-700 text-white"
            onClick={handleAddUpdate}
          >
            {editIndex >= 0 ? "Update" : "Add"}
          </button>
        </div>

        <ul className="h-[100px] w-full overflow-y-auto py-2 flx !px-0 flex-col gap-1">
          {options?.length > 0 &&
            options?.map((item, index) => {
              return (
                <li
                  className="px-2 relative w-full flex items-center justify-between gap-1"
                  key={index}
                >
                  <p className="text-xs text-dark1">{item}</p>

                  <div className="flex gap-1">
                    <button
                      type="button"
                      onClick={() => handleEdit(item, index)}
                    >
                      <FiEdit className="text-sm" />
                    </button>
                    <button
                      type="button"
                      onClick={() => onDeleteItem && onDeleteItem(index)}
                    >
                      <AiFillCloseCircle className="text-red-400 text-sm " />
                    </button>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export const FormTextArea = (props) => {
  return (
    <div>
      <label className="add-vehicle-label" htmlFor={props.id}>
        {props.label}
      </label>
      <textarea
        value={props.value}
        type={props.type}
        id={props.id}
        disabled={props.disabled}
        className="add-vehicle-input"
        onChange={props.onChange}
        onBlur={props.onBlur}
        name={props.name}
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
        style={{
          color: "black",
          minHeight: 120,
          paddingTop: 10,
          paddingBottom: 10,
        }}
      />
    </div>
  );
};

export const FormSelectField = (props) => {
  return (
    <div
      style={{ position: "relative", display: "flex", flexDirection: "column" }}
    >
      <label className="add-vehicle-label" htmlFor={props.id}>
        {props.label}
      </label>
      <select
        className="add-vehicle-input"
        value={props.value}
        type={props.type}
        id={props.id}
        onChange={props.onChange}
        onBlur={props.onBlur}
        name={props.name}
      >
        {props.children}
      </select>
      {/* <img src={SelectIcon} className="select-filed-arrow" /> */}
    </div>
  );
};
export const FormSelectFieldOption = (props) => {
  return <option>{props.children}</option>;
};

export const SelectFieldV2 = ({
  label,
  options,
  value,
  onChange,
  placeholder,
  defaultValue,
  className,
  loading,
  containerClass,
  icon,
  isRequired,
  ...rest
}) => {
  const [outline, setOutLine] = useState(false);

  return (
    <div className={`relative ${className}`}>
      <label
        htmlFor={rest.id}
        className="block !text-sm font-medium text-neutral-900"
      >
        {label}
        {isRequired && <span className="text-r1">*</span>}
      </label>
      <div
        className={`relative flex rounded-[30px] justify-between  
        items-center w-full !py-[0px] !bg-white 2xl:!min-h-[50px] !min-h-[40px] !overflow-hidden  !border-[#F1F2F4] !border !gap-x-2 2xl:!gap-x-3 
        ${outline && !loading ? "!border-blue-600" : ""}
      ${!icon && "!pl-3 !py-[12px]"}
      ${containerClass}`}
      >
        <select
          {...rest}
          value={value}
          onChange={onChange}
          disabled={loading}
          className={`!bg-white 2xl:!text-base !h-full hover:!cursor-pointer !text-sm after:!content-["&#8964;"] !outline-none !w-full ${
            value !== "" ? "" : "text-neutral-900"
          } ${className}`}
          onFocus={() => setOutLine(true)}
          onBlurCapture={() => setOutLine(false)}
        >
          {placeholder && (
            <option value="" disabled selected>
              {placeholder}
            </option>
          )}
          {defaultValue && <option value={``}>{defaultValue}</option>}
          {options?.map(({ _id, title }) => (
            <option key={_id} value={_id}>
              {title}
            </option>
          ))}
        </select>
        {/* {loading && (
          <div className="absolute rounded-[30px]  w-full h-full top-0 left-0 flex items-center justify-center pr-1 bg-gray1 pointer-events-none">
            <Loading className="text-grayLight2" size={15} loading={true} />
          </div>
        )} */}
      </div>
    </div>
  );
};
