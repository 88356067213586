import React, { useEffect, useState } from "react";
import {
  FormTextField,
  FormSelectField,
  FormSelectFieldOption,
} from "../AbstractsComponents/TextFields/textFields";
import { VINSchemaValidation } from "../../Validations/validations";
import { Row, Col } from "react-bootstrap";
import { useFormik } from "formik";
import { Buttons } from "../AbstractsComponents/Button/button";
import VehiclesController from "../../Controllers/vehiclesController";
import ToastController from "../../Controllers/toastController";

export const VINForm = ({ onVINDecoded, editingMode, initialData }) => {
  // const [errorMessage, setErrorMessage] = useState("");
  // const [vehicleData,setVehicleData] = useState("");
  // const [vinValue,setVinValue]= useState("");

  // const vinLoading = useState(false);
  // const Loading = useState(false);
  // const [LoaderButton,setLoaderButton] = useState(false);

  // const VinSubmit = (values) => {
  //   LookUpVin({...getFieldProps("VIN")}.value)
  // };

  // const LookUpVin = (VIN) =>{
  //   console.log("VIN value get")
  //   console.log(VIN)
  //   console.log("VIN value get")
  //   OnsubmitVin({
  //     year: "1997",
  //     modal: "1920",
  //     make: "Audi",
  //   })
  // }
  // const OnsubmitVin = (Data) =>{
  //   console.log("Get the Data afetr vin Match")
  //   console.log(Data)
  //   console.log("Get the Data afetr vin Match")
  //   setVehicleData(Data)
  //   props.GetVinData(Data);

  //   setLoaderButton(false)
  // }

  const [processing, setProcessing] = useState(false);

  const handleSubmitVin = ({ VIN }) => {
    console.log(`Looking up VIN:${VIN}`);
    setProcessing(true);
    VehiclesController.decodeVIN(VIN)
      .then((decodedVinSpescifications) => {
        onVINDecoded(decodedVinSpescifications);
        setProcessing(false);
      })
      .catch((err) => {
        setProcessing(false);
        ToastController.error(err);
      });
    // setTimeout(() => {
    //     setProcessing(false);
    //     if(onVINDecoded) onVINDecoded({
    //       VIN: VIN,
    //       title: "Impreza Premium",
    //       priceMinRange: "",
    //       priceMaxRange: "",
    //       year:2020,
    //       modal: "Impreza Premium",
    //       make: "Subaru",
    //       fuleType: "Gasoline",
    //       kmsDriven: 1000,
    //       trim: "2.0i Premium PZEV",
    //       interior: "interior",
    //       exterior: "exterior",
    //       transmission: "Manual",
    //       engine: "2.0L H4 SMPI",
    //       mileageHighway: 22,
    //       mileageCity: 25,
    //       madeIn: "Japan",
    //     })
    // }, 1000)
  };

  const { handleSubmit, getFieldProps, setValues, touched, errors } = useFormik(
    {
      initialValues: {
        VIN: "",
      },
      validationSchema: VINSchemaValidation,
      onSubmit: handleSubmitVin,
    }
  );

  useEffect(() => {
    if (editingMode && initialData) {
      setValues({ VIN: initialData.VIN });
    }
  }, []);

  if (editingMode) {
    return (
      <Row>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <FormTextField
            name="VIN"
            id="VIN"
            type="text"
            label="VIN"
            disabled
            placeholder="1KMHH82109MJ"
            {...getFieldProps("VIN")}
            // onChange={(e)=>setVinValue(e.target.value)}
          />
          {/* <ErrorMessage name="name" render={renderError} /> */}
          <span className="errorMessage">
            {touched["VIN"] && errors["VIN"]}
          </span>
        </Col>
      </Row>
    );
  } else {
    return (
      <Row>
        <Col xl={8} lg={8} md={8} sm={6} xs={12}>
          <FormTextField
            name="VIN"
            id="VIN"
            type="text"
            label="VIN"
            placeholder="1KMHH82109MJ"
            {...getFieldProps("VIN")}
            // onChange={(e)=>setVinValue(e.target.value)}
          />
          {/* <ErrorMessage name="name" render={renderError} /> */}
          <span className="errorMessage">
            {touched["VIN"] && errors["VIN"]}
          </span>
        </Col>
        <Col xl={4} lg={4} md={4} sm={6} xs={12} className="my-auto">
          <Buttons
            default
            processing={processing}
            style={{
              width: "100%",
              height: "50px",
              borderRadius: "20px",
              margin: " 40px auto auto",
            }}
            onClick={handleSubmit}
            type="button"
          >
            Lookup
          </Buttons>
        </Col>
      </Row>
    );
  }
};
