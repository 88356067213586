import { IsAuthenticated } from "../types";
const state = {
  UserAuthenticate: false,
};
function InfoRideRuducers(mState = { ...state }, action) {
  switch (action.type) {
    case IsAuthenticated:
      if (action.payload === null || action.payload === undefined) {
      } else {
        mState.UserAuthenticate = action.payload;
        console.log(action.payload)
      }
      return deepCopy(mState);
    default:
      return deepCopy(mState);
  }
}

const deepCopy = (obj) => {
  const newObj = JSON.parse(JSON.stringify(obj));
  return newObj;
};

export default InfoRideRuducers;
