import React from "react";
//     importing components
import { Buttons } from "../../AbstractsComponents/Button/button";
import { useNavigate } from "react-router-dom";
//     importing icons
import PasswordSuccessImg from "../../../Assests/Icons/passwordSuccessImg.svg";
//     importing css files
import "./forgetPasswordModal.css";

export const PasswordChangeSuccessModal = (props) => {
  let navigate = useNavigate();
  return (
    <div className="forget-password-modal-div">
      <div style={{ margin: "auto", textAlign: "center" }}>
        <div
          className="otp-header-div"
          style={{ fontWeight: "600", fontSize: "22px" }}
        >
          <h5>Password changed successfully!</h5>
        </div>
        <div className="flex items-center justify-center mx-auto">
          <img src={PasswordSuccessImg} alt="" />
        </div>
        <div>
          <a href="#" style={{ textDecoration: "none" }}>
            <Buttons
              style={{
                width: "192px",
                height: "57px",
                margin: "20px auto 30px auto",
              }}
              onClick={() => navigate("/")}
            >
              Back to login
            </Buttons>
          </a>
        </div>
      </div>
    </div>
  );
};
