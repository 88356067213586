import produce from "immer";
import { AUTH, CONVERSATIONS, VEHICLES } from "../types";

const state = {
    vehicles: [],
    loading: false,
    loadingOffers:false,
    loadingConversation: false,
}


const getConversationIndexes = (vehicles, vehicleId, offerId) => {
    const res = {success: false, vehicleIndex: -1, offerIndex: -1};
    const vehicleIndex = vehicles.findIndex(vehicle => vehicle.payload._id === vehicleId);
    if(vehicleIndex >= 0){
        const offerIndex = vehicles[vehicleIndex].offers.findIndex(offer => offer.payload._id === offerId);
        if(offerIndex >= 0){
            res.success = true;
            res.vehicleIndex = vehicleIndex;
            res.offerIndex = offerIndex
        }else{
            console.log(`Invalid offer ${offerId}`)
        }
    }else{
        console.log(`Invalid vehicle ${vehicleIndex}`)
    }
    return res;
}

const VehiclesReducer = (mState = {...state}, action) => {
    switch(action.type){
        case VEHICLES.SET_KEY_VALUE:
            return produce(mState, draftState => {
                draftState[action.payload.key] = action.payload.value;
            })
        case VEHICLES.SET_OFFERS:   
            return produce(mState, draftState => {
                const {vehicleId, offers} = action.payload;
                const vehicleIndex = draftState.vehicles.data.findIndex(vehicle => vehicle.payload._id === vehicleId);
                if(vehicleIndex >= 0){
                    draftState.vehicles.data[vehicleIndex].offers = offers;
                    draftState.vehicles.data[vehicleIndex].payload.numberOfOffers = offers.length;
                }else{
                    console.log(`Invalid vehicle Id: ${vehicleId}`)
                }
            });
        case VEHICLES.PUSH_VEHICLE:
            return produce(mState, draftState => {
                console.log(`#:${draftState.vehicles.data.length}Vehicle pushing..`)
                console.log(action.payload);
                draftState.vehicles.data.unshift(action.payload);
                console.log(`#:${draftState.vehicles.length} pushed.!`)
            });
        case VEHICLES.UPDATE:
            return produce(mState, draftState => {
                console.log(`TU:UPDATE 01`);
               let vehicleIndex = draftState.vehicles.data.findIndex(vehicle => vehicle.payload._id === action.payload.payload._id);
               console.log(`Updating vehicle index${vehicleIndex}`)
               if(vehicleIndex >= 0){
                draftState.vehicles.data[vehicleIndex] = action.payload;
               }else{
                console.log(`Vehicle not found to update.`, action.payload);
               }
            });
        case VEHICLES.UPDATE_DATA:
            return produce(mState, draftState => {
                console.log(`TU:UPDATE_DATE 01`);
                let vehicleIndex = draftState.vehicles.data.findIndex(vehicle => vehicle._id === action.payload._id);
                if(vehicleIndex >= 0){
                    draftState.vehicles.data[vehicleIndex] = {...draftState.vehicles.data[vehicleIndex], ...action.payload};
                }else{
                console.log(`Vehicle not found to update.`, action.payload);
                }
            });
        case VEHICLES.UPDATE_ENABLED_STATUS:
            return produce(mState, draftState => {
                let vehicleIndex = draftState.vehicles.data.findIndex(vehicle => vehicle._id === action.payload.vehicleId);
                if(vehicleIndex >= 0){
                    draftState.vehicles.data[vehicleIndex].enabledStatus = action.payload.enabledStatus
                }else{
                console.log(`Vehicle not found to update status.`, action.payload);
                }
            });
        case VEHICLES.DELETE:
            return produce(mState, draftState => {
                draftState.vehicles = draftState.vehicles.data.filter(vehicle => vehicle._id !== action.payload);
            });

        case VEHICLES.ON_OFFER_ACCEPTED:
            return produce(mState, draftState => {
                const {vehicleId, offerId, status} = action.payload;
                console.log('running......................................');
                console.log("PAYLAOD..................................................", action.payload)
                
                let vehicleIndex = draftState.vehicles.data.findIndex(vehicle => vehicle.payload._id === vehicleId);
                console.log(vehicleIndex,'vehicle idex...............');

                if(vehicleIndex >= 0){
                    if(status === 'accepted'){
                        console.log('accepted.........................');
                        let currentVehicle = draftState.vehicles.data[vehicleIndex];
                        // console.log(currentVehicle,'current vehicle ..................');

                        // console.log(currentVehicle.offers,'before offers........................');
                        
                        currentVehicle.offers = currentVehicle.offers.map((offer)=>(

                            offer.payload._id === offerId  ? {...offer,
                            action: 'accepted',
                            payload:{
                                ...offer.payload,
                                status:'accepted'
                            }
                        }:{...offer,
                            action: offer.action === "actve"? 'rejected':offer.action,
                            payload:{
                                ...offer.payload,
                                status:offer.payload.status === "actve"? 'rejected':offer.payload.status
                            }
                        }
                        ))


                       draftState.vehicles.data[vehicleIndex] = currentVehicle;

                        console.log("OFFER AFTER.........",currentVehicle.offers)
                    }else{
                        let currentVehicle = draftState.vehicles.data[vehicleIndex];
                        currentVehicle.offers = currentVehicle.offers.map((offer) => (
                            
                            offer.payload._id === offerId ? {...offer,
                                action: status,
                                payload:{
                                    ...offer.payload,
                                    status:status
                                }
                            }: {...offer}
                        ))

                        draftState.vehicles.data[vehicleIndex] = currentVehicle;
                    }
                    
                }
            
            })
        case VEHICLES.ON_OFFER_CLOSED:
            return produce(mState, draftState => {
                const {vehicleId, offerId } = action.payload;
                const {success, vehicleIndex, offerIndex} = getConversationIndexes(draftState.vehicles.data, vehicleId, offerId)
                if(success){
                    draftState.vehicles.data[vehicleIndex].payload.vehicleSellingStatus = "sold"
                    draftState.vehicles.data[vehicleIndex].offers[offerIndex].status = "closed"
                    // console.log(`Vehicle status marked status marked.${draftState.vehicles[vehicleIndex].vehicleSellingStatus}`)
                }else{
                    console.log(`Invalid vehicleId, marking closed, ${vehicleId}, offerId:${offerId}`)
                }
            })
        case VEHICLES.ON_OFFER_DISPUTE:
            return produce(mState, draftState => {
                const {vehicleId, offerId } = action.payload;
                const {success, vehicleIndex, offerIndex} = getConversationIndexes(draftState.vehicles.data, vehicleId, offerId)
                if(success){
                    // draftState.vehicles[vehicleIndex].payload.vehicleSellingStatus = "sold"
                    draftState.vehicles.data[vehicleIndex].offers[offerIndex].status = "conflict"
                    // console.log(`Vehicle status marked status marked.${draftState.vehicles[vehicleIndex].vehicleSellingStatus}`)
                }else{
                    console.log(`Invalid vehicleId, marking closed, ${vehicleId}, offerId:${offerId}`)
                }
            })
        case CONVERSATIONS.SET_VEHICLE_OFFER_CONVERSATION:
            return produce(mState, draftState => {
                const offer = action.payload;
                const vehicleId = offer.vehicle._id;
                const offerId = offer._id;
                const {success, vehicleIndex, offerIndex} = getConversationIndexes(draftState.vehicles.data, vehicleId, offerId)
                if(success){
                    draftState.vehicles.data[vehicleIndex].offers[offerIndex] = {
                        ...draftState.vehicles.data[vehicleIndex].offers[offerIndex],
                        conversation: {
                            vehicle: offer.vehicle, 
                            messages: offer.conversation.message,
                            buyer: offer.buyer
                        }
                    }
                }
            })
        case CONVERSATIONS.PUSH_MESSAGE:
            return produce(mState, draftState => {
                const {vehicleId, offerId, message} = action.payload;
                const {success, vehicleIndex, offerIndex} = getConversationIndexes(draftState.vehicles.data, vehicleId, offerId)
                if(success){
                    draftState.vehicles.data[vehicleIndex].offers[offerIndex].conversation.messages.push(message);
                }
            })
        case CONVERSATIONS.UPDATE_MESSAGE:
            return produce(mState, draftState => {
                const {vehicleId, offerId, message, messageId} = action.payload;
                const {success, vehicleIndex, offerIndex} = getConversationIndexes(draftState.vehicles.data, vehicleId, offerId)
                if(success){
                    const messageIndex = draftState.vehicles.data[vehicleIndex].offers[offerIndex].conversation.messages.findIndex(msg => msg._id === messageId);
                    if(messageIndex >= 0){
                        draftState.vehicles.data[vehicleIndex].offers[offerIndex].conversation.messages[messageIndex] = {...message}
                    }else{
                        console.log(`Invalid Message id:${message._id}`)
                    }
                }
            })
        case AUTH.LOGOUT:
            return {...state}
        default: 
            return {...mState};
    }
}
export default VehiclesReducer;








/// iterating though each offer in vehicle
// for (let index = 0; index < vehicle.offers.length; index++) {
//     const offer = vehicle.offers[index];
//     /// if offer id marches with any index
// //         // console.log(`Offer#${index} :${JSON.stringify(offer)}`, )
// //         // if(offer.payload._id === offerId){
// //         //     console.log(offer)
// //         //     /// marking as accepted
// //         //     draftState.vehicles.data[vehicleIndex].offers[index].action = "accepted";
// //         //     draftState.vehicles.data[vehicleIndex].offers[index].payload.status = "accepted";
// //         //     draftState.vehicles.data[vehicleIndex].offers[index].payload.conversation = {
// //         //         businessLastSeen: true,
// //         //         buyerLastSeen: true,
// //         //         startedAt: new Date()
// //         //     }
// //         // } 
// //         // else{
// //         //     /// for all others marking as rejected.
// //         //     draftState.vehicles.data[vehicleIndex].offers[index].action = "rejected";
// //         //     draftState.vehicles.data[vehicleIndex].offers[index].payload.status = "rejected";
// //         // }
//     if(offer.payload._id === offerId){
//         console.log("offer Data", offer)
//     }
// }




//  finding vehicle index with vehicle id.
// let vehicleIndex = draftState.vehicles.data.findIndex(vehicle => vehicle.payload._id === vehicleId);
// //// if vehicle id is valid.
// if(vehicleIndex >= 0){
//     const vehicle = draftState.vehicles.data[vehicleIndex];
    
// }else{
//     console.log(`Invalid vehicle id:${vehicleId}, not found`);
// }