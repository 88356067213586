import produce from "immer";
import { ACTIONS } from "../types";

const state = {
   loader: {
       visible: false,
       progress: 0,
   }
}

const ActionsReducer = (mState = {...state}, action) => {
    switch(action.type){
        case ACTIONS.SET_LOADER_KEY_VALUE:
            return produce(mState, draftState => {
                draftState.loader[action.payload.key] = action.payload.value;
            });
        default:
            return {...mState};
    }
}
export default ActionsReducer;