import ExcelToJson from './excelToJson';
import { useState } from 'react';
import VehiclesController from './vehiclesController'
import ToastController from '../../Controllers/toastController';
import { Link } from 'react-router-dom';
function TableLITextInput({value, onChange}) {
  if(value.length > 150){
    return (
      <td style={{padding: 2}}>
        <textarea style={{display:"flex",padding: 10, border: 0, minWidth: 50, maxWidth: 400}} onChange={(e) => onChange(e.target.value)} type="text" value={value}/>
      </td>
    )
  }else{
    return (
      <td style={{padding: 2}}>
        <input style={{display:"flex",padding: 10, border: 0, minWidth: 50, maxWidth: 400}} onChange={(e) => onChange(e.target.value)} type="text" value={value}/>
      </td>
    )
  }
}

function TableTR({index, row ,headers, onChange}) {
  return (
    <tr key={index}>{ headers.map((header, index_) => <TableLITextInput onChange={(value) => onChange(index, headers[index_], value)} value={row[headers[index_]]}/>)} </tr>
  )
}

function ImportVehicleScreen() {
  const [processing, setProcessing] = useState(false);
  const [progress, setProgress] = useState({seek:0, peak:0})
  const [error, setError] = useState("")
  const [headers, setHeaders] = useState([]);
  const [data, setData] = useState([]);
  const validHeaders = ["title",	"driveTrain", 	"VIN", 	"description",	"engine",	"exterior",	"fuleType",	"interior",	"KMDriven",	"madeIn",	"make",	"mileageCity",	"mileageHighway",	"priceMaxRange",	"priceMinRange",	"transmission",	"trimLevel",	"style",	"driveType",	"year",	"modal", "image", "avatar", "latitude", "longitude" ]
  // const validHeaders = ["email", "total","date" ,"invoice_title", "notes", "company_name", "contact_name", "currency", "tax"]
  const handleOnChange = (index, key, value) => {
    let _data = [...data]
    _data[index][key] = value;
    setError("")
    setData(_data);
  }

  const handleMigrate = () => {
    setProcessing(true)

    let vehicles = []
    let isValid = true;

    for (let index = 0; index < data.length; index++) {
        const singleVehicle = data[index];
        if(VehiclesController.isValidVehicle(singleVehicle)){
            vehicles.push(VehiclesController.transform(singleVehicle));
        }else{
            isValid = false;
            setProcessing(false)
            setError(`${index}th entry is invalid.`)
            break;
        }
    }
    
    if(isValid){
        let toastController = new ToastController();
        toastController.showProcessing(`Importing vehicles...`)
        VehiclesController.createVehicles(vehicles, (seek, peak) => setProgress({seek, peak}))
          .then(migrated => {
            setProcessing(false)
            clearData()
            setTimeout(() => {
                toastController.endProcessingWithSuccess(`${vehicles.length} vehicles imported successfully!`)
            }, 1000);
          }).catch(err => {
            setError(err)
            toastController.endProcessingWithError("Error in importing vehicles.")
            setProcessing(false)
          })
    }
  }

  const clearData = () => {
    setData([])
    setHeaders([])
    setError("")
    setProcessing(false);
    setProgress({seek: 0, peak: 0})
  }
  
  return (
    <div className="ImportVehicleScreen">
      <div style={{display:"flex", flex:1, height: 70,  alignItems:"center", justifyContent:"space-between" ,paddingLeft: 50, paddingRight: 50}}>
       <div style={{display:"flex", alignItems:"center"}}>
       {
        data.length? processing? false:
        <button onClick={clearData} style={{width: 100, height: 40, fontSize: 17, background: "#f0f0f0", color:"black", borderRadius: 10}} >CLEAR</button>:
        <ExcelToJson 
          onChangeState={(processing, err) => {
            setProcessing(processing);
            setError(err);
          }}
          validHeaders={validHeaders}
          onRead={(headers, data) => {
            setHeaders(headers)
            setData(data)
          }}/>
       }
      
       <a style={{marginLeft: 10}} href="importexample.csv" download target="_blank">Download Sample CSV</a>
       </div>
        <h4 style={{color:"red"}}>{error}</h4>

        {
          data.length > 0 ?
          <button style={{minWidth: 100, height: 40, fontSize: 17, background: "black", color:"white", borderRadius: 10}} onClick={handleMigrate}>{processing? `migrating progress...(${progress.seek}/${progress.peak})`: "migrate"}</button>: false
        }
      </div>
       

        <div style={{display:"flex", flex:1, border:"0px solid red" , overflow: "scroll"}}>
        <table align='center'  style={{
          // border:"1px solid black",
          borderCollapse: "collapse"
        }} >
          <tr>
            { headers.map(headerTitle => <th style={{padding: 15}} key={headerTitle}>{headerTitle}</th>)}
          </tr>
            {
              data.map((row, index) => (<TableTR onChange={handleOnChange} row={row} index={index} headers={headers}/>))
              // <td style={{padding: 10}} key={index}>{row[headers[index]]}</td>
            }
        </table>
        </div>
    </div>
  );
}

export default ImportVehicleScreen;
