import React, { useEffect } from "react";
import WalletIcon from "../../Assests/Icons/wallletView.png";
import { WalletTable } from "../../Components/CompositeComponents/Table/WalletTable/walletTable";
import { Modals } from "../../Components/AbstractsComponents/Modal/modal";
import {
  WalletModal,
  SetUpPaymentModal,
  SetUpPaymentAmountModal,
} from "../../Components/CompositeComponents/WalletModal/walletModal";
import VehicleSkeleton from "../../Components/ModuleComponents/Skeleton/vehiclesList";
//    importing css files
import "./walletScreen.css";
import InvoicesController, {
  useInvoices,
} from "../../Controllers/invoicesController";
export const WalletScreen = () => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  //destructuring pathname from location
  // const { pathname } = location.pathname;

  //Javascript split method to get the name of the path in array
  const splitLocation = window.location.href.split("/");
  useEffect(() => {
    // Update the document title using the browser API
    // console.log(splitLocation);
    InvoicesController.loadInvocies();
  }, []);

  const { loading, invoices, totalPayable } = useInvoices();

  return (
    <React.Fragment>
      <div>
        <div className="all-vehicle-header">
          <h1>Invoices</h1>
          <div style={{ margin: "auto 0px", display: "flex" }}>
            {/* <button
              className="wallet-view-button"
              onClick={(e) => openModal(e)}
            >
              <img src={WalletIcon} className="wallet-button-icon" alt="" />
              View
            </button> */}
            <Modals modalIsOpen={modalIsOpen} closeModal={closeModal}>
              {splitLocation[3] === "wallet#addPaymentMethod" ? (
                <SetUpPaymentModal closeModal={closeModal} />
              ) : splitLocation[3] === "wallet#AddPaymentAmount" ? (
                <SetUpPaymentAmountModal closeModal={closeModal} />
              ) : (
                <WalletModal />
              )}
            </Modals>
            <p className="wallet-payable-text">
              Total Receivable:<span>{`$${totalPayable}`}</span>
            </p>
          </div>
        </div>
        <div>
          {/* <WalletTable /> */}
          {loading ? (
            <>
              <VehicleSkeleton />
              <VehicleSkeleton />
              <VehicleSkeleton />
              <VehicleSkeleton />
              <VehicleSkeleton />
              <VehicleSkeleton />
              <VehicleSkeleton />
              <VehicleSkeleton />
            </>
          ) : (
            <WalletTable totalPayable={totalPayable} data={invoices} />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

{
  /* <WalletTable data={invoices}/> */
}
