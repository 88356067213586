import React, { useState } from "react";
//     importing components
import OtpInput from "react-otp-input";
import { Buttons } from "../../AbstractsComponents/Button/button";
import { useFormik } from "formik";
import { OTPValidation } from "../../../Validations/validations";
//     importing icons
//     importing css files
import "./forgetPasswordModal.css";

export const OTPModal = ({ OTP, setOTP, handleNext, setStep }) => {
  const [hrefs, setHrefs] = useState("#");
  const [isValid, setIsValid] = useState(true);
  const OtpValidationCheck = (values) => {
    console.log("Signin form submit values");
    setHrefs("#changePassword");
    console.log("Signin form submit values");
  };

  // const { handleSubmit, getFieldProps, touched, errors } = useFormik({
  useFormik({
    initialValues: {
      otp: OTP,
    },
    validationSchema: OTPValidation,
    onSubmit: OtpValidationCheck,
  });
  const handleChange = (otp) => {
    setOTP(otp);
    console.log(otp);
  };
  const CheckValidation = () => {
    if (OTP.length < 6) {
      setIsValid(false);
    } else {
      setIsValid(true);
      setHrefs("#changePassword");
      handleNext();
    }
  };
  return (
    <div className=" forget-password-modal-div">
      <div style={{ margin: "auto", textAlign: "center" }}>
        <div className="otp-header-div">
          <h5>Please enter the One-Time Password to verify your account</h5>
          <p>A One-Time Password has been sent to u******n@gmail.com</p>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <OtpInput
            value={OTP}
            inputStyle={
              OTP.length === 6 || isValid
                ? {
                    border: "none",
                    borderBottom: "4px solid #BCBCBCBF",
                    margin: "0px 10px",
                    width: "50px",
                  }
                : {
                    border: "none",
                    borderBottom: "4px solid red",
                    margin: "0px 10px",
                    width: "50px",
                  }
            }
            focusStyle={{
              borderBottom: "4px solid #626161BF",
              outline: "none",
            }}
            onChange={(e) => {
              handleChange(e);
            }}
            numInputs={6}
            hasErrored={true}
            shouldAutoFocus
            name="otp"
            id="otp"
            type="otp"
          />
          <br />

          <div>
            {isValid ? (
              ""
            ) : (
              <span
                className="errorMessage"
                style={{ height: "auto", position: "absolute" }}
              >
                {OTP.length < 6 ? "Required" : ""}
              </span>
            )}
          </div>
        </div>
        <div>
          <a
            href={OTP.length < 6 ? "#" : hrefs}
            style={{ textDecoration: "none" }}
          >
            <Buttons
              style={{
                width: "192px",
                height: "57px",
                margin: "20px auto 30px auto",
              }}
              onClick={() => CheckValidation()}
            >
              Next
            </Buttons>
          </a>
        </div>
        <div className="reseond-otp">
          <span>Resend OTP</span>
        </div>
      </div>
    </div>
  );
};
