import React from "react";
//    importing compoents
import { WelcomeToInfo } from "../../Components/ModuleComponents/AuthComponents/WelcomeToInfo/welcomeToInfo";
import { SignUp } from "../../Components/ModuleComponents/AuthComponents/SignUp/signUp";
//    importing css files
export const SignUpScreen = () => {
  return (
    <div className="sign-in-view-main-div">
      <div className="sign-in-image-div">
        <WelcomeToInfo />
      </div>
     
      <div style={{display:"flex", flex:1, overflowY: "scroll"}}>
        <div className="sign-in-info-div" >
          <SignUp />
        </div>
        </div>
    </div>
  );
};
