import React, { useEffect, useState } from "react";
//     importing components
import { BackButton } from "../../Components/AbstractsComponents/BackButton/backButton";
import { Buttons } from "../../Components/AbstractsComponents/Button/button";
import OfferTable from "../../Components/CompositeComponents/Table/OfferTable/offerTable";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
//     importing css files
import "./offerVehicleScreen.css";
import { useVehicleWithOffers } from "../../Controllers/vehiclesController";
import VehiclesListSkeleton from "../../Components/ModuleComponents/Skeleton/vehiclesList";
export const OfferVehicleScreen = (props) => {
  const params = useParams();
  const [loadedFirstTime, setLoadedFirstTime] = useState(false);
  const { loading, vehicle, offers } = useVehicleWithOffers(params._id);
  const title = vehicle?.title ? vehicle.title : "";

  console.log("Offers....", offers);

  // console.log("Loaded Offers", offers)
  useEffect(() => {
    setTimeout(() => {
      setLoadedFirstTime(true);
    }, 500);
  }, []);

  const navigate = useNavigate();

  return (
    <div>
      <div className="all-vehicle-header">
        <div
          // to="/allVehicle"
          onClick={() => navigate(-1)}
          className="react-router-link"
          style={{ margin: "auto 0px" }}
        >
          <BackButton>{title}</BackButton>
        </div>
        <div style={{ margin: "auto 0px" }}>
          <Link to="/allVehicle/AddVehicle" className="react-router-link">
            <Buttons style={{ width: "192px", height: "57px" }}>
              Add Vehicle
            </Buttons>
          </Link>
        </div>
      </div>
      <div>
        {loadedFirstTime ? (
          loading ? (
            <>
              <VehiclesListSkeleton />
              <VehiclesListSkeleton />
              <VehiclesListSkeleton />
              <VehiclesListSkeleton />
              <VehiclesListSkeleton />
              <VehiclesListSkeleton />
              <VehiclesListSkeleton />
            </>
          ) : (
            <OfferTable loading={loading} offers={offers} />
          )
        ) : (
          <>
            <VehiclesListSkeleton />
            <VehiclesListSkeleton />
            <VehiclesListSkeleton />
            <VehiclesListSkeleton />
            <VehiclesListSkeleton />
            <VehiclesListSkeleton />
            <VehiclesListSkeleton />
          </>
        )}
      </div>
    </div>
  );
};
