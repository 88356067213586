import React from "react";
//     importing components
import { BackButton } from "../../Components/AbstractsComponents/BackButton/backButton";
import { ChatPanelCard } from "../../Components/CompositeComponents/Chat/ChatPanelCard/chatPanelCard";
import { ChatProfileCard } from "../../Components/CompositeComponents/Chat/ChatProfileCard/chatProfileCard";
import { Link, useNavigate, useParams } from "react-router-dom";
//      importing css files
import "./allVehicleChatScreen.css";
import { useConversation } from "../../Controllers/vehiclesController";
import ChatSkeleton from "../../Components/ModuleComponents/Skeleton/chatSkeleton";
export const AllVehicleChatScreen = (props) => {
  const params = useParams();
  const offerId = params._id;
  const vehicleId = params.vehicleId;

  const { conversation, vehicle, offer, loading } = useConversation(
    vehicleId,
    offerId
  );

  // console.log(`Conversations`, conversation)
  // console.log(`Vehicle`, vehicle)
  const navigate = useNavigate();
  return (
    <div>
      <div className="all-vehicle-header">
        <div
          onClick={() => navigate(-1)}
          className="react-router-link"
          style={{ margin: "auto 0px" }}
        >
          <BackButton>{vehicle ? vehicle.title : ""}</BackButton>
        </div>
      </div>
      <div className="vehicle-chat-card-main-div">
        <div className="chat-user-profile-card">
          <ChatProfileCard
            conversation={conversation}
            loading={loading || !conversation}
          />
        </div>
        <div
          style={{ minWidth: "calc( 100% - 339px )" }}
          className="overflow-auto"
        >
          {loading || !conversation ? (
            <ChatSkeleton />
          ) : (
            <ChatPanelCard
              offerId={offerId}
              offer={offer}
              conversation={conversation}
            />
          )}
        </div>
      </div>
    </div>
  );
};
