import React, { useEffect, useState } from "react";
//      importing components
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Buttons } from "../../Components/AbstractsComponents/Button/button";
import VehicleSkeleton from "../../Components/ModuleComponents/Skeleton/vehiclesList";
import AllVehicleTable from "../../Components/CompositeComponents/Table/AllVehicle/allVehicleTable";
//      importing css files
import "./OfferedVehicleScreen.css";
import { useGetVehiclesByPage } from "../../Controllers/vehiclesController";
import TableSkeleton from "../../Components/ModuleComponents/Skeleton/vehicleTableSekelton";
export const OfferedVehicleScreen = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  let pageNoQuery = params.get("PageNo");
  const [pageNo, setPageNo] = useState(pageNoQuery === null ? 0 : pageNoQuery);

  const qryObj = {
    pageNumber: pageNoQuery || 0,
    pageSize: 20,
    isOfferedVehicles: true,
  };

  const { loading, vehicles, noOfPages } = useGetVehiclesByPage(qryObj);

  // console.log("Loading", loading)

  const [pageNoArray, setPageNoArray] = useState([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    navigate(`?PageNo=${parseInt(pageNo)}`);
    // console.log(pageNo,'page no .......................');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo]);

  return (
    <div>
      <div className="all-vehicle-header">
        <h1>Vehicles</h1>
        {/* <div style={{ margin: "auto 0px", display: "flex", gap: 16 }}>
          <Link to="/allVehicle/AddVehicle" className="react-router-link">
            <Buttons style={{ width: "192px", height: "57px" }}>
              Add Vehicle
            </Buttons>
          </Link>
          <Link to="/import" className="react-router-link">
            <Buttons style={{ width: "192px", height: "57px" }}>Import</Buttons>
          </Link>
        </div> */}
      </div>
      <div>
        {loading ? (
          <TableSkeleton
            pageNoArray={pageNoArray}
            setPageNoArray={setPageNoArray}
            activePageNo={parseInt(pageNo)}
            setPageNo={setPageNo}
            noOfPages={noOfPages}
          />
        ) : (
          <AllVehicleTable
            data={vehicles}
            noOfPages={noOfPages}
            pageNoArray={pageNoArray}
            setPageNoArray={setPageNoArray}
            activePageNo={parseInt(pageNo)}
            setPageNo={setPageNo}
            show={show}
            setShow={setShow}
          />
        )}
      </div>
    </div>
  );
};
