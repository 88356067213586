//    importing components
import { Routing } from "./Routing/routing";
//    importing css files
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import SplashScreen from "./Screens/SplashScreen";
import { useSelector } from "react-redux";

function App() {
  const splashVisible = useSelector((state) => state.AuthReducer.splashVisible);

  // console.log = () => {};

  if (splashVisible) {
    return <SplashScreen />;
  } else {
    return (
      <div className="App" style={{ height: "50vh" }}>
        <Routing />
      </div>
    );
  }
}

export default App;
