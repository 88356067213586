import React from "react";
//      importing components
import { Buttons } from "../../Components/AbstractsComponents/Button/button";
import { Link } from "react-router-dom";
import ReduxDispatchController from "../../Controllers/reduxDispatchController";
//      importing css files
import "./pendingUproval.css";
import AuthController from "../../Controllers/authController";
export const PendingUprovalScreen = () => {
  return (
    <div
      style={{
        backgroundColor: "#F9F9F9",
        height: "calc(100vh - 100px)",
        display: "flex",
        flexDirection:"column",
        justifyContent:"center"
        // verticalAlign: "middle",
      }}
    >
      <h1>Approval pending.</h1>
      <h3>You will get notified through email, once approved.</h3>
      {/* <Link to="/" className="react-router-link"> */}
         <h3 style={{cursor:"pointer", color:"#2674e6"}} onClick={() => AuthController.logout()}>Logout</h3>
      {/* </Link> */}

      {/* <Link to="/allVehicle" className="react-router-link">
         <h3 onClick={()=>ReduxDispatchController.AUTH.setAuthStatus(true)}>Home</h3>
      </Link> */}

      {/* <div
        style={{
          maxWidth: "600px",
          height: "200px",
          color: "white",
          margin: "auto",
          borderRadius: "20px",
          boxShadow: "4px 8px 20px 4px #B3CDF3",
        }}
      >
        <div
          style={{
            backgroundColor: "#2674E6",
            padding: "15px 15px",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
          }}
        >
          <h2>Not yet activated from the system</h2>
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "30px",
            justifyContent: "end",
            padding: " 0px 15px",
          }}
        >
          <Link to="/" className="react-router-link">
            <Buttons
              style={{
                width: "150px",
                height: "50px",
                lineHeight: "45px",
                marginRight: "20px",
                boxShadow: "none",
              }}
            >
              Log out
            </Buttons>
          </Link>
          <Link to="/allVehicle" className="react-router-link">
            <Buttons
              style={{
                width: "150px",
                height: "50px",
                lineHeight: "45px",
                boxShadow: "none",
              }}
              onClick={()=>ReduxDispatchController.AUTH.setAuthStatus(true)}
            >
              Next
            </Buttons>
          </Link>
        </div>
      </div> */}
    </div>
  );
};
