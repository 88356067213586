import React, { useState } from "react";
//    importing components
import { TextFields } from "../../../AbstractsComponents/TextFields/textFields";
import { Buttons } from "../../../AbstractsComponents/Button/button";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { SignUpValidation } from "../../../../Validations/validations";
import { useNavigate } from "react-router-dom";
//    importing images or icons
import EyeIcon from "../../../../Assests/Icons/eye-outline.png";
import { ImageUpload } from "../../../AbstractsComponents/ImageUpload/imageUpload";
//    importing css files
import "./signUp.css";
import AuthController from "../../../../Controllers/authController";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { LocationTextField } from "../../../AbstractsComponents/LocationInputField";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export const SignUp = () => {
  const [LoaderButton] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [imageError, setImageError] = useState("");
  const [imageSource, setImageSource] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [show, setShow] = useState(false);

  const inputArr = [EyeIcon];
  let navigate = useNavigate();

  const SignUpFormSubmit = ({
    fullName,
    email,
    password,
    location,
    phoneNumber,
  }) => {
    console.log("Location .....", location);
    if (imageSource) {
      setProcessing(true);
      setErrorMessage("");
      AuthController.signup(
        email,
        fullName,
        password,
        imageSource,
        location,
        phoneNumber
      )
        .then((created) => {
          setProcessing(false);
          navigate("/");
        })
        .catch((err) => {
          setErrorMessage(err);
          setProcessing(false);
        });
    } else {
      setImageError(`*Please select profile image`);
    }
  };

  const handleLocationField = (address) => {
    console.log(address, "updated address");
    setValues({
      ...values,
      location: {
        description: address.address,
        coords: {
          longitude: address.location.lng,
          latitude: address.location.lat,
        },
      },
    });
  };

  const { handleSubmit, getFieldProps, touched, errors, values, setValues } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
        fullName: "",
        confirmPassword: "",
        phoneNumber: "",
        location: {
          description: "",
          coords: {
            longitude: null,
            latitude: null,
          },
        },
      },
      validationSchema: SignUpValidation,
      onSubmit: (data) => {
        console.log("@dealer signup data.....", data);
        if (!data.location.description) {
          setErrorMessage("Location required!");
          return;
        }
        let newData = {
          ...data,
          location: {
            description: data.location.description,
            longitude: data.location.coords.longitude,
            latitude: data.location.coords.latitude,
          },
        };
        console.log("@data...", newData);
        SignUpFormSubmit(newData);
      },
    });

  // const CallApi = () => {
  //   setLoaderButton(true, () => {
  //     axios.get("localhost:300").then((result) => setLoaderButton(false));
  //   });
  //   console.log(isValid);
  // };
  return (
    <React.Fragment>
      <form>
        <div className="sign-up-info-div">
          <h2>Create your account</h2>
          <p className="signup-login-text">
            Already have an account?
            <Link to="/auth/logIn" className="react-router-link">
              <span> Log in </span>
            </Link>
          </p>
          <div style={{ height: 50 }} />
          <div
            style={{
              width: 135,
              height: 135,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
            }}
          >
            <ImageUpload onChange={setImageSource} id="sign-up-image-picker" />
          </div>
          {/* <div style={{height: 30, display:"flex", justifyContent:"center", alignItems:"center"}}>
              <span style={{color:"red"}}>Error message</span>
            </div> */}
          <div
            style={{
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              height: 25,
            }}
          >
            <span style={{ color: "red", fontSize: 12 }}>{imageError}</span>
          </div>
          <TextFields
            label={"Full Name"}
            placeholder={"Enter your Full Name"}
            name="fullName"
            id="fullName"
            type="text"
            {...getFieldProps("fullName")}
          />
          <span className="errorMessage" style={{ marginTop: "-5px" }}>
            {touched["fullName"] && errors["fullName"]}
          </span>
          <TextFields
            label={"Email"}
            placeholder={"Enter your email"}
            name="email"
            id="email"
            type="email"
            {...getFieldProps("email")}
          />
          <span className="errorMessage" style={{}}>
            {touched["email"] && errors["email"]}
          </span>
          <div className="my-2 w-full flex flex-col ">
            <p className="text-sm">Phone Number</p>
            <PhoneInput
              containerClass="w-full rounded-[8px] h-[48px]  flex !outline-none !border-none"
              inputClass="w-full h-[48px] !outline-none !border-none  flex-1"
              containerStyle={{
                outline: "!none",
                border: "!none",
              }}
              country="us"
              value={values.phoneNumber}
              placeholder="Enter phone number"
              type="text"
              onChange={(e) => {
                const formattedPhoneNumber = "+" + e;
                console.log(e, "@on phone change e");
                setValues({
                  ...values,
                  phoneNumber: formattedPhoneNumber,
                });
              }}
            />
            <span className="errorMessage my-2" style={{}}>
              {touched["phoneNumber"] && errors["phoneNumber"]}
            </span>
          </div>
          <div className="relative flex flex-col w-full h-auto">
            <h5 className="text-[12px] font-[600] mt-1 text-[#5a616c]">
              Password
            </h5>
            <input
              type={show ? "text" : "password"}
              className="text-fileds-input"
              label={"Password"}
              placeholder={"Enter your password"}
              name="password"
              id="password"
              {...getFieldProps("password")}
            />
            <span className="mt-1 errorMessage" style={{ marginTop: "-10px" }}>
              {touched["password"] && errors["password"]}
            </span>

            <button
              type="button"
              onClick={() => setShow(!show)}
              className="absolute right-6  top-[48%] text-xl text-[#5a616c]"
            >
              {show ? <AiFillEyeInvisible /> : <AiFillEye />}
            </button>
          </div>
          {/* <TextFields
          //   textIconArr={inputArr}
          //   label={"Confirm password"}
          //   placeholder={"Confirm Password"}
          //   name="confirmPassword"
          //   id="confirmPassword"
          //   type="password"
          //   {...getFieldProps("confirmPassword")}
          // />
          // <span className="errorMessage" style={{ marginTop: "-10px" }}>
          //   {touched["confirmPassword"] && errors["confirmPassword"]}
          // </span> */}
          <div className="relative flex flex-col w-full h-auto">
            <h5 className="text-[12px] font-[600] mt-1 text-[#5a616c]">
              Confirm Password
            </h5>
            <input
              type={show ? "text" : "password"}
              className="text-fileds-input"
              label={"Confirm password"}
              placeholder={"Confirm Password"}
              name="confirmPassword"
              id="confirmPassword"
              {...getFieldProps("confirmPassword")}
            />
            <span className="mt-1 errorMessage" style={{ marginTop: "-10px" }}>
              {touched["confirmPassword"] && errors["confirmPassword"]}
            </span>
            <button
              type="button"
              onClick={() => setShow(!show)}
              className="absolute right-6 top-[48%] text-xl text-[#5a616c]"
            >
              {show ? <AiFillEyeInvisible /> : <AiFillEye />}
            </button>
          </div>
          <LocationTextField
            type="text"
            name="location"
            id="location"
            label="Location / Address"
            placeholder="2564 Joes Road, New York"
            value={values?.location?.description}
            onChange={(e) =>
              setValues({
                ...values,
                location: {
                  ...values?.location,
                  description: e.target.value,
                },
              })
            }
            // {...getFieldProps("location")}
            // icon={LocationIcon}
            onLoactionChange={handleLocationField}
          />

          <Buttons
            onClick={handleSubmit}
            type="button"
            processing={processing}
            style={{ height: "60px", marginTop: "20px" }}
          >
            Sign Up
          </Buttons>
          <br />
          <div
            style={{
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              height: 25,
            }}
          >
            <span style={{ color: "red", fontSize: 12 }}>{errorMessage}</span>
          </div>
          <div
            className="sign-up-already-have-account-div"
            style={{ fontSize: "12px", width: "340px", margin: "10px auto" }}
          >
            <p>
              By clicking "Sign Up" you agree to our{" "}
              <a href="https://www.inforideauctions.com/terms-of-use">
                <span style={{ textDecoration: "underline" }}>
                  {" "}
                  terms and conditions{" "}
                </span>{" "}
              </a>
              as well as our{" "}
              <a href="https://www.inforideauctions.com/privacy-policy">
                <span style={{ textDecoration: "underline" }}>
                  {" "}
                  privacy policy
                </span>
              </a>
            </p>
          </div>
          <br />
          <br />
          <br />
        </div>
      </form>
    </React.Fragment>
  );
};

// setAuction({
//   ...auction,
//   location: {
//     ...auction?.location,
//     description: e.target.value,
//   },
// })
