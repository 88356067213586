import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const BusinessAvatar = props => {
    
    return (
        <SkeletonTheme   baseColor="#f3f3f3" highlightColor="#ecebeb">
            <Skeleton style={{borderRadius: "50%"}} width={118} height={118} count={1} />
            <div style={{height: 10}}/>
            <Skeleton style={{borderRadius: 5}} width={100} height={30} count={1} />
        </SkeletonTheme>
    )
}

export default BusinessAvatar;