import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const VehiclesListSkeleton = props => {
    
    return (
        <SkeletonTheme   baseColor="#f3f3f3" highlightColor="#ecebeb">
            <p style={{display: "flex", flexDiraction:"row", alignItems:"center"}}>

            <Skeleton style={{borderRadius: 10}} width={100} height={50} count={1} />
            <div style={{width: 10}}/>
            <Skeleton style={{borderRadius: 20}} width={100} height={30} count={1} />
            <div style={{width: 10}}/>
            <Skeleton style={{borderRadius: 20}} width={100} height={30} count={1} />
            <div style={{width: 10}}/>
            <Skeleton style={{borderRadius: 20}} width={100} height={30} count={1} />
            <div style={{width: 10}}/>
            <Skeleton style={{borderRadius: 20}} width={100} height={30} count={1} />
            <div style={{width: 10}}/>
            <Skeleton style={{borderRadius: 20}} width={100} height={30} count={1} />
            <div style={{width: 10}}/>
            <Skeleton style={{borderRadius: 20}} width={100} height={30} count={1} />
            <div style={{width: 10}}/>
            <Skeleton style={{borderRadius: 20}} width={100} height={30} count={1} />
            <div style={{width: 10}}/>
            <Skeleton style={{borderRadius: 20}} width={100} height={30} count={1} />
            </p>
        </SkeletonTheme>
    )
}

export default VehiclesListSkeleton;