import { TailSpin } from "react-loader-spinner";

const MySpinner = ({color, size, style}) => {
    const color_ = color? color: "white";
    const size_ = size? size: "30px"
    return (
        <TailSpin
          height={size_}
          width={size_}
          color={color_}
          wrapperStyle={style}
          ariaLabel="loading"
        />
    )
}
export default MySpinner;