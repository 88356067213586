import React, { useState } from "react";
//     importing components
import { Buttons } from "../../AbstractsComponents/Button/button";
import { TextFields } from "../../AbstractsComponents/TextFields/textFields";
import { useFormik } from "formik";
import { ForgetCreateNewPassword } from "../../../Validations/validations";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

//     importing icons
import EyeIcon from "../../../Assests/Icons/eye-outline.png";
//     importing css files
import "./forgetPasswordModal.css";

export const CreateNewPasswordModal = ({ setNewPass, handleNext }) => {
  const inputArr = [EyeIcon];
  const [hrefs, setHref] = useState("#changePassword");
  const [show, setShow] = useState(false);

  const createNewPasswordCheck = () => {
    console.log("Signin form submit values");
    handleNext(values.password);
    // setHref("#successChange");
    // console.log(hrefs);
    // console.log("Signin form submit values");
  };

  const { handleSubmit, getFieldProps, touched, errors, values } = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: ForgetCreateNewPassword,
    onSubmit: createNewPasswordCheck,
  });

  return (
    <div className="forget-password-modal-div">
      <div style={{ margin: "auto", textAlign: "center" }}>
        <div className="otp-header-div">
          <h5>Create new password</h5>
          <p>Choose you new password!</p>
        </div>

        <div style={{ width: "458px" }}>
          <div className="relative flex flex-col w-full h-auto">
            <h5 className="text-[15px] text-left font-[600] mt-1 text-[#5a616c]">
              Password
            </h5>
            <input
              type={show ? "text" : "password"}
              className="text-fileds-input"
              label={"Password"}
              placeholder={"Enter your password"}
              name="password"
              id="password"
              {...getFieldProps("password")}
            />
            <span className="mt-1 errorMessage" style={{ marginTop: "-10px" }}>
              {touched["password"] && errors["password"]}
            </span>

            <button
              type="button"
              onClick={() => setShow(!show)}
              className="absolute right-6  top-[48%] text-xl text-[#5a616c]"
            >
              {show ? <AiFillEyeInvisible /> : <AiFillEye />}
            </button>
          </div>
          <div className="relative flex flex-col w-full h-auto">
            <h5 className="text-[15px] text-left font-[600] mt-1 text-[#5a616c]">
              Confirm Password
            </h5>
            <input
              type={show ? "text" : "password"}
              className="text-fileds-input"
              label={"Confirm password"}
              placeholder={"Confirm Password"}
              name="confirmPassword"
              id="confirmPassword"
              {...getFieldProps("confirmPassword")}
            />
            <span className="mt-1 errorMessage" style={{ marginTop: "-10px" }}>
              {touched["confirmPassword"] && errors["confirmPassword"]}
            </span>
            <button
              type="button"
              onClick={() => setShow(!show)}
              className="absolute right-6 top-[48%] text-xl text-[#5a616c]"
            >
              {show ? <AiFillEyeInvisible /> : <AiFillEye />}
            </button>
          </div>
          <a href={hrefs} style={{ textDecoration: "none" }}>
            <Buttons
              style={{
                width: "100%",
                height: "57px",
                margin: "20px auto 30px auto",
              }}
              onClick={() => handleSubmit()}
            >
              Done
            </Buttons>
          </a>
        </div>
      </div>
    </div>
  );
};
