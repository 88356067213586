import React, { useState } from "react";
//    importring icons
import PlusIcon from "../../../Assests/Icons/imagePlusIcon.svg";
//    importing css files
import "./imageUpload.css";

export const ImageUpload = (props) => {
  const [imageSource, setImageSource] = useState("");

  const handleChangeImageSource = (event) => {
      console.log(event.target.files[0])
      setImageSource(URL.createObjectURL(event.target.files[0]))
      if(props.onChange){
         props.onChange(event.target.files[0]);
      }
  }

  

  if(imageSource){
    return (
      <div className="offer-detail-inputs-div" style={{ display: "flex"}}>
        <p onClick={() => setImageSource("")} className="image-time-icon">
          &times;
        </p>
        <div className="offer-detail-label" style={{backgroundColor:"transparent", borderWidth: 0}} >
          <div>
              <img src={imageSource} className="input-select-image" style={{objectFit:"cover"}} alt="" />
          </div>
        </div>
      </div>
    );
  }else{
    return (
      <div className="offer-detail-inputs-div" style={{ display: "flex"}}>
       
        <input
          className="offer-detail-inputs"
          onChange={handleChangeImageSource}
          type="file"
          id={props.id ? props.id : "actual-btn"}
          hidden
        />
        <label
          className="offer-detail-label"
          // style={{backgroundColor: "transparent", borderWidth: 0}}
          htmlFor={props.id ? props.id : "actual-btn"}
        >
          <div>
              <span className="image-upload-plus-icon">
                <img src={PlusIcon}  alt="" />
              </span>
          </div>
        </label>
      </div>
    );
  }


  // return (
  //   <div className="offer-detail-inputs-div" style={{ display: "flex"}}>
  //     {imageSource === "" ? (
  //       ""
  //     ) : (
  //       <p onClick={() => setImageSource("")} className="image-time-icon">
  //         &times;
  //       </p>
  //     )}
  //     <input
  //       className="offer-detail-inputs"
  //       onChange={(event) =>{
  //         console.log(event.target.files[0])
  //         setImageSource(URL.createObjectURL(event.target.files[0]))
  //       }}
  //       type="file"
  //       id={props.id ? props.id : "actual-btn"}
  //       hidden
  //     />
  //     {/* <label className="offer-detail-label" for="actual-btn"><div style={{marginTop:"37px",textAlign:"center"}}>{this.state.file===null?"icon":<img src={this.state.file} className="input-select-image"/>}</div></label> */}
  //     <label
  //       className="offer-detail-label"
  //       // style={{backgroundColor: "transparent", borderWidth: 0}}
  //       htmlFor={props.id ? props.id : "actual-btn"}
  //     >
  //       <div>
  //         {imageSource === "" ? (
  //           <span className="image-upload-plus-icon">
  //             <img src={PlusIcon}  alt="" />
  //           </span>
  //         ) : (
  //           <img src={imageSource} className="input-select-image" alt="" />
  //         )}
  //       </div>
  //     </label>
  //   </div>
  // );
};
