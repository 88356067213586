import React from "react";
import Modal from "react-modal";
import "./modal.css";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "770px",
    //   height:"676px",
    borderRadius: "31px",
    backgroundColor: "transparent",
    border: "none",
    overFlow: "hidden",
    opacity: "1",
    zIndex: "99",
    position: "absolute",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(77, 77, 77, 0.75)",
  },
};
export const Modals = (props) => {
  return (
    <div>
      <Modal
        isOpen={props.modalIsOpen}
        onRequestClose={props.closeModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Example Modal"
        shouldCloseOnOverlayClick={false}
        // overlayClassName="modal-overlay"
      >
        <div style={{ borderRadius: "31px" }}>
          {props.children}
          <a href="#">
            <button className="modal-close-button" onClick={props.closeModal}>
              &times;
            </button>
          </a>
        </div>
      </Modal>
    </div>
  );
};
