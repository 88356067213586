import produce from 'immer';
import { AUTH, INVOICES } from '../types';
const state = {
    invoices: [], 
    totalPayable: 0, 
    loadingInvoices: false
}

const InvoicesReducer = (mState = {...state}, action) => {
    switch(action.type){
        case INVOICES.SET_ALL:
            return produce(mState, draftState => {
                console.log(`Setting invoices`, action.payload)
                draftState.invoices = action.payload.invoices;
                draftState.totalPayable = action.payload.payable;
            })
        case INVOICES.SET_KEY_VALUE:
            return produce(mState, draftState => {
               draftState[action.payload.key] = action.payload.value;
            })
        case AUTH.LOGOUT:
            return {...state}
        default:
            return {...mState};
    }
}
export default InvoicesReducer;