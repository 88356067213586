import React from "react";
//    importing components
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
//    importing images
//    importing icons
//    imoporting css files
import "./walletTable.css";
var WalletProducts = [
  {
    vehicleTitle: "Vehicle title",
    buyerName: "Buyer name",
    time: " time : 12:00 pm",
    total: "$10,000",
  },
  {
    vehicleTitle: "Vehicle title",
    buyerName: "Buyer name",
    time: " time : 12:00 pm",
    total: "$10,000",
  },
  {
    vehicleTitle: "Vehicle title",
    buyerName: "Buyer name",
    time: " time : 12:00 pm",
    total: "$10,000",
  },
  {
    vehicleTitle: "Vehicle title",
    buyerName: "Buyer name",
    time: " time : 12:00 pm",
    total: "$10,000",
  },
  {
    vehicleTitle: "Vehicle title",
    buyerName: "Buyer name",
    time: " time : 12:00 pm",
    total: "$10,000",
  },
  {
    vehicleTitle: "Vehicle title",
    buyerName: "Buyer name",
    time: " time : 12:00 pm",
    total: "$10,000",
  },
  {
    vehicleTitle: "Vehicle title",
    buyerName: "Buyer name",
    time: " time : 12:00 pm",
    total: "$10,000",
  },
  {
    vehicleTitle: "Vehicle title",
    buyerName: "Buyer name",
    time: " time : 12:00 pm",
    total: "$10,000",
  },
];

function imageFormatter(cell, row) {
  return (
    <img
      style={{ width: 70, height: 50, borderRadius: 10 }}
      src={cell}
      alt=""
    />
  );
}
export const WalletTable = ({ data, totalPayable }) => {
  return (
    <div>
      <BootstrapTable
        bodyContainerClass="my-custom-body"
        headerStyle={{ padding: "0px 0px" }}
        tableStyle={{
          borderRadius: "30px",
          minHeight: "calc(100vh - 280px)",
          position: "relative",
          background: "#ffffff",
          border: "none",
          width: "100%",
          marginBottom: "20px",
        }}
        bodyStyle={{
          overflow: "overlay",
          // height: "calc(100vh - 280px)",
          background: "#ffffff",
          borderRadius: "30px",
        }}
        data={data ? data : []}
      >
        <TableHeaderColumn
          thStyle={{ padding: "20px 40px" }}
          tdStyle={{ padding: "10px 0px 10px 40px", verticalAlign: "middle" }}
          width="100px"
          className="td-header-string-example"
          dataField="picture"
          dataFormat={imageFormatter}
        >
          <div style={{ display: "flex" }}>Picture</div>
        </TableHeaderColumn>

        <TableHeaderColumn
          isKey={true}
          dataField="vehicleTitle"
          tdStyle={{
            height: "80px",
            padding: "0px 0px 0px 10px",
            verticalAlign: "middle",
            color: "#16478E",
            fontWeight: "700",
          }}
          width="180px"
          className="td-header-string-example"
        >
          <div style={{ display: "flex" }}>Vehicle</div>
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField="time"
          tdStyle={{
            height: "20px",
            padding: "0px 0px 0px 10px",
            verticalAlign: "middle",
            fontWeight: "700",
          }}
          className="td-header-string-example"
          width="190px"
        >
          <div style={{ display: "flex" }}>Time</div>
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField="total"
          width="130px"
          thStyle={{
            position: "sticky",
            right: "0px",
            backgroundColor: "white",
            borderRadius: "30px",
          }}
          tdStyle={{
            height: "70px",
            padding: "0px 0px 0px 10px",
            fontWeight: "700",
            verticalAlign: "middle",
            position: "sticky",
            right: "0px",
            backgroundColor: "white",
          }}
          className="td-header-string-example"
        >
          <div style={{ display: "flex" }}>Receivable</div>
        </TableHeaderColumn>
      </BootstrapTable>
      {/* <div className="wallet-total-divs">
        <div className="wallet-total-main-div">
          <div>
            <p className="sub-total-text">Sub total:</p>
            <p className="total-payment-value">$ 1800</p>
          </div>
          <div>
            <p className="payable-text">Total Payable:</p>
            <p className="payable-amount">{`-$${totalPayable}`}</p>
          </div>
        </div>
      </div> */}
    </div>
  );
};
