import { combineReducers } from "redux";
import InfoRiderReducrs from "./infoRiderReducer";
import AuthReducer from "./authReducer";
import VehiclesReducer from "./vehiclesReducer";
import ActionsReducer from "./actionsReducer";
import InvoicesReducer from "./invoicesReducer";
const rootReducer = combineReducers({ 
    InfoRiderReducrs,  
    AuthReducer,
    VehiclesReducer,
    ActionsReducer,
    InvoicesReducer, 
});

export default rootReducer;
