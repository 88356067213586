import React from "react";
//     importing components
import { ForgetPassword } from "../../Components/ModuleComponents/AuthComponents/ForgetPassword/forgetPassword";
import { WelcomeToInfo } from "../../Components/ModuleComponents/AuthComponents/WelcomeToInfo/welcomeToInfo";

export const PrivacyPolicyScreen = () => {
  return (
    <div className="sign-in-view-main-div">
    <div style={{display:"flex", flexDirection: "column", width: "70%", alignSelf:"center", margin:"auto"}}>
  <p style={{ marginTop: "0.0000pt", marginBottom: "0.0000pt" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        INFORIDE® PRIVACY POLICY
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", marginBottom: "0.0000pt" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        &nbsp;
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        LAST UPDATED: March 23, 2022
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        &nbsp;
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      Worth Advertising Group, Inc. ("
    </span>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        Worth Advertising Group
      </span>
    </strong>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      " "
    </span>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        we
      </span>
    </strong>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      " “
    </span>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        our
      </span>
    </strong>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      ” or “
    </span>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        us
      </span>
    </strong>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      ”) is committed to protecting your privacy, and as such has adopted
      privacy practices to protect the information we collect from you. The
      practices are described in this privacy policy (the “
    </span>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        Privacy
      </span>
    </strong>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      &nbsp;
    </span>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        Policy
      </span>
    </strong>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      ”). Our Privacy Policy is designed to provide transparency into our
      privacy practices and principles, in a format that our users can navigate,
      read and understand. We are dedicated to treating your personal
      information with care and respect.
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      &nbsp;
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      This Privacy Policy covers our online privacy practices with respect to
      use and disclosure of Information that you may provide when you access,
      download or use the InfoRide Mobile Application (the "
    </span>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        InfoRide App
      </span>
    </strong>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      "), visit the InfoRide website located at https://www.inforide.app/ (the “
    </span>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        Website
      </span>
    </strong>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      ”) or access the InfoRide SaaS Platform available to Dealerships and their
      authorized End Users (the “
    </span>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        Platform
      </span>
    </strong>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      ”) (collectively referred to herein as the “
    </span>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        Services
      </span>
    </strong>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      ”). Please review the following Privacy Policy and contact us at&nbsp;
    </span>
    <a href="mailto:Help@InfoRide.com">
      <span style={{ fontFamily: '"Times New Roman"', fontSize: 15 }}>
        Worth@worthadv.com
      </span>
    </a>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      &nbsp;if you have any questions or concerns.
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      &nbsp;
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        Acceptance of Terms:
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        &nbsp;
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      By accessing, downloading or using our Services you agree to be bound by
      the terms and conditions of this Privacy Policy, our Terms of Use
      available at [
    </span>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15,
        background: "rgb(255,255,0)"
      }}
    >
      INSERT URL TO TERMS OF USE
    </span>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      ] and by the terms of our End User License Agreement (“
    </span>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        EULA
      </span>
    </strong>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      ”) or Dealership SaaS Agreement (
    </span>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        “SaaS Agreement
      </span>
    </strong>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      ”) as may be applicable. By accessing our Services you acknowledge that
      you fully understand our Privacy Policy and freely consent to the
      collection, use and disclosure of your Information, as described in this
      Privacy Policy. If you do not consent to the collection, use and
      disclosure practices described below, you must immediately cease any
      access or use of our Services. &nbsp;
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      &nbsp;
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        PRIVACY POLICY SCOPE:
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        &nbsp;
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      This Privacy Policy describes:&nbsp;
    </span>
  </p>
  <ul style={{ listStyleType: "undefined", marginLeft: 26 }}>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        Information We Collect From You and How We Collect It
      </span>
    </li>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        Parents and Children
      </span>
    </li>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        Third Party Information Collection
      </span>
    </li>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        How We Process Your Information and Our Lawful Basis for Doing So
      </span>
    </li>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        How We Share Your Information
      </span>
    </li>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontWeight: "normal",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "normal",
          fontSize: "11.0000pt"
        }}
      >
        Your Choices About Our Collection, Use and Disclosure of Your
        Information
      </span>
    </li>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        Opt-In/Opt-Out Communications
      </span>
    </li>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontWeight: "normal",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "normal",
          fontSize: "11.0000pt"
        }}
      >
        Information Retention and Accessing and Changing Your Personal
        Information
      </span>
    </li>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        California Privacy Rights – Notice to California Residents
      </span>
    </li>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        Data Protection and Security
      </span>
    </li>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        Consent
      </span>
    </li>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        Sensitive Data
      </span>
    </li>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        Third Party Sites and Links
      </span>
    </li>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        Analytics/Performance Cookies
      </span>
    </li>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        Email/Marketing Communications
      </span>
    </li>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        Changes to Our Privacy Policy
      </span>
    </li>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        Contact Information
      </span>
    </li>
  </ul>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      &nbsp;
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      This Privacy Policy applies only to Information we collect in the InfoRide
      App, Website and Platform and other electronic communications sent through
      or in connection with the Services. This Privacy Policy DOES NOT apply to
      information that we collect offline or on any other Worth Advertising
      Group owned apps or websites, including third party websites you may
      access through the Services; or to information that you provide to or is
      collected by any third party. This Privacy Policy may change from time to
      time (see&nbsp;
    </span>
    <u>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          textDecoration: "underline",
          fontSize: 15
        }}
      >
        Changes to Our Privacy Policy
      </span>
    </u>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      &nbsp;section below). Your continued use of the Services after we revise
      our Privacy Policy means you accept and consent to those changes, so
      please check the Privacy Policy periodically for updates.&nbsp;
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      &nbsp;
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        Information We Collect FROM YOU and how we collect it:
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        &nbsp;
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0.0000pt",
      textAlign: "justify",
      background: "rgb(255,255,255)"
    }}
  >
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      When you use the Services, certain types of data may be collected from
      you, including: (1) Personally Identifiable Information; and (2)
      Non-Personal Information (collectively referred to as “
    </span>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        Information
      </span>
    </strong>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      ”). For more detailed &nbsp;information on the collection of personal
      information from children, please refer to the “
    </span>
    <u>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          textDecoration: "underline",
          fontSize: 15
        }}
      >
        Parents and Children
      </span>
    </u>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      ” section below.
    </span>
  </p>
  <p
    style={{
      marginTop: "0.0000pt",
      textAlign: "justify",
      background: "rgb(255,255,255)"
    }}
  >
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0.0000pt",
      textAlign: "justify",
      background: "rgb(255,255,255)"
    }}
  >
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        Personally Identifiable Information
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      You may be able to view, access, use or download the Services (or portions
      thereof) without disclosing any information that, either alone or in
      combination with other information, can be used to uniquely identify,
      contact or locate you (collectively, “
    </span>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        Personally Identifiable Information”
      </span>
    </strong>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      &nbsp;or “
    </span>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        PII
      </span>
    </strong>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      ”). However, certain portions of the Services may only be accessible by
      disclosing certain PII, including your name, email address and phone
      number. We collect information in different ways, depending on your use of
      the&nbsp;
    </span>
    <span style={{ fontFamily: '"Times New Roman"', fontSize: 15 }}>
      Services and whether you are a customer shopping on the InfoRide App (“
    </span>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        Buyer
      </span>
    </strong>
    <span style={{ fontFamily: '"Times New Roman"', fontSize: 15 }}>
      ”), or a an authorized end user (“
    </span>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        End User
      </span>
    </strong>
    <span style={{ fontFamily: '"Times New Roman"', fontSize: 15 }}>
      ”) of an authorized dealership (“
    </span>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        Dealership
      </span>
    </strong>
    <span style={{ fontFamily: '"Times New Roman"', fontSize: 15 }}>
      ”) using the Platform pursuant to a separate SaaS Agreement entered into
      by and between Worth Advertising Group and the Dealership. Buyer, End
      User, and Dealership, are all collectively referred to herein as “
    </span>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        users
      </span>
    </strong>
    <span style={{ fontFamily: '"Times New Roman"', fontSize: 15 }}>
      ”.&nbsp;
    </span>
    <span style={{ fontFamily: '"Times New Roman"', fontSize: 15 }}>
      Users actively provide information to Worth Advertising Group in various
      ways while using our online Services. For example, you share information
      when you:
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span style={{ fontFamily: '"Times New Roman"', fontSize: 15 }}>
      &nbsp;
    </span>
  </p>
  <ul style={{ listStyleType: "undefined", marginLeft: 26 }}>
    <li>
      <span style={{ fontFamily: "Symbol", fontSize: "11.0000pt" }}>
        ·&nbsp;
      </span>
      <span style={{ fontFamily: '"Times New Roman"', fontSize: "11.0000pt" }}>
        Create a Buyer account on the InfoRide App;
      </span>
    </li>
    <li>
      <span style={{ fontFamily: "Symbol", fontSize: "11.0000pt" }}>
        ·&nbsp;
      </span>
      <span style={{ fontFamily: '"Times New Roman"', fontSize: "11.0000pt" }}>
        Create a Dealership or End User account on the Platform;
      </span>
    </li>
    <li>
      <span style={{ fontFamily: "Symbol", fontSize: "11.0000pt" }}>
        ·&nbsp;
      </span>
      <span style={{ fontFamily: '"Times New Roman"', fontSize: "11.0000pt" }}>
        Request information about our Services where we collect
        information;&nbsp;
      </span>
      <span style={{ fontFamily: '"Times New Roman"', fontSize: "11.0000pt" }}>
        &nbsp;
      </span>
    </li>
    <li>
      <span style={{ fontFamily: "Symbol", fontSize: "11.0000pt" }}>
        ·&nbsp;
      </span>
      <span style={{ fontFamily: '"Times New Roman"', fontSize: "11.0000pt" }}>
        Use our chat feature in the InfoRide App or Platform;
      </span>
    </li>
    <li>
      <span style={{ fontFamily: "Symbol", fontSize: "11.0000pt" }}>
        ·&nbsp;
      </span>
      <span style={{ fontFamily: '"Times New Roman"', fontSize: "11.0000pt" }}>
        Upload Vehicle Data to the Platform as an End User or Dealership;&nbsp;
      </span>
    </li>
    <li>
      <span style={{ fontFamily: "Symbol", fontSize: "11.0000pt" }}>
        ·&nbsp;
      </span>
      <span style={{ fontFamily: '"Times New Roman"', fontSize: "11.0000pt" }}>
        Request customer service;&nbsp;
      </span>
      <span style={{ fontFamily: '"Times New Roman"', fontSize: "11.0000pt" }}>
        or
      </span>
    </li>
    <li>
      <span style={{ fontFamily: "Symbol", fontSize: "11.0000pt" }}>
        ·&nbsp;
      </span>
      <span style={{ fontFamily: '"Times New Roman"', fontSize: "11.0000pt" }}>
        Contact us through any of our Services
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        .
      </span>
    </li>
  </ul>
  <p
    style={{ marginTop: "0.0000pt", textAlign: "justify", lineHeight: "107%" }}
  >
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0.0000pt",
      textAlign: "justify",
      background: "rgb(255,255,255)"
    }}
  >
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      By providing PII, you acknowledge that you understand our collection, use
      and disclosure of it, as described in this Privacy Policy.&nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0.0000pt",
      textAlign: "justify",
      background: "rgb(255,255,255)"
    }}
  >
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0.0000pt",
      textAlign: "justify",
      background: "rgb(255,255,255)"
    }}
  >
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        Automatically Gathered Information&nbsp;
      </span>
    </strong>
  </p>
  <p
    style={{
      marginRight: "5.6000pt",
      marginBottom: "0.0000pt",
      textIndent: "0.0000pt",
      textAlign: "justify"
    }}
  >
    <span style={{ fontFamily: '"Times New Roman"', fontSize: 15 }}>
      Worth Advertising Group automatically tracks certain information about you
      based upon your behavior while using the Services. This information is
      used to better understand and serve you by responding to your particular
      interests and needs.&nbsp;
    </span>
  </p>
  <p
    style={{
      marginRight: "5.6000pt",
      marginBottom: "0.0000pt",
      textIndent: "0.0000pt",
      textAlign: "justify"
    }}
  >
    <span style={{ fontFamily: '"Times New Roman"', fontSize: 15 }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginRight: "5.6000pt",
      marginBottom: "0.0000pt",
      textIndent: "0.0000pt",
      textAlign: "justify"
    }}
  >
    <em>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          fontStyle: "italic",
          fontSize: 15
        }}
      >
        Usage Details
      </span>
    </em>
  </p>
  <p
    style={{
      marginRight: "5.6000pt",
      marginBottom: "0.0000pt",
      textIndent: "0.0000pt",
      textAlign: "justify"
    }}
  >
    <span style={{ fontFamily: '"Times New Roman"', fontSize: 15 }}>
      When you access and use the Services, we may automatically collect certain
      details of your access to and use of the Services, including traffic data,
      date and time you access the Services, the number of links you click
      within the Services, any third party website and sections within the
      Services that you access, logs, resources and other communication data
      that you access and use on or through the Services.
    </span>
  </p>
  <p
    style={{
      marginRight: "5.6000pt",
      marginBottom: "0.0000pt",
      textIndent: "0.0000pt",
      textAlign: "justify"
    }}
  >
    <span style={{ fontFamily: '"Times New Roman"', fontSize: 15 }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginRight: "5.6000pt",
      marginBottom: "0.0000pt",
      textIndent: "0.0000pt",
      textAlign: "justify"
    }}
  >
    <em>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          fontStyle: "italic",
          fontSize: 15
        }}
      >
        Device Information
      </span>
    </em>
  </p>
  <p
    style={{
      marginRight: "5.6000pt",
      marginBottom: "0.0000pt",
      textIndent: "0.0000pt",
      textAlign: "justify"
    }}
  >
    <span style={{ fontFamily: '"Times New Roman"', fontSize: 15 }}>
      When you access the Services,
    </span>
    <em>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          fontStyle: "italic",
          fontSize: 15
        }}
      >
        &nbsp;
      </span>
    </em>
    <span style={{ fontFamily: '"Times New Roman"', fontSize: 15 }}>
      we may collect information about your device and internet connection,
      including the device's unique device identifier, IP address, operating
      system, browser type, and network information.
    </span>
  </p>
  <p
    style={{
      marginRight: "5.6000pt",
      marginBottom: "0.0000pt",
      textIndent: "0.0000pt",
      textAlign: "justify"
    }}
  >
    <span style={{ fontFamily: '"Times New Roman"', fontSize: 15 }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginRight: "5.6000pt",
      marginBottom: "0.0000pt",
      textIndent: "0.0000pt",
      textAlign: "justify"
    }}
  >
    <em>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          fontStyle: "italic",
          fontSize: 15
        }}
      >
        Location Information
      </span>
    </em>
  </p>
  <p
    style={{
      marginRight: "5.6000pt",
      marginBottom: "0.0000pt",
      textIndent: "0.0000pt",
      textAlign: "justify"
    }}
  >
    <span style={{ fontFamily: '"Times New Roman"', fontSize: 15 }}>
      When you use the Services, we may collect real-time information about the
      location of your device.&nbsp;
    </span>
  </p>
  <p
    style={{
      marginRight: "5.6000pt",
      marginBottom: "0.0000pt",
      textIndent: "0.0000pt",
      textAlign: "justify"
    }}
  >
    <span style={{ fontFamily: '"Times New Roman"', fontSize: 15 }}>
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginRight: "5.6000pt",
      marginBottom: "0.0000pt",
      textIndent: "0.0000pt",
      textAlign: "justify"
    }}
  >
    <em>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          fontStyle: "italic",
          fontSize: 15
        }}
      >
        Use of Cookies (or mobile cookies)
      </span>
    </em>
  </p>
  <p
    style={{
      marginRight: "5.6000pt",
      marginBottom: "0.0000pt",
      textIndent: "0.0000pt",
      textAlign: "justify"
    }}
  >
    <span style={{ fontFamily: '"Times New Roman"', fontSize: 15 }}>
      Our Services may use cookies to enhance the user experience while using
      our Services. Cookies are pieces of information that some apps and
      websites transfer to the computer or mobile device that is browsing that
      app or website and are used for record-keeping purposes by many apps and
      websites. Use of cookies makes web browsing easier by performing certain
      functions such as saving your passwords, saving personal preferences
      regarding your use of the particular app or website, and ensuring that you
      don't see the same advertisement repeatedly. It may be possible to refuse
      to accept mobile cookies by activating the appropriate setting on your
      device. However, if you select this setting you may be unable to access
      certain parts of our Services.
    </span>
  </p>
  <p
    style={{
      marginRight: "5.6000pt",
      marginBottom: "0.0000pt",
      textIndent: "0.0000pt",
      textAlign: "justify"
    }}
  >
    <em>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          fontStyle: "italic",
          fontSize: 15
        }}
      >
        &nbsp;
      </span>
    </em>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <em>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontStyle: "italic",
          fontSize: 15
        }}
      >
        Use of Web Beacons
      </span>
    </em>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      A “web beacon,” “clear GIF,” “web bug,” or “pixel tag” is a tiny graphic
      file with a unique identifier that is similar in function to a cookie, but
      allows us to count the number of users that have visited certain pages or
      screens of our Services, and to help determine the effectiveness of
      promotional or advertising campaigns, and for other related app
      statistics.
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      &nbsp;
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      We also may use these technologies to collect information about your
      activities over time and across third party websites, apps, or other
      online services linked to our Services (behavioral tracking).&nbsp;
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      &nbsp;
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        PARENTS AND CHILDREN:
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        &nbsp;
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      Our Services may not be used by anyone under the age of eighteen (18). Our
      Services are not intended for or directed to minors or children under 16
      years of age and no part of our Services are designed or intended to
      attract anyone under the age of 16. We will never ask for or knowingly
      collect information from children. If you are a child under the age of 16,
      you are not permitted to use the Services and should immediately exit our
      Services. Consistent with the Children's Online Privacy Protection Act, we
      will not knowingly collect any information from children under the age of
      16. If you think that we have collected personal information from a child
      under the age of 16 through the Services, please contact us at&nbsp;
    </span>
    <a href="mailto:Help@InfoRide.com">
      <span style={{ fontFamily: '"Times New Roman"', fontSize: 15 }}>
        Worth@worthadv.com
      </span>
    </a>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      &nbsp;and we will dispose of the information.&nbsp;
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      &nbsp;
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        THIRD PARTY INFORMATION COLLECTION:
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        &nbsp;
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      When you use the Services, and certain third party sites linked from the
      Services, third parties may use automatic information collection
      technologies to collect information, including location information about
      you or your device. These third parties may include: InfoRide business
      partners, advertisers, ad networks, ad servers, your mobile device
      manufacturer, your mobile service provider, or other service
      providers.&nbsp;
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      &nbsp;
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      The information they collect may be associated with your personal
      information and location or they may collect information, about your
      online activities over time and across different websites, apps, and other
      online services websites. They may use this information to provide you
      with interest-based (behavioral) advertising or other targeted
      content.&nbsp;
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      &nbsp;
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      We do not control these third parties' tracking technologies or how they
      may be used. If you have any questions about any third party content,
      advertisement or other targeted content, you should contact the
      responsible provider directly.&nbsp;
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      &nbsp;
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        HOW WE PROCESS YOUR INFORMATION&nbsp;
      </span>
    </strong>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        AND OUR LAWFUL BASIS FOR DOING SO:
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        &nbsp;
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        Generally
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      We use Information that we collect about you or that you provide to us,
      including PII to:
    </span>
  </p>
  <ul style={{ listStyleType: "undefined" }}>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        Create and maintain your InfoRide App Buyer account;&nbsp;
      </span>
    </li>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        Create and maintain your authorized Dealership and End User account (as
        applicable);&nbsp;
      </span>
    </li>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        Provide the functionality and Services requested or purchased;
      </span>
    </li>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        Connect Dealerships and End Users with InfoRide App Buyers;
      </span>
    </li>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        Process payments;
      </span>
    </li>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        Facilitate your orders and requests for Services;
      </span>
    </li>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        Provide users with customer service and technical support;
      </span>
    </li>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        Provide users with a tailored experience when using our Services;
      </span>
    </li>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        Enhance functionality of our Services;
      </span>
    </li>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        Protect the security and integrity of our Services and help prevent
        fraud;
      </span>
    </li>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        Conduct analytics;
      </span>
    </li>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        Notify you when InfoRide App updates are available, and of changes to
        any services we offer or provide through it.
      </span>
    </li>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        To send important notices, such as communications about changes to our
        terms, conditions and policies.&nbsp;
      </span>
    </li>
  </ul>
  <p
    style={{
      marginTop: "0.0000pt",
      marginBottom: "0.0000pt",
      marginLeft: "36.0000pt",
      textAlign: "justify"
    }}
  >
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      &nbsp;
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      The Information we collect helps us to improve our Services and to deliver
      a better and more personalized experience by enabling us to:
    </span>
  </p>
  <ul style={{ listStyleType: "undefined" }}>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        Estimate our audience size and usage patterns.
      </span>
    </li>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        Store information about your preferences, allowing us to customize our
        Services according to your individual interests.
      </span>
    </li>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        Recognize you when you use the Services.
      </span>
    </li>
  </ul>
  <p
    style={{
      marginTop: "0.0000pt",
      marginBottom: "0.0000pt",
      textAlign: "justify"
    }}
  >
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        &nbsp;
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        Information Provided at Time of Registration
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      To access and use certain of the Services, you may be required to register
      a unique username and password, by entering your name, e-mail address,
      phone number, and other information that assists Worth Advertising Group
      in providing access to the InfoRide App, Platform and other Services
      selected by you. This information is necessary for us to provide you with
      access to and use of the Services.
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      &nbsp;
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        Supplemental Information Provided While Using the Services
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      Supplemental information may be requested and provided by you through the
      Services to facilitate the offering of Services and functionality
      requested or purchased by you or the Dealership for which you are an
      authorized End User of, including the following:
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      &nbsp;
    </span>
  </p>
  <ul style={{ listStyleType: "undefined", marginLeft: 26 }}>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        As an authorized End User of a Dealership certain vehicle inventory
        information may be provided by you (“
      </span>
      <strong>
        <span
          style={{
            fontFamily: '"Times New Roman"',
            color: "rgb(0,0,0)",
            fontWeight: "bold",
            fontSize: "11.0000pt"
          }}
        >
          Vehicle Data
        </span>
      </strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ”). Vehicle Data may include, but is not limited to, vehicle
        photographs, VIN numbers, vehicle make, model, trim, color, year,
        features, and other specifications. Vehicle Data is information that we
        obtain from you, related to the vehicle inventory of the Dealership of
        which you are an authorized End User. Vehicle Data includes&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        data in electronic form input or collected through the Platform uploaded
        by an End User.&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        We may collect and process the Vehicle Data provided by you, in order to
        provide the Services requested or purchased by you, or the Dealership
        for which you are an authorized End User of. &nbsp;
      </span>
    </li>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        As a user of the InfoRide App and/or Platform certain chat feature
        functionality will be made available for you, to allow Buyer and
        Dealerships/End Users to directly connect in order to complete the
        vehicle purchase and sale. All information provided by you in the chat
        feature may be stored, saved and/or reviewed by Worth Advertising
        Group.&nbsp;
      </span>
    </li>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        Handling contact and support requests. If you send us an email through
        our “Contact” link to request user support, or if you contact us by
        other means including but not limited to via phone, we process your
        personal information to the extent it is necessary for our legitimate
        interest in fulfilling your requests and communicating with you.
      </span>
    </li>
  </ul>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      &nbsp;
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        Payment Information
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      To facilitate processing payments, you may need to create a digital wallet
      and provide a payment method and the following information: name, billing
      address, credit card or banking information, and such other applicable
      information as requested by our third party payment processing service,
      which is currently Stripe, whose privacy policy can be reviewed at
      https://stripe.com/privacy. If your payment method information changes
      (e.g., card number, account number, routing number, expiration date, CVV),
      then our third party payment processor may acquire additional information
      from you. This information is necessary for us to verify and process
      payments for transactions made through the Services.
    </span>
  </p>
  <p
    style={{
      marginTop: "0.0000pt",
      marginBottom: "0.0000pt",
      textAlign: "justify"
    }}
  >
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      &nbsp;
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        HOW WE SHARE YOUR INFORMATION:
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        &nbsp;
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      We may disclose non-personal information about our users, and information
      that does not identify any individual or device, without restriction. We
      do not sell, share, or otherwise transfer your personal information to
      third parties, except for sharing your personal information with our
      third-party service providers and partners in furtherance of Worth
      Advertising Group’s Business Purposes as described below. We may also
      share your personal information when we believe sharing is necessary to
      comply with applicable law, enforce our policies, or protect our or
      others' rights, property, or safety. We may share your Information as
      follows:
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      &nbsp;
    </span>
  </p>
  <ul style={{ listStyleType: "undefined" }}>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        To fulfill the purpose for which you provide it. For example, if you
        contact us regarding a privacy request, we will transmit the contents of
        that email and your email address to the proper party at Worth
        Advertising Group to address your concern.&nbsp;
      </span>
    </li>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        By using the InfoRide App and/or Platform, users consent to Worth
        Advertising Group’s collection and use of the Information provided by
        you in order to advance Worth Advertising Group’s purposes, including,
        but not limited to:
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        &nbsp;(a) maintain and service your account; (b) process transactions
        and request for Services, which includes but is not limited to: (i)
        processing Vehicle Data; (ii) monitoring chats; (iii) connecting
        Dealerships/End Users with Buyers; and (iv) processing payments for
        Services; and (c) providing similar services to you&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        (collectively “
      </span>
      <strong>
        <span
          style={{
            fontFamily: '"Times New Roman"',
            color: "rgb(0,0,0)",
            fontWeight: "bold",
            fontSize: "11.0000pt"
          }}
        >
          Worth Advertising Group’s Business Purposes
        </span>
      </strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ”).
      </span>
    </li>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        We may share your personal information, and credit card and banking
        information (only for payment processing) with other trusted third-party
        companies and individuals to provide and improve the Services,
        facilitate and complete transactions and requested services, process
        payments, analyze use of our Services, &nbsp;perform analytics and
        produce statistics on the Services, or deliver other requested services
        and features to you.&nbsp;
      </span>
    </li>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        For any other purpose disclosed by us when you provide the Information.
      </span>
    </li>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        To comply with any court order, law, or legal process, including to
        respond to any government or regulatory request.
      </span>
    </li>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        To enforce our rights arising from any contracts entered into between
        you and us, including under any EULA or SaaS Agreement.
      </span>
    </li>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        If we believe disclosure is necessary or appropriate to protect the
        rights, property, or safety of Worth Advertising Group our customers or
        others. This includes exchanging information with other companies and
        organizations for the purposes of fraud protection and credit risk
        reduction.
      </span>
    </li>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        To an affiliate, subsidiary organization, buyer or other successor in
        the event of a merger, divestiture, restructuring, reorganization,
        dissolution, or other sale or transfer of some or all of Worth
        Advertising Group's assets, whether as a going concern or as part of
        bankruptcy, liquidation, or similar proceeding, in which personal
        information held by Worth Advertising Group about our Service users is
        among the assets transferred.
      </span>
    </li>
  </ul>
  <p
    style={{
      marginTop: "0.0000pt",
      marginBottom: "0.0000pt",
      marginLeft: "36.0000pt"
    }}
  >
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      &nbsp;
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        YOUR CHOICES ABOUT OUR COLLECTION, USE, AND DISCLOSURE OF YOUR
        INFORMATION:
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        &nbsp;
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      We strive to provide you with choices regarding the Information you
      provide to us. This section describes mechanisms we provide for you to
      control certain uses and disclosures of your Information.&nbsp;
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      &nbsp;
    </span>
  </p>
  <ul style={{ listStyleType: "undefined" }}>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <strong>
        <span
          style={{
            fontFamily: '"Times New Roman"',
            color: "rgb(0,0,0)",
            fontWeight: "bold",
            fontSize: "11.0000pt"
          }}
        >
          Do Not Track Signals.
        </span>
      </strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        &nbsp;You can set your browser to refuse all or some browser cookies, or
        to alert you when cookies are being sent. If you disable or refuse
        cookies or block the use of other tracking technologies, some parts of
        the Services may then be inaccessible or not function properly.
      </span>
    </li>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: "11.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <strong>
        <span
          style={{
            fontFamily: '"Times New Roman"',
            color: "rgb(0,0,0)",
            fontWeight: "bold",
            fontSize: "11.0000pt"
          }}
        >
          Location Information.
        </span>
      </strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        &nbsp;You can choose whether or not to allow the Services to collect and
        use real-time information about your device's location through the
        device's privacy settings and location settings. If you block the use of
        location information, some parts of the Services may become inaccessible
        or not function properly.&nbsp;
      </span>
    </li>
  </ul>
  <p
    style={{
      marginTop: "0.0000pt",
      marginBottom: "0.0000pt",
      marginLeft: "36.0000pt",
      textAlign: "justify"
    }}
  >
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        &nbsp;
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        OpT-IN/Opt-Out Communications:&nbsp;
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        &nbsp;
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      We provide users of the Services the opportunity to sign-up for certain
      email communications using their email address. Users are not obligated to
      sign-up for these communications. You may revoke your consent to receiving
      these communications at any time by clicking the unsubscribe button in the
      email correspondence or contacting us at&nbsp;
    </span>
    <a href="mailto:Help@InfoRide.com">
      <span style={{ fontFamily: '"Times New Roman"', fontSize: 15 }}>
        Worth@worthadv.com
      </span>
    </a>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      &nbsp;
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        INFORMATION RETENTION AND ACCESSING AND CHANGING YOUR PERSONAL
        INFORMATION:
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        &nbsp;
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontWeight: "normal",
        fontSize: 15
      }}
    >
      Information collected through the Services will be deleted when such
      Information no longer serves one of the above-mentioned purposes.&nbsp;
    </span>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontWeight: "normal",
        fontSize: 15
      }}
    >
      You can review and change the personal information that you entered, by
      logging into the Services and visiting the settings page.
    </span>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        &nbsp;
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontWeight: "normal",
        fontSize: 15
      }}
    >
      &nbsp;
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      You may also send us an email at&nbsp;
    </span>
    <a href="mailto:Help@InfoRide.com">
      <span style={{ fontFamily: '"Times New Roman"', fontSize: 15 }}>
        Worth@worthadv.com
      </span>
    </a>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      &nbsp;to request access to, correct, or delete any Information that you
      have provided to us. In some cases, we may not be able to delete your
      Information except by also deleting your user access. We may not
      accommodate a request to change information if we believe the change would
      violate any law or legal requirement or cause the information to be
      incorrect.
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        &nbsp;
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        CALIFORNIA PRIVACY RIGHTS - NOTICE TO CALIFORNIA RESIDENTS:
      </span>
    </strong>
  </p>
  <p style={{ textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      If you are a California resident, California law may provide you with
      additional rights regarding our use of your Information. To learn more
      about your California privacy rights, visit &nbsp;
    </span>
    <a href="https://oag.ca.gov/privacy/privacy-laws">
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: 15
        }}
      >
        https://oag.ca.gov/privacy/privacy-laws
      </span>
    </a>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15,
        background: "rgb(255,255,0)"
      }}
    >
      &nbsp;
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        &nbsp;
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        DATA PROTECTION AND SECURITY:
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <u>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "normal",
          textDecoration: "underline",
          fontSize: 15
        }}
      >
        &nbsp;
      </span>
    </u>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      We have implemented measures designed to secure your PII from accidental
      loss and from unauthorized access, use, alteration, and disclosure. The
      safety and security of your Information also depends on you. Be sure not
      to let others access your device or account. Unfortunately, the
      transmission of information via the internet and mobile platforms is not
      completely secure. Although we do our best to protect your PII, we cannot
      guarantee the security of your PII transmitted through our Services. Any
      transmission of PII is at your own risk. We are not responsible for the
      circumvention of any privacy settings or security measures we provide.
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      &nbsp;
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        CONSENT:
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        &nbsp;
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      When you provide us with PII through our Services, through the mail, over
      the phone, or by using the Services we imply that you consent to our
      collecting it and using it to carry out Worth Advertising Group’s Business
      Purposes. &nbsp;
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      &nbsp;
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      IMPORTANT: Except as stated herein as necessary to carry out Worth
      Advertising Group’s Business Purposes and to provide the Services, we do
      not share your personal information to any third parties, including but
      not limited to advertisers, strategic partners or vendors.&nbsp;
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      &nbsp;
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        Third Party SITES AND Links:
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        &nbsp;
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      The Services contain links to third party sites. Please be aware that we
      are not responsible for any of the content, information, data, activities,
      offers, deals, promotions, products, services or advertisements
      (collectively, “
    </span>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        Content
      </span>
    </strong>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      ”) on these third party sites, that may be made available to you through
      these third party sites, or that may be advertised on the Services. By
      accessing any linked third party site, you assume all risk of use and
      reliance upon any of the Content contained on the third party site.
      &nbsp;&nbsp;
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      &nbsp;
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      By providing you a link to these third party sites, we make no
      representations, warranties, approvals, or endorsements regarding the
      Content provided or the nature, quality, or accuracy of such Content, or
      other services made available. We encourage you to read the terms of use
      and the privacy policies of each such linked third party site you choose
      to access.
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0.0000pt",
      textAlign: "justify",
      background: "rgb(255,255,255)"
    }}
  >
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        ANALYTICS/PERFORMANCE COOKIES:
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0.0000pt",
      textAlign: "justify",
      background: "rgb(255,255,255)"
    }}
  >
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        &nbsp;
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "0.0000pt",
      textAlign: "justify",
      background: "rgb(255,255,255)"
    }}
  >
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      Worth Advertising Group may use analytic tools to analyze the performance
      of our Services as well as how visitors use our Services including:
    </span>
  </p>
  <ul style={{ listStyleType: "undefined", marginLeft: 26 }}>
    <li>
      <span
        style={{
          fontFamily: "Symbol",
          color: "rgb(0,0,0)",
          fontSize: "10.0000pt"
        }}
      >
        ·&nbsp;
      </span>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        Google Analytics - For more information on Google Analytics cookies and
        privacy policies, visit&nbsp;
      </span>
      <a href="http://www.google.com/policies">
        <span
          style={{
            fontFamily: '"Times New Roman"',
            color: "rgb(0,0,0)",
            fontSize: "11.0000pt"
          }}
        >
          www.google.com/policies
        </span>
      </a>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        . To opt-out of being tracked by Google Analytics across all websites
        visit&nbsp;
      </span>
      <a href="https://tools.google.com/dlpage/gaoptout">
        <u>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              color: "rgb(27,81,124)",
              textDecoration: "underline",
              fontSize: "11.0000pt"
            }}
          >
            http://tools.google.com/dlpage/gaoptout
          </span>
        </u>
      </a>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontSize: "11.0000pt"
        }}
      >
        . This will allow you to download and install a Google Analytics
        cookie-free web browser.
      </span>
    </li>
  </ul>
  <p
    style={{
      marginTop: "0.0000pt",
      marginLeft: "36.0000pt",
      textAlign: "justify",
      background: "rgb(255,255,255)"
    }}
  >
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      &nbsp;
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        EMAIL AND MARKETING COMMUNICATIONS:
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        &nbsp;
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      We are committed to being compliant with the Controlling the Assault of
      Non-Solicited Pornography and Marketing Act of 2003 (“CAN-SPAM Act”). All
      e-mails you receive from Worth Advertising Group are intended to fully
      comply with the CAN-SPAM Act. You can withdraw your consent to receive any
      direct marketing communication from us by contacting&nbsp;
    </span>
    <a href="mailto:Help@InfoRide.com">
      <span style={{ fontFamily: '"Times New Roman"', fontSize: 15 }}>
        Worth@worthadv.com
      </span>
    </a>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      &nbsp;or following the opt-out instructions in our email communications.
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      &nbsp;
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        CHANGES TO OUR PRIVACY POLICY:
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        &nbsp;
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      We reserve the right to change the terms of this Privacy Policy at any
      time by posting those changes to this Privacy Policy, so that you are
      always aware of our processes related to collection, use and disclosure of
      your Information. For significant changes, we will notify you by posting a
      prominent notice on our Website indicating at the top of the Privacy
      Policy when it was most recently updated. &nbsp;Please consult the Website
      regularly for important changes to the Privacy Policy as they occur.
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      &nbsp;
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      You agree to be bound by all the provisions of the Privacy Policy that are
      displayed on the Website on the date you use the Services. Your continued
      use of the Services, indicates your acceptance of all of the provisions of
      this Privacy Policy displayed on the Website on the date of such
      use.&nbsp;
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        &nbsp;
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        CONTACT INFORMATION:
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        &nbsp;
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      To ask questions or comments about this Privacy Policy and our privacy
      practices, contact us at:&nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0.0000pt",
      marginLeft: "36.0000pt",
      textAlign: "justify"
    }}
  >
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      &nbsp;
    </span>
  </p>
  <p
    style={{
      marginTop: "0.0000pt",
      marginLeft: "36.0000pt",
      textAlign: "justify"
    }}
  >
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      Worth Advertising Group
    </span>
  </p>
  <p
    style={{
      marginTop: "0.0000pt",
      marginLeft: "36.0000pt",
      textAlign: "justify"
    }}
  >
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      625 W. University Avenue
    </span>
  </p>
  <p
    style={{
      marginTop: "0.0000pt",
      marginLeft: "36.0000pt",
      textAlign: "justify"
    }}
  >
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      Gainesville, Florida 32601
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", marginLeft: "36.0000pt" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        Telephone Number:&nbsp;
      </span>
    </strong>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      352-519-5852
    </span>
  </p>
  <p style={{ marginTop: "0.0000pt", marginLeft: "36.0000pt" }}>
    <strong>
      <span
        style={{
          fontFamily: '"Times New Roman"',
          color: "rgb(0,0,0)",
          fontWeight: "bold",
          fontSize: 15
        }}
      >
        Email address:&nbsp;
      </span>
    </strong>
    <a href="mailto:Help@InfoRide.com">
      <span style={{ fontFamily: '"Times New Roman"', fontSize: 15 }}>
        Worth@worthadv.com
      </span>
    </a>
  </p>
  <p style={{ marginTop: "0.0000pt", textAlign: "justify" }}>
    <span
      style={{
        fontFamily: '"Times New Roman"',
        color: "rgb(0,0,0)",
        fontSize: 15
      }}
    >
      &nbsp;
    </span>
  </p>
</div>

    </div>
  );
};
