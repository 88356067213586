import React from "react";
//     importing images
import UserImage from "../../../../Assests/Images/image 2.png";
import BusinessAvatar from "../../../ModuleComponents/Skeleton/businessAvatar";
//     importing css files
import "./chatProfileCard.css";
export const ChatProfileCard = (props) => {
  const buyer = props?.conversation?.buyer;
  if (props.loading && !buyer) {
    return (
      <div className="chat-profile-card">
        <BusinessAvatar />
      </div>
    );
  } else {
    return (
      <div className="chat-profile-card">
        <div>
          <img
            src={buyer?.profilePicture?.image?.url || "/user-avatar.png"}
            className="chat-user-image"
            alt=""
          />
          <p>{buyer?.fullName}</p>
        </div>
      </div>
    );
  }
};
