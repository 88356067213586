import React from "react";
//     importing components
import { ForgetPassword } from "../../Components/ModuleComponents/AuthComponents/ForgetPassword/forgetPassword";
import { WelcomeToInfo } from "../../Components/ModuleComponents/AuthComponents/WelcomeToInfo/welcomeToInfo";

export const ForgetPasswordScreen = () => {
  return (
    <div className="sign-in-view-main-div">
      <div className="sign-in-image-div">
        <WelcomeToInfo />
      </div>
      <div className="sign-in-info-div">
        <ForgetPassword />
      </div>
    </div>
  );
};
