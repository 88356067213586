import React, { useState } from "react";
import ExcelReader from './excelReader'
import { SheetJSFT } from './types';
const ExcelToJson = ({onRead, onChangeState, validHeaders}) => {
    const [fileName, setFileName] = useState("")
    const [loadingExcel, setLoadingExcel] = useState(false);
    const [excelImportError, setExcelImportError] = useState(false);
    const [headers, setHeaders] = useState([])
    const isSheetValid = (headers_) => {
      console.log("Found Headers", headers_)
      console.log("Valid headers", validHeaders)
      let isValid = true;
      if(Array.isArray(validHeaders)){
        if(headers_.length !== validHeaders.length ) {
          console.log("Invalid length")
          isValid = false
        }else{
          for (let index = 0; index < headers_.length; index++) {
            const singleHeader = headers_[index];
            if(!validHeaders.includes(singleHeader)){
              isValid = false;
              break;
            }
          }
        }
      }
      return isValid;
    } 
    const handelImportData = () => {
        setLoadingExcel(true)
        onChangeState(true, "")
        setExcelImportError("");
        ExcelReader.handleFile()
          .then((res) => {
            console.log("Extracted Response", res);
            setLoadingExcel(false)
            onChangeState(false, "")
            setHeaders(res.headers)
            if(isSheetValid(res.headers)){
              onRead(res.headers, res.data)
            }else{
              onChangeState(false, "Invalid Headers")
            }
          })
          .catch((err) => {
            console.log(err);
            onChangeState(false, err)
            setLoadingExcel(true)
            setExcelImportError(err);
          })
    }
    const handleChange = (e) => {
        console.log(e.target.value);
        setFileName(e.target.files[0].name)/// e.target.value.replace(/^.*[\\\/]/, ""),
        ExcelReader.handleChange(e);
        handelImportData();
    }
    return (
        <>
              <label
                style={{
                  height: 40,
                  paddingRight: 5,
                  paddingLeft: 5,
                  justifyContent:"center", 
                  alignItems:"center",
                  display:"flex",
                  backgroundColor: "black",
                  // padding: 10,
                  color: "black",
                  cursor: "pointer",
                  borderRadius: 5,
                }}
                htmlFor="file"
              >
                <span style={{color:"white"}}>
                  {fileName === ""
                    ? "Upload an EXCEL File"
                    : fileName}
                </span>
              </label>
              <input
                className="form-control"
                id="file"
                type="file"
                accept={SheetJSFT}
                onChange={handleChange}
                style={{ display: "none" }}
              />
        </>
    )
}

ExcelReader.defaultProps = {
    onChangeState: (processing, error) => false,
    onRead: (headers, data) => false
}

export default ExcelToJson;