import React, { useEffect, useState } from "react";
//      Importing css file
import "./toggleButton.css";
export const ToggleButton = (props) => {
  // const [toggle, setToggle] = useState(true);

  // useEffect(() => {
  //   if(typeof(props.initialValue) === "boolean"){
  //     setToggle(props.initialValue);
  //   }
  // }, [])

  // useEffect(() => {
  //   if(props.onChange) props.onChange(toggle);
  // }, [toggle])

  const toggle = props.value ? true : false;
  const handleToggle = () => {
    if (props.onChange) props.onChange();
  };

  return (
    <div>
      <span className="toggle-button-title">{props.title}</span>
      <label className="switch" style={props.style}>
        <input onClick={handleToggle} type="checkbox" />
        <span
          className={
            toggle === false ? "slider round" : "slider sliderChecked round"
          }
        ></span>
      </label>
    </div>
  );
};
