import { AUTH } from "../types";
import produce from "immer";
const state = {
  splashVisible: true,
  currentUser: null,
  accessbarVisible: false,
};

const AuthReducer = (mState = { ...state }, action) => {
  switch (action.type) {
    case AUTH.SET_SPLASH_VISIBLE:
      return produce(mState, (draftState) => {
        console.log(`Setting splash visible ${action.payload}`);
        draftState.splashVisible = action.payload;
      });
    case AUTH.SET_CURRENT_USER:
      return produce(mState, (draftState) => {
        console.log(`Setting splash visible ${action.payload}`);
        draftState.currentUser = action.payload;
      });

    case AUTH.SET_DELEGATE_BAR:
      return produce(mState, (draftState) => {
        // console.log(`Setting splash visible`, action.payload);
        draftState.accessbarVisible = action.payload;
      });
    case AUTH.LOGOUT:
      return { ...state };
    default:
      return { ...mState };
  }
};
export default AuthReducer;
