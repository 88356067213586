import React from "react";

import WalletCArdSim from "../../../Assests/Icons/walletCardSim.svg";
import WalletArrow from "../../../Assests/Icons/walletArrow.svg";
import { Buttons } from "../../../Components/AbstractsComponents/Button/button";
import PlusIcon from "../../../Assests/Icons/walletPlusIcon.svg";
import ArrowBlack from "../../../Assests/Icons/ArrowBlack.svg";
import PlusBlackicon from "../../../Assests/Icons/PlusBlackIcon.svg";
import VisaCardImg from "../../../Assests/Icons/visaCardImg.svg";
import { TextFields } from "../../../Components/AbstractsComponents/TextFields/textFields";
import { BackButton } from "../../../Components/AbstractsComponents/BackButton/backButton";
//      icons for setup payment menthod
import CardImg from "../../../Assests/Icons/Visa.svg";
import CardImg1 from "../../../Assests/Icons/card2.svg";
import CardImg2 from "../../../Assests/Icons/American Express.svg";
import CardImg3 from "../../../Assests/Icons/card3.svg";
import CVCCardImg from "../../../Assests/Icons/CVC.svg";
import "./walletModal.css";
export const WalletModal = (props) => {
  return (
    <div className="wallet-modal-main-div">
      <div className="wallet-modal-div">
        <h5>Wallet</h5>
        <div className="wallet-modal-info-ride-cash-div">
          <p>Info Ride Cash</p>
          <img alt="" src={WalletCArdSim} className="wallet-card-sim" />
          <div className="wallet-payment-value-div">
            <p>$200</p>
            <img alt="" src={WalletArrow} />
          </div>
          <div>
            <a
              style={{ textDecoration: "none", color: "black" }}
              href="#AddPaymentAmount"
            >
              <Buttons
                leftIcon={PlusIcon}
                style={{
                  width: "155px",
                  height: "35px",
                  fontSize: "16px",
                  boxShadow: "none",
                  lineHeight: "31px",
                }}
              >
                Add Funds
              </Buttons>
            </a>
          </div>
        </div>
      </div>
      <div className="wallet-modal-payment-method-div">
        <div className="wallet-modal-payment-method-inner-div">
          <p className="payment-method-heading">Payments Method</p>
          <div className="visa-card-img-div">
            <div>
              <img alt="" className="visa-card-img" src={VisaCardImg} />
              <p>**** **** **** **20</p>
            </div>
            <img alt="" src={ArrowBlack} />
          </div>
          <button className="add-payment-method-button">
            <img alt="" src={PlusBlackicon} className="add-paymnet-plus-icon" />
            <a
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "18px",
              }}
              href="#addPaymentMethod"
            >
              <span>Add payment method</span>
            </a>
          </button>
        </div>
      </div>
    </div>
  );
};

export const SetUpPaymentModal = (props) => {
  const ImgArr = [CardImg, CardImg1, CardImg2, CardImg3];
  const CVCcardImg = [CVCCardImg];
  return (
    <div className="set-up-payment-modal-div">
      <div className="set-up-payment-head-div">
        <a style={{ textDecoration: "none", color: "black" }} href="#">
          <BackButton
            style={{ margin: "auto 20px", fontSize: "20px" }}
            imgStyle={{ width: "16px", height: "14px" }}
          >
            Back
          </BackButton>
        </a>
        <div style={{ textAlign: "center", width: "421px" }}>
          <h4>Setup Payment Method</h4>
        </div>
      </div>
      <div className="set-up-dapyment-method-input-div">
        <TextFields
          style={{
            borderBottomLeftRadius: "0px",
            borderBottomRightRadius: "0px",
          }}
          label="Country or region"
          placeholder="1234 1234 1234 1234"
          type={"text"}
          textIconArr={ImgArr}
        />
        <div style={{ display: "flex", marginTop: "-21px" }}>
          <div style={{ width: "50%" }}>
            <TextFields
              style={{
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
                borderBottomRightRadius: "0px",
              }}
              modal
              placeholder="MM / YY"
              type={"date"}
            />
          </div>
          <div style={{ width: "50%" }}>
            <TextFields
              style={{
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
                borderBottomLeftRadius: "0px",
              }}
              modal
              placeholder="CVC"
              type={"text"}
              textIconArr={CVCcardImg}
            />
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <TextFields
            modal
            style={{ width: "421px" }}
            label="Name on Card"
            placeholder=""
            type={"text"}
          />
        </div>
        <div style={{ width: "100%" }}>
          <TextFields
            modal
            style={{ width: "421px" }}
            label="Country or region"
            placeholder=""
            type={"text"}
          />
          <TextFields
            modal
            style={{ width: "421px" }}
            placeholder=""
            type={"select"}
          />
        </div>
        <div style={{ marginTop: "30px" }}>
          <Buttons
            onClick={() => props.closeModal()}
            style={{
              width: "100%",
              height: "60px",
              fontSize: "18px",
              boxShadow: "none",
              lineHeight: "55px",
            }}
          >
            Add
          </Buttons>
        </div>
      </div>
    </div>
  );
};
export const SetUpPaymentAmountModal = (props) => {
  return (
    <div className="set-up-payment-modal-div">
      <div className="set-up-payment-head-div">
        <a style={{ textDecoration: "none", color: "black" }} href="#">
          {" "}
          <BackButton
            style={{ margin: "auto 20px", fontSize: "20px" }}
            imgStyle={{ width: "16px", height: "14px" }}
          >
            Back
          </BackButton>
        </a>
        <div style={{ textAlign: "center", width: "421px" }}>
          <h4>Setup Payment Method</h4>
        </div>
      </div>
      <div className="set-up-dapyment-method-input-div add-funds-div">
        <div style={{ width: "100%" }}>
          <TextFields
            modal
            style={{ width: "421px" }}
            label="Amount"
            placeholder="Add more then $50"
            type={"text"}
          />
        </div>
        <div style={{ marginTop: "30px" }}>
          <Buttons
            onClick={() => props.closeModal()}
            style={{
              width: "100%",
              height: "60px",
              fontSize: "18px",
              boxShadow: "none",
              lineHeight: "55px",
            }}
          >
            Add
          </Buttons>
        </div>
      </div>
    </div>
  );
};
