import React, { useRef, useState } from "react";
import VehiclesController from "../../../Controllers/vehiclesController";
import { Buttons } from "../../AbstractsComponents/Button/button";
import { Modals } from "../../AbstractsComponents/Modal/modal"; ///"../AbstractsComponents/Modal/modal";
import { FormTextArea } from "../../AbstractsComponents/TextFields/textFields";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
const CloseDealButton = ({ conversation, offer }) => {
  const navigate = useNavigate();
  // const [disputeReason, setDisputeReason] = useState("");
  const disputeReason = useRef("");
  const [isOpen, setIsOpen] = useState(false);
  const [mode, setMode] = useState(0);
  const [processing, setProcessing] = useState(false);
  const [message, setMessage] = useState("");
  const handleCloseModal = () => {
    setMode(0);
    setIsOpen(false);
  };
  const setDisputeReason = (text) => (disputeReason.current = text);

  console.log(offer, "Offer.....");

  const handleCloseDeal = () => {
    setMode(2);
    setMessage(`Closing offer ...!`);
    VehiclesController.closeOffer(offer._id)
      .then(() => {
        handleCloseModal();
        navigate("/allVehicle");
      })
      .catch((err) => {
        handleCloseModal();
      });
  };

  const handleDisputeDeal = () => {
    setMode(2);
    setMessage(`Creating dispute ...!`);
    //  setMessage(`Closing offer ...!`)
    console.log(
      `Submitting dispute vid:${offer.vehicle._id} offerId:${offer._id} reason:${disputeReason.current}`
    );
    VehiclesController.createConflict(
      offer._id,
      offer.vehicle._id,
      disputeReason.current
    )
      .then(() => {
        handleCloseModal();
        navigate("/allVehicle");
      })
      .catch((err) => {
        handleCloseModal();
      });
  };

  const RenderControls = ({ onDisputeReason }) => {
    switch (mode) {
      case 2:
        return (
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              flexDirection: "column",
              paddingLeft: 40,
              paddingRight: 40,
            }}
          >
            <div
              style={{
                height: 100,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
              }}
            >
              <TailSpin
                height="30px"
                width="30px"
                color="black"
                ariaLabel="loading"
              />
              <div style={{ width: 20 }} />
              <h6 style={{ marginTop: 5 }}>{message}</h6>
            </div>
          </div>
        );
      case 1:
        return (
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              flexDirection: "column",
              paddingBottom: 30,
              paddingLeft: 40,
              paddingRight: 40,
            }}
          >
            <div style={{ width: "100%" }}>
              <FormTextArea
                onChange={(e) => onDisputeReason(e.target.value)}
                label="Conflict Reason ?"
                placeholder="Type reason here.!"
                width={200}
              />
            </div>
            <div style={{ height: 20 }} />
            <Buttons
              onClick={handleDisputeDeal}
              style={{ width: 200, height: 50, backgroundColor: "red" }}
            >
              Dispute
            </Buttons>
          </div>
        );
      default:
        return (
          <div
            style={{
              display: "flex",
              flex: 1,
              height: 100,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Buttons
              onClick={handleCloseDeal}
              style={{ width: 200, height: 50, backgroundColor: "#64B822" }}
            >
              Confirm
            </Buttons>
            <div style={{ width: 50 }} />
            <Buttons
              onClick={() => setMode(1)}
              style={{ width: 200, height: 50, backgroundColor: "red" }}
            >
              Dispute
            </Buttons>
          </div>
        );
    }
  };

  if (offer.status === "conflict") {
    return (
      <Buttons
        disabled
        onClick={() => setIsOpen(true)}
        style={{ width: 130, height: 50, display: "flex", margin: "auto" }}
      >
        Under review
      </Buttons>
    );
  } else if (offer.status === "closed") {
    return (
      <Buttons
        disabled
        onClick={() => setIsOpen(true)}
        style={{ width: 130, height: 50, display: "flex", margin: "auto" }}
      >
        Closed
      </Buttons>
    );
  } else {
    return (
      <div>
        <Buttons
          onClick={() => setIsOpen(true)}
          style={{ width: 150, height: 50, display: "flex", margin: "auto" }}
        >
          Confirm Deal
        </Buttons>
        <Modals modalIsOpen={isOpen} closeModal={handleCloseModal}>
          <div
            style={{
              width: "100%",
              backgroundColor: "white",
              borderRadius: 10,
            }}
          >
            <RenderControls onDisputeReason={setDisputeReason} />
          </div>
        </Modals>
      </div>
    );
  }
};
export default CloseDealButton;
