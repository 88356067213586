import React, { useState } from "react";
//    importring icons
import PlusIcon from "../../../Assests/Icons/imagePlusIcon.svg";
//    importing css files
import "./imageUpload.css";

export const ImageUploadV2 = ({ id, onChange, onRemove, image }) => {
  const handleChangeImageSource = (event) => {
    console.log(event.target.files[0]);
    if (onChange) {
      onChange({
        _id: image._id,
        source: event.target.files[0],
        objectUrl: URL.createObjectURL(event.target.files[0]),
      });
      //  onChange({_id: image._id, source: URL.createObjectURL(event.target.files[0])});
    }
  };

  if (image?.objectUrl) {
    return (
      <div
        className="offer-detail-inputs-div"
        style={{
          display: "flex",
          backgroundColor: "#f0f0f0",
          borderRadius: 10,
        }}
      >
        <p onClick={() => onRemove(image)} className="image-time-icon">
          &times;
        </p>
        <div
          className="offer-detail-label"
          style={{ backgroundColor: "transparent", borderWidth: 0 }}
        >
          <img
            style={{ objectFit: "cover" }}
            src={image.objectUrl}
            className="w-full h-full object-cover rounded-[0.6em]"
            alt=""
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="offer-detail-inputs-div" style={{ display: "flex" }}>
        <input
          className="offer-detail-inputs"
          onChange={handleChangeImageSource}
          type="file"
          id={id ? id : "actual-btn"}
          hidden
        />
        <label
          className="offer-detail-label"
          // style={{backgroundColor: "transparent", borderWidth: 0}}
          htmlFor={id ? id : "actual-btn"}
        >
          <div>
            <span className="image-upload-plus-icon">
              <img src={PlusIcon} alt="" />
            </span>
          </div>
        </label>
      </div>
    );
  }
};
