export const IsAuthenticated = "IsAuthenticated";

export const AUTH = {
  SET_SPLASH_VISIBLE: "SET_SPLASH_VISIBLE",
  SET_CURRENT_USER: "SET_CURRENT_USER",
  LOGOUT: "LOGOUT",
  SET_DELEGATE_BAR: "SET_DELEGATE_BAR",
};

export const VEHICLES = {
  SET_KEY_VALUE: "SET_KEY_VALYE",
  SET_OFFERS: "SET_VEHICLE_OFFERS",
  ON_OFFER_ACCEPTED: "ON_OFFER_ACCEPTED",
  ON_OFFER_CLOSED: "ON_OFFER_CLOSED",
  ON_OFFER_DISPUTE: "ON_OFFER_DISPUTE",
  PUSH_VEHICLE: "PUSH_VEHICLE",
  UPDATE: "UPDATE_VEHICLE",
  UPDATE_DATA: "UPDATE_VEHICLE_DATA",
  UPDATE_ENABLED_STATUS: "UPDATE_VEHICLE_ENABLED_STATUS",
  DELETE: "DELETE_VEHICLE",
};

export const ACTIONS = {
  SET_LOADER_KEY_VALUE: "SET_LOADER_KEY_VALUE",
};

export const INVOICES = {
  SET_ALL: "SET_ALL_INVOICES",
  SET_KEY_VALUE: "SET_INVOICES_KEY_VALUE",
};

export const CONVERSATIONS = {
  SET_VEHICLE_OFFER_CONVERSATION: "SET_VEHICLE_OFFER_CONVERSATION",
  PUSH_MESSAGE: "PUSH_CONVERSATION_MESSAGE",
  SET_KEY_VALUE: "SET_CONVERSATION_KEY_VALUE",
  UPDATE_MESSAGE: "UPDATE_CONVERSATION_MESSAGE",
};
