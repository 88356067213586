import io from 'socket.io-client';
import { BASE_URL } from '../proxy';
import AuthController from '../Controllers/authController'
import MessageController from '../Controllers/messageController';
let count = 0;
class SocketController{
   

    constructor(){
      // this.socket = null;
      count++;
        console.log(`### DANGER #### Socket Constructor calling ${count}`)
        // global.socket = null;
        this.socketConnected = false;
       
        const onConnection = () => {
          console.log(`Socket Connected!`)
            this.socketConnected = true;
            global.socket.on("onMessage", (res) => {
              console.log(`@=>OnNewMessage:`, res)
              MessageController.onNewMessage(res.query);
            })
            // MyBroadcastEventsManager.onConnection(global.socket);
        }

        const onDisconnect = () => {
          global.socket.removeAllListeners("onMessage");
            // MyBroadcastEventsManager.onDisconnect();
        }


        this.init = () => {
          const userId = AuthController.getCurrentUser()._id
          console.log(`Initializing Socket Connection`)
            global.socket = io(BASE_URL, {
              query: {uid:userId},
              reconnection: true,
              reconnectionDelay: 1000,
              reconnectionDelayMax : 5000,
              reconnectionAttempts: Infinity
            }) // atamuhiuldin connected
            global.socket.on("connect", () => {
                onConnection();

                global.socket.on('connect_error', (error) => {
                  console.log('CONNECT: ERROR');
                    onDisconnect()
                    global.socket.connect();
                    this.socketConnected = false;
                    console.log(error);
                  });
            
                  global.socket.on('connect_timeout', (timeout) => {
                    console.log('CONNECT: TIMEOUT');
                    onDisconnect()
                    global.socket.connect();
                    console.log(timeout);
                    this.socketConnected = false;
                  });
            
                  global.socket.on('disconnect', (reason) => {
                    console.log(`Disconnected: ${reason}`)
                    onDisconnect()
                    this.socketConnected = false;
                    if (reason === 'io server disconnect') {
                      // the disconnection was initiated by the server, you need to reconnect manually
                      console.log("Requesting Connection...")
                      global.socket.connect();
                    }
                    // else the socket will automatically try to reconnect
                  });
            
                  global.socket.on('reconnect', (attemptNumber) => {
                    console.log('SOCKET: RECONNECTED');
                    console.log(attemptNumber);
                    this.socketConnected = false;
                  });
            
                  global.socket.on('reconnect_attempt', (attemptNumber) => {
                    console.log('RECONNECT ATTEMPTS');
                    this.socketConnected = false;
                    console.log(attemptNumber);
                  });
            
                  global.socket.on('reconnecting', (attemptNumber) => {
                    console.log('RECONNECTING...');
                    this.socketConnected = false;
                  });
            
                  global.socket.on('reconnect_error', (error) => {
                    onDisconnect()
                    console.log('RECONNECT ERROR');
                    this.socketConnected = false;
                  });
            })
        }

        this.emit = (type, payload ,_ack = (ack) => false) => {
           if(this.socketConnected && this.socket){
             global.socket.emit(type, payload ,_ack)
           }else{
             console.log(`System offline :SocketController`)
           }
        }

        this.destroy = () => {
          if(global.socket){
            global.socket.disconnect();
            global.socket = null;
          }
        }
        
    }   
}
const MySocketController = new SocketController();
export default MySocketController;
