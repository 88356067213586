import * as Yup from "yup";

export const FormValidationSchema = Yup.object().shape({
  VIN: Yup.string()
    .min(8, "Must be more than 8 character")
    .required("*VIN required."),
  price: Yup.number()
    .min(100, "Greater than $100")
    .max(500, "less than 500$")
    .required("*Price required"),
  KmHours: Yup.number()
    .min(100, "Must greater than $100")
    .max(500, "Must Less than $500")
    .required("*KmHours required"),
  Model: Yup.string()
    .min(4, "Must greater than 4")
    .max(9, "Must less than 9")
    .required("*Modal required"),
  year: Yup.string().required("Please Select option"),
  fuel: Yup.string().required("Please Select Option"),
  PowerFeature: Yup.string().min(3, "Select Max 3").max(5, "Select Min 5"),
});

export const VINSchemaValidation = Yup.object().shape({
  VIN: Yup.string()
    // .min(16, "*Must be  16 character")
    // .max(16, "*Must be equal 16 character")
    .required("*VIN required."),
});
export const UpdateVehicleFormValidationSchema = Yup.object().shape({
  // VIN:Yup.string()
  // .required("*VIN required."),
  // .min(16, "*Must be  16 character")
  // .max(16, "*Must be equal 16 character")
  title: Yup.string()
    .min(8, "*Title Must be more than 8 character")
    .required("*Title required."),
  priceMinRange: Yup.number()
    .min(0, "*Greater than $0")
    .required("*Price required"),
  priceMaxRange: Yup.number().optional(),
  year: Yup.number().optional(),
  modal: Yup.string().optional(),
  make: Yup.string().optional(),
  fuleType: Yup.string().optional(),
  trimLevel: Yup.string().optional(),
  interior: Yup.string().optional(),
  exterior: Yup.string().optional(),
  transmission: Yup.string().optional(),
  engine: Yup.string().optional(),
  mileageHighway: Yup.number().optional(),
  mileageCity: Yup.number().optional(),
  madeIn: Yup.string().optional(),
  description: Yup.string().optional(),
  KMDriven: Yup.number().optional(),
});

export const AddVehicleFormValidationSchema = Yup.object().shape({
  title: Yup.string()
    .min(8, "*Title Must be more than 8 character")
    .required("*Title required."),
  priceMinRange: Yup.number()
    .min(0, "*Greater than $0")
    .required("*Price required"),
  year: Yup.number().required(`year required`),
  modal: Yup.string().required(`modal required`),
  make: Yup.string().required(`make required`),
  fuleType: Yup.string().required(`fuel required`),
  trimLevel: Yup.string().optional(),
  interior: Yup.string().optional(),
  exterior: Yup.string().optional(),
  transmission: Yup.string().optional(),
  engine: Yup.string().optional(),
  mileageHighway: Yup.number().optional(),
  mileageCity: Yup.number().optional(),
  madeIn: Yup.string().optional(),
  description: Yup.string().optional(),
  KMDriven: Yup.number().optional(),
});
//    Login User Schema
export const LogInSignIN = Yup.object().shape({
  email: Yup.string().email().required("*Email required."),
  password: Yup.string()
    .required("*Password required.")
    .min(8, "Password is too short - should be 8 chars minimum."),
  // .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
});

//    Sign Up User Schema validation
export const SignUpValidation = Yup.object().shape({
  fullName: Yup.string().required("*Full Name required"),
  email: Yup.string().email().required("*Email required"),
  phoneNumber: Yup.string().required("*Phone Number required"),
  password: Yup.string()
    .required("*Password required")
    .min(8, "Password is too short - should be 8 chars minimum."),
  // .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
  confirmPassword: Yup.string()
    .required("*Confirm password required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
  // .min(8, "Password is too short - should be 8 chars minimum."),
  location: Yup.object().required("Location required"),
});

///     Forget Password Modal Schema
export const ForgetPasswordEmailValidation = Yup.object().shape({
  email: Yup.string().email().required("Required"),
});

export const OTPValidation = Yup.object().shape({
  otp: Yup.string()
    .required("*OTP required.")
    .min(6, "Password is too short - should be 8 chars minimum."),
});

export const ForgetCreateNewPassword = Yup.object().shape({
  password: Yup.string()
    .required("*Password required.")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
  confirmPassword: Yup.string()
    .required("*Conform password required.")
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .min(8, "Password is too short - should be 8 chars minimum."),
});
