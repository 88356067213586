import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import AuthController from "./authController";
import { ERRORS } from "./errorMessages";
import ReduxDispatchController from "./reduxDispatchController";
import store from "../Store";
import ToastController from "./toastController";
class VehiclesController {
  static ADAPTER = {
    formatVehicle: (vehicle) => {
      return {
        action: "",
        payload: { ...vehicle },
        offers: [],
        vehiclePicture: getFirstImage(vehicle.pictures).avatar.url,
        vehicleName: vehicle.title,
        priceRange: `$${vehicle?.priceRange?.min}`,
        age: vehicle.year
          ? `${new Date().getFullYear() - vehicle.year} years`
          : "--",
      };
    },
    formatOffer: (offer) => {
      // console.log(offer?.buyer?.fullName, "Offer Buyer")
      return {
        clientPicture: offer?.buyer?.profilePicture?.avatar?.url,
        buyerName: offer?.buyer?.fullName,
        vehicleName: offer.vehicle.title,
        offer: `$${offer.amount}`,
        age: "1 day",
        action: offer.status,
        payload: offer,
      };
    },
  };

  static getVehicles(qryObj) {
    return new Promise((resolve, reject) => {
      const currentUser = AuthController.getCurrentUser();

      axios({
        url: `/vehicle/business/get-vehicle-by-business/${currentUser._id}`,
        params: qryObj,
        method: "get",
      })
        .then((res) => {
          // console.log("Data By PageNo....",res.data);
          if (res.data.success) {
            const { vehicles, noOfPages } = res.data.data;
            console.log("@Vehicles.....", res.data.data);
            ReduxDispatchController.VEHICLES.setAllVehicles({
              noOfPages: noOfPages,
              data: vehicles.map((vehicle, index) => ({
                action: "",
                payload: vehicle,
                index,
                offers: [],
                vehiclePicture: getFirstImage(vehicle.pictures).avatar.url,
                vehicleName: vehicle.title,
                priceRange: `$${vehicle?.priceRange?.min}`,
                // age: vehicle.year
                //   ? `${new Date().getFullYear() - vehicle.year} years`
                //   : "--",
                age: new Date(vehicle?.createdAt).toDateString().slice(4),
              })),
            });
            resolve(res.data.data);
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          reject(ERRORS.NETWORK_ERROR);
        });
    });
  }
  static loadVehicles() {
    const vehiclesLength = store.getState().VehiclesReducer.vehicles.length;
    // console.log(`T0: pass`)
    if (vehiclesLength === 0) {
      ReduxDispatchController.VEHICLES.setLoading(true);
      // console.log(`T2: pass`)
      VehiclesController.getVehicles()
        .then((vehicles) => {
          console.log(`T3: pass => vehicles`, vehicles);
          ReduxDispatchController.VEHICLES.setAllVehicles(
            vehicles.map((vehicle) => ({
              action: "",
              payload: vehicle,
              offers: [],
              vehiclePicture: getFirstImage(vehicle.pictures).avatar.url,
              vehicleName: vehicle.title,
              priceRange: `$${vehicle?.priceRange?.min}`,
              age: vehicle.year
                ? `${new Date().getFullYear() - vehicle.year} years`
                : "--",
            }))
          );
          // console.log(`T4: pass`)
          ReduxDispatchController.VEHICLES.setLoading(false);
        })
        .catch((err) => {
          // console.log(`T0: fail`)
          ReduxDispatchController.VEHICLES.setLoading(false);
          console.log(err);
        });
    }
  }

  static getOffers(vehicleId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/offer/offer/get-offers-by-vehicle/${vehicleId}`)
        .then((res) => {
          if (res.data.success) {
            resolve(res.data.data);
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          reject(ERRORS.NETWORK_ERROR);
        });
    });
  }

  static loadOffers(vehicleId) {
    ReduxDispatchController.VEHICLES.OFFERS.setLoading(true);
    ReduxDispatchController.ACTIONS.startProcessing();
    ReduxDispatchController.ACTIONS.setProgress(60);

    VehiclesController.getOffers(vehicleId)
      .then((offers) => {
        ReduxDispatchController.ACTIONS.setProgress(80);
        ReduxDispatchController.VEHICLES.OFFERS.set(
          vehicleId,
          offers.map((offer) => VehiclesController.ADAPTER.formatOffer(offer))
        );
        ReduxDispatchController.VEHICLES.OFFERS.setLoading(false);
        ReduxDispatchController.ACTIONS.endProcessing();
      })
      .catch((err) => {
        console.log(err);
        ReduxDispatchController.VEHICLES.OFFERS.setLoading(false);
        ReduxDispatchController.ACTIONS.endProcessing();
      });
  }

  static createVehicle(images, vehicleData) {
    return new Promise((resolve, reject) => {
      let toastController = new ToastController();
      toastController.showProcessing(`Creating vehicle...`);
      const currentUser = AuthController.getCurrentUser();

      const formData = new FormData();
      for (let index = 0; index < images.length; index++) {
        const singleImage = images[index];
        if (singleImage.source) {
          formData.append("image", singleImage.source);
        }
      }

      for (const key in vehicleData) {
        if (!!vehicleData[key]) {
          formData.append(key, vehicleData[key]);
        }
      }
      formData.append("enabledStatus", 1);

      console.log(`Form Data`);
      console.log(formData);

      toastController.setProcessingMessage(`Uploading images...`);
      axios
        .post(`/vehicle/business/create/${currentUser._id}`, formData)
        .then((res) => {
          console.log(res.data);
          if (res.data.success) {
            ReduxDispatchController.VEHICLES.pushVehicle(
              VehiclesController.ADAPTER.formatVehicle(res.data.data)
            );
            toastController.endProcessingWithSuccess(
              `Vehicle created successfully.`
            );
            resolve(res.data.data);
          } else {
            toastController.endProcessingWithError(res.data.error.message);
            console.log(`Vehicle Not Created.!`);
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          toastController.endProcessingWithError(
            `Please check your internet connection.`
          );
          console.log(`CATCH Error in creating vehicle`);
          console.log(err);
          reject(`Please check your internet connection.`);
        });
    });
  }

  static updateVehicleData(
    vehicleId,
    {
      title,
      priceMinRange,
      priceMaxRange,
      driveTrain,
      interior,
      kmsDriven,
      exterior,
      transmission,
      engine,
      fuleType,
      milageCity,
      mileageHighway,
      description,
      VIN,
      year,
      modal,
      make,
      trimLevel,
      style,
      madeIn,
      driveType,
      enabledStatus,
      knownIssues,
    },
    imagesToUpload,
    imagesToDelete
  ) {
    return new Promise((resolve, reject) => {
      let toastController = new ToastController();
      console.log({ imagesToUpload, imagesToDelete }, "@images data......");
      toastController.showProcessing(`Updating vehicle...`);
      let data = {
        title: title,
        priceRange: {
          min: priceMinRange,
          //   max: priceMaxRange,
        },
        driveTrain: driveTrain,
        interior: interior,
        exterior: exterior,
        transmission: transmission,
        engine: engine,
        fuleType: fuleType,
        mileageCity: milageCity,
        mileageHighway: mileageHighway,
        description: description,
        VIN: VIN,
        year: year,
        modal: modal,
        make: make,
        trimLevel: trimLevel,
        KMDriven: kmsDriven,
        style: style,
        madeIn: madeIn, ///TODO: maedeIn
        driveType: driveType,
        enabledStatus: enabledStatus,
        knownIssues: knownIssues,
      };
      axios
        .post(`/vehicle/business/update/${vehicleId}`, data)
        .then((res) => {
          console.log(res.data);
          if (res.data.success) {
            VehiclesController.updateVehicleImages(
              vehicleId,
              imagesToUpload,
              imagesToDelete
            )
              .then((response) => {
                console.log("images updated", response);
                ReduxDispatchController.VEHICLES.updateVehicle(
                  VehiclesController.ADAPTER.formatVehicle(res.data.data)
                );
                toastController.endProcessingWithSuccess(
                  `Vehicle updated successfully.`
                );
                resolve(res.data.data);
              })
              .catch((err) => {
                toastController.endProcessingWithError(
                  `Error in uploading imgs...!`
                );
                reject(err);
              });

            // })
          } else {
            toastController.endProcessingWithError(res.data.error.message);
            console.log(`Vehicle Not updated.!`);
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          toastController.endProcessingWithError(
            `Please check your internet connection.`
          );
          console.log(`CATCH Error in updating vehicle`);
          console.log(err);
          reject(`Please check your internet connection.`);
        });
    });
  }

  static updateVehicle(vehicleId, imagesToUpload, imagesToDelete) {
    return new Promise((resolve, reject) => {
      VehiclesController.updateVehicleImages(
        vehicleId,
        imagesToUpload,
        imagesToDelete
      );
    });
  }
  static acceptOffer(offerId, status) {
    return new Promise((resolve, reject) => {
      const toast = new ToastController();
      const reqData = {
        offerStartedDate: "2002-12-09",
        status: status,
      };
      console.log({ offerId, status, reqData }, "@offer req data..........");

      // axios.patch(`/offer/offer/update-status/${offerId}`, reqData)
      axios
        .post(`/offer/offer/accept-reject-counter-offer/${offerId}`, reqData)
        .then((res) => {
          console.log(res, "@offer accept res............");
          if (res.data.success) {
            // console.log("RESP ----> ", res.data.data.status);
            // console.log("OffId ----> ", offerId);
            ReduxDispatchController.VEHICLES.OFFERS.accept(
              res.data.data.vehicle._id,
              offerId,
              status
            );
            ToastController.success(`offer successfully ${status}...!`);
            resolve(true);
          } else {
            ToastController.error(res.data.error.message);
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(ERRORS.NETWORK_ERROR);
        });
    });
  }

  static getConversation(offerId) {
    return new Promise((resolve, reject) => {
      console.log(`Loading offers with id:${offerId}`);
      const currentUserId = AuthController.getCurrentUser()._id;
      console.log(`Loading offers with id:${offerId}/${currentUserId}`);
      axios
        .get(
          `/chat/message/get-conversation-by-business/${offerId}/${currentUserId}`
        )
        .then((res) => {
          console.log(`Conv. loaded..`);
          if (res.data.success) {
            console.log(`Conv. success`);
            ReduxDispatchController.CONVERSATION.set(res.data.data);
            resolve(res.data.data);
          } else {
            console.log(`Conv. fail`);
            console.log(res.data);
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          console.log(`CATCH Error in getting conversations`);
          console.log(err);
          reject(ERRORS.NETWORK_ERROR);
        });
    });
  }

  static handleLoadConversations(offerId) {
    ReduxDispatchController.CONVERSATION.setLoading(true);
    VehiclesController.getConversation(offerId)
      .then((conversation) => {
        ReduxDispatchController.CONVERSATION.setLoading(false);
      })
      .catch((err) => {
        ReduxDispatchController.CONVERSATION.setLoading(false);
      });
  }

  static sendMessage(offerId, message) {
    return new Promise((resolve, reject) => {
      const data = {
        body: message.body,
        sentTime: new Date(),
        senderType: "business",
      };

      axios
        .post(`/chat/message/send/${offerId}`, data)
        .then((res) => {
          if (res.data.success) {
            resolve(res.data.data);
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          reject(ERRORS.NETWORK_ERROR);
        });
    });
  }
  static markSeen(offerId) {
    const data = {
      lastSeenType: "business",
    };
    axios
      .post(`/chat/message/mark-last-seen/${offerId}`, data)
      .then((res) => {
        if (res.data.success) {
          console.log(`successfully marked seen ${offerId}`);
        } else {
          console.log(res.data.error.message);
        }
      })
      .catch((err) => {
        console.log(`Error in marking seen`, err);
      });
  }
  static decodeVIN(vin) {
    return new Promise((resolve, reject) => {
      const config = {
        headers: {
          "x-rapidapi-host": "vindecoder.p.rapidapi.com",
          "x-rapidapi-key":
            "169d0c1d77msh8f0db0244f4a745p154a29jsnd5552bba32e6",
        },
      };
      axios
        .get(
          `https://vindecoder.p.rapidapi.com/v2.0/decode_vin?vin=${vin}`,
          config
        )
        .then((res) => {
          if (res.data.success) {
            const s = res.data.specification;
            resolve({
              VIN: vin,
              title: `${s.year} ${s.make} ${s.model}`,
              priceMinRange: "",
              priceMaxRange: "",
              year: s.year,
              modal: s.model,
              make: s.make,
              fuleType: s.fuel_type,
              kmsDriven: "",
              trim: s.trim_level,
              interior: "interior",
              exterior: "exterior",
              transmission: s.transmission,
              engine: s.engine,
              mileageHighway: s.highway_mileage
                ? parseInt(s.highway_mileage.replace(" miles/gallon", ""))
                : "",
              mileageCity: s.city_mileage
                ? parseInt(s.city_mileage.replace(" miles/gallon", ""))
                : "",
              madeIn: s.made_in,
            });
          } else {
            console.log(`Unable to load vin.`);
            console.log(res.data);
            reject(`Unable to decode vin`);
          }
        })
        .catch((err) => {
          console.log(`CATCH Error, getting vin.`);
          console.log(JSON.stringify(err));
          if (err.status == 422) {
            reject(`Unable to decode vin`);
          } else {
            reject(ERRORS.NETWORK_ERROR);
          }
        });
    });
  }

  static changeVehicleStatus(id, status) {
    return new Promise((resolve, reject) => {
      let data = {
        _id: id,
        enabledStatus: status,
      };
      axios
        .post(`/vehicle/business/change-vehicle-status`, data)
        .then((res) => {
          if (res.data.success) {
            ReduxDispatchController.VEHICLES.updateEnabledStatus(id, status);
            resolve(res.data?.data?.enabledStatus);
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          reject(ERRORS.NETWORK_ERROR);
        });
    });
  }

  static pushVehicleImages(vehicleId, images) {
    return new Promise((resolve, reject) => {
      const data = new FormData();
      for (let index = 0; index < images.length; index++) {
        const singleImage = images[index];
        data.append("image", singleImage.source);
      }
      axios
        .post(`/vehicle/business/update-images/${vehicleId}`, data)
        .then((res) => {
          if (res.data.success) {
            resolve(res.data.data.enabledStatus);
          } else {
            console.log(res.data.error.message, "@images err in upload images");

            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          console.log(err, "@images err in upload images");
          reject(ERRORS.NETWORK_ERROR);
        });
    });
  }

  // static changeVehicleStatus(data) {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .post(`/vehicle/business/change-vehicle-status`, data)
  //       .then((res) => {
  //         if (res.data.success) {
  //           console.log("@vehicle status change resonse....", res.data);
  //           resolve(true);
  //         } else {
  //           console.log("@vehicle status change err....", res.data);
  //           reject(res.data.error.message);
  //         }
  //       })
  //       .catch((err) => {
  //         reject(ERRORS.NETWORK_ERROR);
  //       });
  //   });
  // }

  static deleteVehicleImages(vehicleId, images) {
    return new Promise((resolve, reject) => {
      const pictures = [];
      for (let index = 0; index < images.length; index++) {
        const singleImage = images[index];
        pictures.push({ avatar: singleImage.avatar, image: singleImage.image });
      }
      axios
        .post(`/vehicle/business/delete-images/${vehicleId}`, {
          pictures,
        })
        .then((res) => {
          if (res.data.success) {
            resolve(res.data);
          } else {
            console.log(res.data.error.message, "@images err in delete images");
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          console.log(err, "@images err in delete images");
          reject(ERRORS.NETWORK_ERROR);
        });
    });
  }

  static safeDeleteVehicle = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/vehicle/business/${id}`)
        .then((res) => {
          console.log("@safe delete response....", res);
          if (res.data.success) {
            resolve(true);
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          reject(ERRORS.NETWORK_ERROR);
        });
    });
  };

  static updateVehicleImages(
    vehicleId,
    imagesToUpload = [],
    imagesToDelete = []
  ) {
    return new Promise((resolve, reject) => {
      console.log(
        { imagesToUpload, imagesToDelete },
        "@images in update vehicle images"
      );

      let promisesArray = [];

      if (imagesToUpload.length > 0) {
        promisesArray.push(
          VehiclesController.pushVehicleImages(vehicleId, imagesToUpload)
        );
      }

      if (imagesToDelete.length > 0) {
        promisesArray.push(
          VehiclesController.deleteVehicleImages(vehicleId, imagesToDelete)
        );
      }

      Promise.all(promisesArray)
        .then((values) => {
          console.log(values, "@images promise all");
          VehiclesController.loadVehicles();
          resolve(true);
        })
        .catch((err) => {
          console.log(`@images error CATCH error in updating images`, err);
          // resolve(true);
          reject(err);
        });
    });
  }

  static closeOffer(offerId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/offer/offer/close/${offerId}`)
        .then((res) => {
          if (res.data.success) {
            /// update offer status.
            ReduxDispatchController.VEHICLES.OFFERS.close(
              res.data.data.vehicle._id,
              offerId
            );
            resolve(offerId);
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          reject(ERRORS.NETWORK_ERROR);
        });
    });
  }

  static getAuctions(qryObj = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/auction/search/auction-title`)
        .then((res) => {
          if (res.data.success) {
            /// update offer status.
            // ReduxDispatchController.VEHICLES.OFFERS.close(
            //   res.data.data.vehicle._id,
            //   offerId
            // );
            resolve(res?.data?.data);
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          reject(ERRORS.NETWORK_ERROR);
        });
    });
  }

  static createConflict(offerId, vehicleId, disputeReason) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/offer/offer/dispute/${offerId}`, { disputeReason })
        .then((res) => {
          if (res.data.success) {
            // ReduxDispatchController.OFFERS.createConflict(offerId, disputeReason);
            ReduxDispatchController.VEHICLES.OFFERS.dispute(
              offerId,
              vehicleId,
              disputeReason
            );
            resolve({ offerId, disputeReason });
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          console.log(`CATCH Error in creating conflict`);
          console.log(err);
          reject(ERRORS.NETWORK_ERROR);
        });
    });
  }
}
export default VehiclesController;

export const useVehicles = () => {
  return useSelector((state) => ({
    vehiclesLoading: state.VehiclesReducer.loading,
    vehicles: state.VehiclesReducer.vehicles.data,
  }));
};

export const useSingleVehicle = (vehicleId) => {
  // const {vehicles} =  useSelector(state => ({
  //     vehicles: state.VehiclesReducer.vehicles.data
  // }))
  const { vehicles } = useVehicles();
  // console.log("V data", vehicles)
  let vehicle = null;
  let priceMinRange = "";
  let priceMaxRange = "";
  let vehicleIndex = vehicles?.findIndex((v) => v.payload._id === vehicleId);

  if (vehicleIndex >= 0) {
    vehicle = vehicles[vehicleIndex];
    priceMaxRange = vehicle.payload.priceRange.max;
    priceMinRange = vehicle.payload.priceRange.min;
  }
  return { ...vehicle.payload, priceMinRange, priceMaxRange };
};

export const useVehicleWithOffers = (vehicleId) => {
  console.log("VId", vehicleId);
  const { vehicles, loading } = useVehicles();
  useEffect(() => {
    VehiclesController.loadOffers(vehicleId);
  }, [vehicles]);
  // const {vehicles, loading} =  useSelector(state => ({
  //             vehicles: state.VehiclesReducer.vehicles,
  //             loading: state.VehiclesReducer.loadingOffers
  //         }))

  let vehicle = null;
  let vehicleIndex = vehicles?.findIndex((v) => v.payload._id === vehicleId);

  if (vehicleIndex >= 0) {
    vehicle = vehicles[vehicleIndex];
    // console.log("Offer No........", vehicle.offers)
  }
  return { loading, vehicle: vehicle?.payload, offers: vehicle?.offers };
};

const getConversationIndexes = (vehicles, vehicleId, offerId) => {
  console.log(`${vehicleId}/${offerId}`);
  const res = { success: false, vehicleIndex: -1, offerIndex: -1 };
  const vehicleIndex = vehicles.findIndex(
    (vehicle) => vehicle.payload._id === vehicleId
  );
  if (vehicleIndex >= 0) {
    console.log(`Vehicle found at${vehicleIndex}`, vehicles[vehicleIndex]);
    const offerIndex = vehicles[vehicleIndex].offers.findIndex(
      (offer) => offer.payload._id === offerId
    );
    if (offerIndex >= 0) {
      res.success = true;
      res.vehicleIndex = vehicleIndex;
      res.offerIndex = offerIndex;
    } else {
      console.log(`Invalid offer ${offerId}`);
    }
  } else {
    console.log(`Invalid vehicle ${vehicleIndex}`);
  }
  return res;
};

export const useConversation = (vehicleId, offerId) => {
  useEffect(() => {
    VehiclesController.handleLoadConversations(offerId);
  }, []);

  // const {vehicles, loading} =  useSelector(state => ({
  //     vehicles: state.VehiclesReducer.vehicles.data,
  //     loading: state.VehiclesReducer.loadingConversation
  // }))
  const { vehicles, loading } = useVehicles();

  let conversation = null;
  let vehicle = null;
  let offer = null;
  const { success, vehicleIndex, offerIndex } = getConversationIndexes(
    vehicles,
    vehicleId,
    offerId
  );
  // console.log(`vindex:${vehicleIndex}, ofIndex:${offerIndex}`)
  if (success) {
    vehicle = vehicles[vehicleIndex].payload;
    conversation = vehicles[vehicleIndex].offers[offerIndex].conversation;
    offer = vehicles[vehicleIndex].offers[offerIndex].payload;
  }
  return { conversation, vehicle, offer, loading };
};

export const getFirstImage = (images) => {
  if (images.length > 0) {
    return images[0];
  } else {
    return {
      image: {
        url: undefined,
        key: undefined,
      },
      avatar: {
        url: undefined,
        key: undefined,
      },
    };
  }
};

export const useGetVehiclesByPage = (qryParamsObj) => {
  const { vehicles } = useSelector((state) => state.VehiclesReducer);
  // console.log(vehicles,"Data.....")

  const { pageNumber, auctionId } = qryParamsObj;
  const [loading, setLoading] = useState(true);
  let isLoaded = vehicles.length > 0;

  useEffect(() => {
    if (!isLoaded) {
      setLoading(true);
      VehiclesController.getVehicles(qryParamsObj)
        .then((res) => {
          console.log(res, "@vehicle res..!");
          if (res) {
            setLoading(false);
          }
        })
        .catch((e) => {
          console.log(e, "@vehicle error..!");

          setLoading(false);
        });
    } else {
      console.log("@vehicle error..!");

      setLoading(false);
    }
  }, [pageNumber, auctionId]);

  return { loading, vehicles: vehicles.data, noOfPages: vehicles.noOfPages };
};

export const useGetAuctions = () => {
  const [loading, setLoading] = useState(false);
  const [auctions, setAuctions] = useState([]);

  const handleGetAuctions = async () => {
    try {
      setLoading(true);
      const data = await VehiclesController.getAuctions();
      setAuctions(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetAuctions();
  }, []);

  return {
    loading,
    auctions,
  };
};
