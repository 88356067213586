import axios from 'axios';
import AuthController from '../../Controllers/authController';


class VehiclesController{
    static createSingleVehicle(vehicle, onProgress){
        return new Promise((resolve, reject) => {
            console.log("---VEHICLE", vehicle)
            // resolve(true)
            // reject("Network error")

            axios({
                method:"post",
                url:"/vehicle/business/v2/create", 
                data:vehicle,
                headers: {"api-key": "ABX838437483478374sERWERWwerSDDDF1231231sdfAAGU"}
            })
                .then(res => {
                    console.log(res.data)
                    if(res.data.success){
                        onProgress()
                        console.log("+VEHICLE", res.data)
                        resolve(res.data);
                    }else{
                        reject(res.data.error.message)
                    }
                }).catch(err => {
                    console.log("CATCH Error in creating vehicle")
                    console.log(err);
                    reject(`Error at creating ${vehicle.title}`)
                })
        })
    }


    static isValidVehicle(v){
        let isValid = false;
        const isNumber = (n) => {
            return !isNaN(parseFloat(n));
        }
        if(v.title && v.VIN && v.driveTrain && v.interior 
            && v.exterior && v.transmission && v.engine 
            && v.fuleType && v.description && v.modal && v.make && v.trimLevel
            && v.style && v.madeIn && v.driveType && v.image && v.avatar ){
                if(
                    isNumber(v.priceMinRange) 
                    && isNumber(v.priceMaxRange)
                    && isNumber(v.priceMaxRange)
                    && isNumber(v.mileageCity)
                    && isNumber(v.mileageHighway)
                    && isNumber(v.year)
                    && isNumber(v.KMDriven)
                    && isNumber(v.longitude)
                    && isNumber(v.latitude)
                    ){
                        isValid = true;
                    }
            }
            return isValid;
    }

    
    static transform(v){
       return {
            "vehicleType": "",
                "title":v.title,
                "priceMinRange": parseFloat(v.priceMinRange?v.priceMinRange: 0),
                "priceMaxRange": parseFloat(v.priceMaxRange?v.priceMaxRange: 0),
                "VIN":v.VIN,
                "driveTrain":v.driveTrain,
                "interior":v.interior,
                "exterior":v.exterior,
                "transmission":v.transmission,
                "engine":v.engine,
                "fuleType":v.fuleType,
                "mileageCity": parseFloat(v.mileageCity?v.mileageCity: 0),
                "mileageHighway":parseFloat(v.mileageHighway?v.mileageHighway: 0),
                "description":v.description,
                "year":parseInt(v.year?v.year: 0),
                "modal":v.modal,
                "make":v.make,
                "trimLevel":v.trimLevel,
                "style":v.style,
                "madeIn":v.madeIn,
                "driveType":v.driveType,
                "enabledStatus": parseInt(v.enabledStatus?v.enabledStatus: 0),
                "KMDriven": parseFloat(v.KMDriven?v.KMDriven: 0),
                "pictures":[{
                    avatar: {
                        key: "none",
                        url: v.image
                    },
                    image: {
                        key: "none",
                        url: v.avatar
                    }
                }],
                "businessId": AuthController.getCurrentUser()._id,
                "longitude":parseFloat(v.longitude?v.longitude: 34.0522),
                "latitude":parseFloat(v.latitude?v.latitude: 118.2437),
        }
    }
    
    static createVehicles(vehicles, _progressCallback = (seek_, peak_) => false ){
        return new Promise((resolve, reject) => {
            let peak = vehicles.length;
            let seek = 0;
            const onProgress = () => {
                seek++;
                _progressCallback(seek, peak);
            }

            const vehiclePromises = vehicles.map(singleVehicle => VehiclesController.createSingleVehicle(singleVehicle, onProgress))
            Promise.all(vehiclePromises)
                .then(createdVehicles => {
                    console.log("Vehicles Successfully Created!")
                    resolve(createdVehicles)
                }).catch(err => {
                    console.log("CATCH Error in creating vehicles.")
                    reject(err);
                })
        })
    }
}
export default VehiclesController;