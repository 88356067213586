import React, { useEffect } from "react";
import "./CustomPagination.css";
export default function CustomPagination({
  noOfPages,
  onPageChange,
  activePageNo,
  pageNoArray,
  setPageNoArray,
}) {
  useEffect(() => {
    updatePaginationState();
  }, [activePageNo]);
  let updatePaginationState = () => {
    let pageNo = activePageNo > 4 ? activePageNo - 4 : 0;
    let pageNoEnd = pageNo + 5;
    pageNoEnd = pageNoEnd > noOfPages ? noOfPages : pageNoEnd;
    let pageNoStart = pageNoEnd - 5;
    pageNoStart = pageNoStart < 0 ? 0 : pageNoStart;
    let _pageNoArray = [...Array(pageNoEnd - pageNoStart)].map(
      (item, index) => {
        return pageNoStart + index;
      }
    );
    if (activePageNo >= 4 && activePageNo < noOfPages - 1) {
      _pageNoArray.splice(_pageNoArray.length, 0, activePageNo + 1);
    }
    setPageNoArray([..._pageNoArray]);
  };
  // console.log("actvePageNo +++>", activePageNo, pageNoArray)
  return (
    <div className="bg-white d-flex flex-row pagination-container">
      {noOfPages > 1 && (
        <>
          {activePageNo > 1 && (
            <span
              onClick={() => onPageChange(activePageNo - 1)}
              className="prevPageIcon"
            >
              &lt;&lt;
            </span>
          )}
          {pageNoArray[pageNoArray.length - 1] >= 6 && (
            <>
              <span
                onClick={() => onPageChange(1)}
                className={`${
                  activePageNo === 1 && "active text-white"
                } rounded-circle pagination-item`}
              >
                1
              </span>
              <span className="mt-2">...</span>
            </>
          )}
          {pageNoArray.map((_, idx) => {
            return (
              <span
                onClick={() => onPageChange(_ + 1)}
                className={`${
                  _ + 1 === parseInt(activePageNo) && "active text-white"
                } rounded-circle pagination-item`}
                key={idx}
              >
                {_ + 1}
              </span>
            );
          })}
          {noOfPages > 5 && activePageNo < noOfPages - 2 && (
            <>
              {activePageNo !== noOfPages - 3 && (
                <span className="mt-2">...</span>
              )}
              <span
                onClick={() => onPageChange(noOfPages)}
                className={`${
                  noOfPages - 1 === activePageNo && "active text-white"
                } rounded-circle pagination-item`}
              >
                {noOfPages}
              </span>
            </>
          )}
          {activePageNo < noOfPages && (
            <span
              onClick={() => onPageChange(activePageNo + 1)}
              className="nextPageIcon"
            >
              &gt;&gt;
            </span>
          )}
        </>
      )}
    </div>
  );
}
