import React from "react";
import MySpinner from "../../AbstractsComponents/mySpinner";
//     importing css files
import "./offerButtons.css";
export const MessageButton = (props) => {
  return (
    <button
      onClick={props.onClick}
      className="message-button inline-flex justify-center items-center min-h-[40px]"
    >
      {props.isUnread ? <span className="message-button-badge"></span> : ""}
      {!props.processing ? (
        <>
          {props.children}
          {props.RightIcon ? (
            <img
              src={props.RightIcon}
              className="button-icon right-icon"
              alt=""
              style={{ margin: "auto 10px", width: "18px", height: "18px" }}
            />
          ) : (
            ""
          )}
        </>
      ) : (
        <MySpinner color={"#2674e6"} style={{ marginRight: 3 }} />
      )}
    </button>
  );
};
