import AuthController from './authController';
import VehiclesController from './vehiclesController';
import ReduxDispatchController from './reduxDispatchController';
class MessageController{
    static MESSAGE_STATUS = {
        SENDING: 1, 
        SENT: 2, 
        FAILD: 3
    }
   
    static onNewMessage({vehicleId, offerId, message}){
        ReduxDispatchController.CONVERSATION.pushMessage(vehicleId ,offerId ,message)
    }

    constructor(messageBody,vehicleId ,offerId ,buyerId, messageId = "") {
        this.currentUser = AuthController.getCurrentUser();
        this.offerId = offerId;
        this.buyerId = buyerId;
        this.vehicleId = vehicleId;
        this.messageId = messageId? messageId: `${Math.random()*100000*Math.random()}-${Math.random()*100000*Math.random()}`
        this.messageObject = {
            _id: this.messageId, 
            buyer: buyerId,
            business: this.currentUser._id, 
            body: messageBody, 
            sentTime: new Date(), 
            status: MessageController.MESSAGE_STATUS.SENDING,
        }
    }
    send(_callback = () => false){
        /// send to reducer.
        console.log(`T0: Sending message locally`)
        ReduxDispatchController.CONVERSATION.pushMessage(this.vehicleId ,this.offerId ,this.messageObject)
        //// send to database
        console.log(`T1: Sending message server`)
        VehiclesController.sendMessage(this.offerId, this.messageObject)
            .then(message => {
                ///  then > update in reducer.
                console.log(`T2: sent`)
                ReduxDispatchController.CONVERSATION.updateMessage(this.vehicleId, this.offerId, this.messageId, {...message, status: MessageController.MESSAGE_STATUS.SENT});
                console.log(`T3: updated`)
                _callback()
            }).catch(err => {
                console.log("CATCH Error in sendin message", err);
                console.log(err)
                ReduxDispatchController.CONVERSATION.updateMessage(this.vehicleId, this.offerId, this.messageId, {...this.messageObject, status: MessageController.MESSAGE_STATUS.FAILD});
                console.log(`T2: fail`)
                _callback()
            })
    }
    reSend(){
        ReduxDispatchController.CONVERSATION.updateMessage(this.vehicleId ,this.offerId, this.messageId, {...this.messageObject, status: MessageController.MESSAGE_STATUS.SENDING});
        //// send to database
        VehiclesController.sendMessage(this.offerId, this.messageObject)
            .then(message => {
                ///  then > update in reducer.
                ReduxDispatchController.CONVERSATION.updateMessage(this.vehicleId ,this.offerId, this.messageId, {...message, status: MessageController.MESSAGE_STATUS.SENT});
            }).catch(err => {
                ReduxDispatchController.CONVERSATION.updateMessage(this.vehicleId ,this.offerId, this.messageId, {...this.messageObject, status: MessageController.MESSAGE_STATUS.FAILD});
            })
    }
}
export default MessageController;