import React from "react";
//     importing componants
import { useLocation, Outlet, Navigate } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import { SideBar } from "../Components/ModuleComponents/SideBar/sideBar";
import { UserNavBar } from "../Components/ModuleComponents/UserNavBar/userNavBar";
//    importing css files
import "./dashBoardRouting.css";
import { useSelector } from "react-redux";
const ProtectedDashboardWrapper = (props) => {
  const location = useLocation();
  const accessBar = useSelector((state) => state.AuthReducer.accessbarVisible);

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  const currentUser = useSelector((state) => state.AuthReducer.currentUser);
  // console.log(`Current user => `, currentUser.verificationStatus)
  if (currentUser) {
    if (currentUser.verificationStatus === "pending") {
      return <Navigate to={"/approval-pending"} />;
    } else {
      return (
        <div>
          {accessBar && (
            <div className="bg-black relative flex justify-between  text-white text-base text-left px-2 py-1">
              {" "}
              <div className="absolute capitalize  left-1/2 -translate-x-1/2">
                delegate access
              </div>
              <button
                onClick={() => window.location.reload()}
                className="flex capitalize items-center gap-2 ml-auto"
              >
                <div>remove access</div>
                <div className=" w-4 h-4 rounded-full flex items-center  justify-center text-sm  text-white">
                  x
                </div>
              </button>
            </div>
          )}
          <Container fluid className="all-ve" style={{ position: "fixed" }}>
            <Row>
              <Col xl={2} md={2} sm={0} xs={2} className="side-bar-main-div">
                <SideBar currentUser={currentUser} />
              </Col>
              <Col
                xl={10}
                lg={10}
                md={10}
                sm={12}
                xs={12}
                className="dash-board-table-colum-div"
              >
                <UserNavBar currentUser={currentUser} />
                <div
                  className="dashboard-col-table-col-inner-div"
                  style={{
                    position: "relative",
                    height: "calc(100vh - 70px)",
                    overflowY: "overlay",
                  }}
                >
                  <Outlet />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }
  } else {
    console.log(`Pre-Location:`, location);
    return <Navigate to={"/auth/login"} />;
  }
};
export default ProtectedDashboardWrapper;
