import { useSelector } from "react-redux";
import store from "../Store/index";
import {
  ACTIONS,
  AUTH,
  CONVERSATIONS,
  INVOICES,
  IsAuthenticated,
  VEHICLES,
} from "../Store/types";
class ReduxDispatchController {
  static AUTH = {
    setAuthStatus: (isAutenticated) => {
      console.log("function called");
      console.log(isAutenticated);
      console.log("function called");

      store.dispatch({
        type: IsAuthenticated,
        payload: isAutenticated,
      });
    },

    setDelegateBar: (visible) => {
      store.dispatch({
        type: AUTH.SET_DELEGATE_BAR,
        payload: visible,
      });
    },

    setSplashVisible: (visible) => {
      store.dispatch({
        type: AUTH.SET_SPLASH_VISIBLE,
        payload: visible,
      });
    },
    setCurrentUser: (user) => {
      store.dispatch({
        type: AUTH.SET_CURRENT_USER,
        payload: user,
      });
    },
    logout: () => {
      store.dispatch({
        type: AUTH.LOGOUT,
      });
    },
  };
  static VEHICLES = {
    setAllVehicles: (vehicles) => {
      store.dispatch({
        type: VEHICLES.SET_KEY_VALUE,
        payload: { value: vehicles, key: "vehicles" },
      });
    },
    uploadVehicleData: (vehicleId, vehicleData) => {
      store.dispatch({
        type: VEHICLES.UPDATE_DATA,
        payload: { _id: vehicleId, vehicleData },
      });
    },
    setLoading: (loading) => {
      store.dispatch({
        type: VEHICLES.SET_KEY_VALUE,
        payload: { value: loading, key: "loading" },
      });
    },
    pushVehicle: (vehicle) => {
      store.dispatch({
        type: VEHICLES.PUSH_VEHICLE,
        payload: vehicle,
      });
    },
    updateVehicle: (vehicle) => {
      store.dispatch({
        type: VEHICLES.UPDATE,
        payload: vehicle,
      });
    },
    updateEnabledStatus: (vehicleId, enabledStatus) => {
      store.dispatch({
        type: VEHICLES.UPDATE_ENABLED_STATUS,
        payload: { vehicleId, enabledStatus },
      });
    },
    deleteVehicle: (vehicleId) => {
      store.dispatch({
        type: VEHICLES.UPDATE,
        payload: vehicleId,
      });
    },
    OFFERS: {
      setLoading: (isLoading) => {
        store.dispatch({
          type: VEHICLES.SET_KEY_VALUE,
          payload: { value: isLoading, key: "loadingOffers" },
        });
      },
      set: (vehicleId, offers) => {
        store.dispatch({
          type: VEHICLES.SET_OFFERS,
          payload: { vehicleId, offers },
        });
      },
      accept: (vehicleId, offerId, status) => {
        console.log("DISPATCHER----------------", vehicleId, status, offerId);
        store.dispatch({
          type: VEHICLES.ON_OFFER_ACCEPTED,
          payload: { vehicleId, offerId, status },
        });
      },
      close: (vehicleId, offerId) => {
        store.dispatch({
          type: VEHICLES.ON_OFFER_CLOSED,
          payload: { vehicleId, offerId },
        });
      },
      dispute: (offerId, vehicleId, disputeReason) => {
        store.dispatch({
          type: VEHICLES.ON_OFFER_DISPUTE,
          payload: { vehicleId, offerId, disputeReason },
        });
      },
    },
  };

  static ACTIONS = {
    setProgress: (progress) => {
      store.dispatch({
        type: ACTIONS.SET_LOADER_KEY_VALUE,
        payload: { key: "progress", value: progress },
      });
    },
    setProgressVisible: (isVisible) => {
      store.dispatch({
        type: ACTIONS.SET_LOADER_KEY_VALUE,
        payload: { key: "visible", value: isVisible },
      });
    },
    startProcessing: () => {
      ReduxDispatchController.ACTIONS.setProgress(0);
      ReduxDispatchController.ACTIONS.setProgressVisible(true);
    },
    endProcessing: () => {
      ReduxDispatchController.ACTIONS.setProgress(0);
      ReduxDispatchController.ACTIONS.setProgressVisible(false);
    },
  };

  static INVOICES = {
    set: (invoices, payable) => {
      store.dispatch({
        type: INVOICES.SET_ALL,
        payload: { invoices, payable },
      });
    },
    setKeyValue: (key, value) => {
      store.dispatch({
        type: INVOICES.SET_KEY_VALUE,
        payload: { key, value },
      });
    },
    setProcessing: (processing) => {
      ReduxDispatchController.INVOICES.setKeyValue(
        "loadingInvoices",
        processing
      );
    },
  };

  static CONVERSATION = {
    set: (conversation) => {
      store.dispatch({
        type: CONVERSATIONS.SET_VEHICLE_OFFER_CONVERSATION,
        payload: conversation,
      });
    },
    setLoading: (processing) => {
      store.dispatch({
        type: VEHICLES.SET_KEY_VALUE,
        payload: { value: processing, key: "loadingConversation" },
      });
    },
    pushMessage: (vehicleId, offerId, message) => {
      store.dispatch({
        type: CONVERSATIONS.PUSH_MESSAGE,
        payload: { vehicleId, offerId, message },
      });
    },
    updateMessage: (vehicleId, offerId, messageId, message) => {
      store.dispatch({
        type: CONVERSATIONS.UPDATE_MESSAGE,
        payload: { vehicleId, offerId, messageId, message },
      });
    },
  };
}
export default ReduxDispatchController;

export const useLoaderAction = () => {
  return useSelector((state) => state.ActionsReducer.loader);
};
