import React, { useEffect } from "react";
//    importing css files
import { TextFields } from "../../../AbstractsComponents/TextFields/textFields";
import { Buttons } from "../../../AbstractsComponents/Button/button";
import { BackButton } from "../../../AbstractsComponents/BackButton/backButton";
import { Modals } from "../../../AbstractsComponents/Modal/modal";
import { Link } from "react-router-dom";
import { PasswordChangeSuccessModal } from "../../../CompositeComponents/ForgetPasswordModal/passwordChangeSuccess";
import { OTPModal } from "../../../CompositeComponents/ForgetPasswordModal/otpModal";
import { CreateNewPasswordModal } from "../../../CompositeComponents/ForgetPasswordModal/createNewPassword";
import { ForgetPasswordEmailValidation } from "../../../../Validations/validations";
import { useFormik } from "formik";
import Modal from "../../Modal/Modal";

import "./forgetPassword.css";
import { useState } from "react";
import axios from "axios";
import ToastController from "../../../../Controllers/toastController";
export const ForgetPassword = () => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [OTP, setOTP] = useState("");
  const [newPass, setNewPass] = useState(null);
  const [msg, setMsg] = useState({
    success: null,
    error: null,
  });
  const [step, setStep] = useState(0);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  //destructuring pathname from location
  //Javascript split method to get the name of the path in array
  const splitLocation = window.location.href.split("/");
  useEffect(() => {
    // Update the document title using the browser API
    console.log(splitLocation);
  });

  const ForgetPasswordEmailCheck = (values) => {
    console.log("Signin form submit values");
    openModal();
    console.log("Signin form submit values");
  };

  const toast = new ToastController();

  const sendOtp = () => {
    toast.showProcessing("Sending OTP...");
    axios
      .patch(`/business/auth/send-otp-to-forgot-password`, {
        email: values.email,
      })
      .then((res) => {
        if (res.data.success) {
          toast.endProcessingWithSuccess("OTP Sent Successfully!");
          setIsOpen(true);
          setStep(0);
        } else {
          toast.endProcessingWithError(res.data.error.message);
        }
      })
      .catch((err) => {
        toast.endProcessingWithError(err);
      });
  };

  const verifyEmail = () => {
    toast.showProcessing("Verifying Email....");
    axios
      .post(`/business/auth/verify-email`, { otp: OTP })
      .then((res) => {
        if (res.data.success) {
          setStep(1);
          toast.endProcessingWithSuccess("Email Verified!");
        } else {
          toast.endProcessingWithError(res.data.error.message);
        }
      })
      .catch((err) => {
        toast.endProcessingWithError(err);
      });
  };

  const createNewPassword = (password) => {
    toast.showProcessing("Changing new password...");
    axios
      .patch(`/business/auth/forgot-password`, {
        email: values.email,
        password,
      })
      .then((res) => {
        if (res.data.success) {
          setStep(2);
          toast.endProcessingWithSuccess("Password Changed!");
        } else {
          toast.endProcessingWithError(res.data.error.message);
        }
      })
      .catch((err) => {
        toast.endProcessingWithError(err);
      });
  };

  const { handleSubmit, getFieldProps, touched, errors, values, setValues } =
    useFormik({
      initialValues: {
        email: "",
      },
      validationSchema: ForgetPasswordEmailValidation,
      onSubmit: sendOtp,
    });

  // const renderForgotPasswordScreens = () => {
  //   switch (step) {
  //     case 0:
  //       return (
  //         <div className="min-w-[300px] min-h-[200px] bg-white">Hello</div>
  //       );
  //     case 1:
  //       <CreateNewPasswordModal setNewPass={setNewPass} />;
  //     case 2:
  //       <PasswordChangeSuccessModal />;
  //     default:
  //       <OTPModal OTP={OTP} setOTP={setOTP} />;
  //   }
  // };

  return (
    <div className="sign-in-main-div">
      <div className="sign-in-info-div">
        <Link to="/" className="react-router-link">
          <BackButton
            style={{ fontSize: "20px" }}
            imgStyle={{ width: "16px", height: "16px", marginLeft: "-30px" }}
          >
            Back
          </BackButton>
        </Link>
        <div className="forget-password-heading-div">
          <h2>Forget Password</h2>
          <p>Enter you email to receive a code.</p>
        </div>
        <div>
          <TextFields
            // onChange={(e) => setEmail(e.target.value)}
            label={"Email"}
            placeholder={"Enter your email"}
            name="email"
            id="email"
            type="email"
            {...getFieldProps("email")}
          />
          <span className="errorMessage" style={{ marginTop: "-10px" }}>
            {touched["email"] && errors["email"]}
          </span>
        </div>
        <Buttons
          type="submit"
          style={{ height: "60px", marginTop: "30px" }}
          onClick={(e) => handleSubmit(e)}
        >
          Next
        </Buttons>
        <Modal visible={modalIsOpen} closeModal={() => setIsOpen(false)}>
          {step === 0 && (
            <div className="mx-auto">
              <OTPModal OTP={OTP} setOTP={setOTP} handleNext={verifyEmail} />
            </div>
          )}
          {step === 1 && (
            <div className="mx-auto">
              <CreateNewPasswordModal
                setNewPass={setNewPass}
                handleNext={createNewPassword}
              />
            </div>
          )}
          {step === 2 && (
            <div className="mx-auto">
              <PasswordChangeSuccessModal />
            </div>
          )}
        </Modal>
      </div>
    </div>
  );
};

// {splitLocation[3] === "forgetPassword#successChange" ? (
//   <PasswordChangeSuccessModal closeModal={closeModal} />
// ) : splitLocation[3] === "forgetPassword#changePassword" ? (
//   <CreateNewPasswordModal />
// ) : (
//   <OTPModal />
// )}
