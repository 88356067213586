import React from "react";
import { useLocation } from "react-router-dom";
//    importing images
import Logo from "../../../Assests/Icons/InfoRide-01 1.svg";
//    importing css files
import "./authNavBar.css";
export const AuthNav = () => {
  const location = useLocation();
  const { pathname } = location;
  console.log(pathname)
  const splitLocation = pathname.split("/");
  let isAuthRoute = (splitLocation[1] === "/auth" && splitLocation[1] !== "auth/signUp" &&
  splitLocation[1] !== "pendingApproval"&&
  splitLocation[1] !== "forgetPassword");

  if(!isAuthRoute){
    return (
      <div
      style={{ display: "block" }}
      >
      <div className="auth-nav-div">
        <img src={Logo} className="auth-nav-bar-img" alt="" />
      </div>
    </div>
    );
  }else{
    return false;
  }
};
